export const FETCH_VAT_LOGS_REQUEST = 'vat/fetch-vat-logs-request';
export const FETCH_VAT_LOGS_SUCCESS = 'vat/fetch-vat-logs-success';
export const FETCH_VAT_LOGS_FAILURE = 'vat/fetch-vat-logs-failure';

export const FETCH_RUN_DATA_REQUEST = 'vat/fetch-run-data-request';
export const FETCH_RUN_DATA_SUCCESS = 'vat/fetch-run-data-success';
export const FETCH_RUN_DATA_FAILURE = 'vat/fetch-run-data-failure';
export const FETCH_VIEW_RUN_DATA_REQUEST = 'vat/fetch-view-run-data-request';

export const GENERATE_REPORT_REQUEST = 'vat/generate-report-request';
export const GENERATE_REPORT_SUCCESS = 'vat/generate-report-success';
export const GENERATE_REPORT_FAILURE = 'vat/generate-report-failure';
export const LOAD_REPORT_REQUEST = 'vat/load-report-request';

export const LOAD_RECON_REQUEST = 'vat/load-recon-request';
export const LOAD_RECON_SUCCESS = 'vat/load-recon-success';
export const LOAD_RECON_FAILURE = 'vat/load-recon-failure';

export const SAVE_RETURN_REQUEST = 'vat/save-return-request';
export const SAVE_RETURN_SUCCESS = 'vat/save-return-success';
export const SAVE_RETURN_FAILURE = 'vat/save-return-failure';

export const CANCEL_RETURN_REQUEST = 'vat/cancel-return-request';
export const CANCEL_RETURN_SUCCESS = 'vat/cancel-return-success';
export const CANCEL_RETURN_FAILURE = 'vat/cancel-return-failure';

export const UPDATE_ACTIVITY_ROLE_REQUEST = 'vat/update-activity-role-request';
export const UPDATE_ACTIVITY_ROLE_SUCCESS = 'vat/update-activity-role-success';
export const UPDATE_ACTIVITY_ROLE_FAILURE = 'vat/update-activity-role-failure';

export const ADD_NOTE_REQUEST = 'vat/add-note-request';
export const ADD_NOTE_SUCCESS = 'vat/add-note-success';
export const ADD_NOTE_FAILURE = 'vat/add-note-failure';

export const FETCH_ACTIVITY_LOGS_REQUEST = 'vat/fetch-activity-logs-request';
export const FETCH_ACTIVITY_LOGS_SUCCESS = 'vat/fetch-activity-logs-success';
export const FETCH_ACTIVITY_LOGS_FAILURE = 'vat/fetch-activity-logs-failure';

export const CHANGE_STATUS_AFTER_UPDATE = 'vat/change_status_after_update';

export const AWAITING_APPROVAL_REQUEST = 'vat/awaiting-approval-request';
export const AWAITING_APPROVAL_SUCCESS = 'vat/awaiting-approval-success';
export const AWAITING_APPROVAL_FAILURE = 'vat/awaiting-approval-failure';

export const CHANGE_ACTIVITY_ROLE = 'vat/change_activity_role';

export const INTERNALLY_APPROVE_REQUEST = 'vat/internally-approve-request';
export const INTERNALLY_APPROVE_SUCCESS = 'vat/internally-approve-success';
export const INTERNALLY_APPROVE_FAILURE = 'vat/internally-approve-failure';

export const SEND_CLIENT_APPROVAL_REQUEST = 'vat/send-client-approval-request';
export const SEND_CLIENT_APPROVAL_SUCCESS = 'vat/send-client-approval-success';
export const SEND_CLIENT_APPROVAL_FAILURE = 'vat/send-client-approval-failure';

export const CLIENT_APPROVE_REQUEST = 'vat/client-approve-request';
export const CLIENT_APPROVE_SUCCESS = 'vat/client-approve-success';
export const CLIENT_APPROVE_FAILURE = 'vat/client-approve-failure';

export const NOT_APPROVE_REQUEST = 'vat/not-approve-request';
export const NOT_APPROVE_SUCCESS = 'vat/not-approve-success';
export const NOT_APPROVE_FAILURE = 'vat/not-approve-failure';

export const SUBMIT_RETURN_REQUEST = 'vat/submit-return-request';
export const SUBMIT_RETURN_SUCCESS = 'vat/submit-return-success';
export const SUBMIT_RETURN_FAILURE = 'vat/submit-return-failure';

export const LOCK_RETURN_REQUEST = 'vat/lock-return-request';
export const LOCK_RETURN_SUCCESS = 'vat/lock-return-success';
export const LOCK_RETURN_FAILURE = 'vat/lock-return-failure';

export const RESET_VAT_DATA = 'vat/reset-vat-data';

export const CHECK_ENTITY_STATUS_REQUEST = 'vat/check-entity-status-request';
export const CHECK_ENTITY_STATUS_SUCCESS = 'vat/check-entity-status-success';
export const CHECK_ENTITY_STATUS_FAILURE = 'vat/check-entity-status-failure';
