import React from 'react';
import { Divider, MenuProps, Space } from 'antd';
import { DataLogActions, DataLogStatus } from 'store/app/enums';
import { User } from 'store/auth/models';
import { VatRunData } from 'store/vat/models';
import { checkDisabled } from './check-disabled';
import { IconContextButton } from 'components/Custom/buttons';
import { Link } from 'react-router-dom';
import { ReactComponent as IconRevert } from 'assets/images/icons/iconRevert.svg';
import { ReactComponent as IconSave } from 'assets/images/icons/iconSave.svg';
import { ReactComponent as IconClock } from 'assets/images/icons/clockIcon.svg';
import { ReactComponent as IconPadlock } from 'assets/images/icons/iconPadlock.svg';
import { ReactComponent as IconCross } from 'assets/images/icons/iconCross.svg';
import { ReactComponent as IconCheck } from 'assets/images/icons/iconCheck.svg';
import styled from 'styled-components';
import { AccountType } from 'store/api/enums';
const IconWrapper = styled.div`
  width: 25px;
  display: flex;
  justify-content: center;
  //align-items: center;
  height: 25px;
`;
export const getActionMenuItems = (
  vatRunData: VatRunData,
  currentUser: User,
  handleMenuClick: (key: DataLogActions) => void,
  cannotSave?: boolean
) => {
  let enabled = false;

  return (
    <div className="context-menu-items">
      <Space direction="vertical" style={{ rowGap: '0' }}>
        {vatRunData.dataLogId &&
          ((vatRunData.status &&
            DataLogStatus[vatRunData.status] === DataLogStatus.NOT_SAVED) ||
            !vatRunData.status) && (
            <>
              <IconContextButton
                type="text"
                icon={
                  <IconWrapper>
                    <IconRevert />
                  </IconWrapper>
                }
                onClick={() => handleMenuClick(DataLogActions.REVERT_DATA)}
              >
                {DataLogActions.REVERT_DATA}
              </IconContextButton>
              {(enabled = true)}
            </>
          )}
        {!checkDisabled(
          DataLogActions.SAVE_VAT_RETURN,
          vatRunData,
          currentUser
        ) && (
          <>
            <IconContextButton
              type="text"
              icon={
                <IconWrapper>
                  <IconSave />
                </IconWrapper>
              }
              onClick={() => handleMenuClick(DataLogActions.SAVE_VAT_RETURN)}
            >
              {DataLogActions.SAVE_VAT_RETURN}
            </IconContextButton>
            {(enabled = true)}
          </>
        )}
        {!checkDisabled(
          DataLogActions.SET_AWAITING_APPROVAL,
          vatRunData,
          currentUser
        ) && (
          <>
            <IconContextButton
              type="text"
              icon={
                <IconWrapper>
                  <IconClock style={{ width: 17 }} />
                </IconWrapper>
              }
              onClick={() =>
                handleMenuClick(DataLogActions.SET_AWAITING_APPROVAL)
              }
            >
              {DataLogActions.SET_AWAITING_APPROVAL}
            </IconContextButton>
            {(enabled = true)}
          </>
        )}
        {!checkDisabled(
          DataLogActions.SET_INTERNAL_APPROVED,
          vatRunData,
          currentUser
        ) && (
          <>
            <IconContextButton
              type="text"
              icon={
                <IconWrapper>
                  <IconCheck style={{ alignSelf: 'center' }} />
                </IconWrapper>
              }
              onClick={() =>
                handleMenuClick(DataLogActions.SET_INTERNAL_APPROVED)
              }
            >
              {DataLogActions.SET_INTERNAL_APPROVED}
            </IconContextButton>
            {(enabled = true)}
          </>
        )}
        {!checkDisabled(
          DataLogActions.SEND_FOR_CLIENT_APPROVAL,
          vatRunData,
          currentUser
        ) && (
          <>
            <IconContextButton
              type="text"
              icon={
                <IconWrapper>
                  <IconClock style={{ width: 17 }} />
                </IconWrapper>
              }
              onClick={() =>
                handleMenuClick(DataLogActions.SEND_FOR_CLIENT_APPROVAL)
              }
            >
              {DataLogActions.SEND_FOR_CLIENT_APPROVAL}
            </IconContextButton>
            {(enabled = true)}
          </>
        )}
        {!checkDisabled(
          DataLogActions.SET_NOT_APPROVED,
          vatRunData,
          currentUser
        ) && (
          <>
            <IconContextButton
              type="text"
              icon={
                <IconWrapper>
                  <IconCheck style={{ alignSelf: 'center' }} />
                </IconWrapper>
              }
              onClick={() => handleMenuClick(DataLogActions.SET_NOT_APPROVED)}
            >
              {DataLogActions.SET_NOT_APPROVED}
            </IconContextButton>
            {(enabled = true)}
          </>
        )}
        {!checkDisabled(
          DataLogActions.MARK_AS_SUBMITTED,
          vatRunData,
          currentUser
        ) && (
          <>
            <IconContextButton
              type="text"
              icon={
                <IconWrapper>
                  <IconCheck style={{ alignSelf: 'center' }} />
                </IconWrapper>
              }
              onClick={() => handleMenuClick(DataLogActions.MARK_AS_SUBMITTED)}
            >
              {DataLogActions.MARK_AS_SUBMITTED}
            </IconContextButton>
            {(enabled = true)}
          </>
        )}
        {enabled && <Divider />}
        {!checkDisabled(
          DataLogActions.LOCK_AS_APPROVED,
          vatRunData,
          currentUser
        ) && (
          <IconContextButton
            type="text"
            icon={
              <IconWrapper>
                <IconPadlock />
              </IconWrapper>
            }
            onClick={() => handleMenuClick(DataLogActions.LOCK_AS_APPROVED)}
            disabled={
              AccountType[currentUser.accountType] === AccountType.CLIENT
            }
          >
            {DataLogActions.LOCK_AS_APPROVED}
          </IconContextButton>
        )}
        {!checkDisabled(
          DataLogActions.CANCEL_RETURN,
          vatRunData,
          currentUser
        ) && (
          <IconContextButton
            type="text"
            icon={
              <IconWrapper>
                <IconCross style={{ alignSelf: 'center' }} />
              </IconWrapper>
            }
            onClick={() => handleMenuClick(DataLogActions.CANCEL_RETURN)}
            disabled={
              AccountType[currentUser.accountType] === AccountType.CLIENT
            }
          >
            {DataLogActions.CANCEL_RETURN}
          </IconContextButton>
        )}
      </Space>
    </div>
  );
};
