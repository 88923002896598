import { createAction } from 'store/utils';
import {
  FETCH_BUSINESS_SIZES_FAILURE,
  FETCH_BUSINESS_SIZES_REQUEST,
  FETCH_BUSINESS_SIZES_SUCCESS,
  FETCH_BUSINESS_TYPES_FAILURE,
  FETCH_BUSINESS_TYPES_REQUEST,
  FETCH_BUSINESS_TYPES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_CURRENCIES_FAILURE,
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_TIMEZONES_FAILURE,
  FETCH_TIMEZONES_REQUEST,
  FETCH_TIMEZONES_SUCCESS,
  FETCH_COUNTRIES_FLAGS_FAILURE,
  FETCH_COUNTRIES_FLAGS_REQUEST,
  FETCH_COUNTRIES_FLAGS_SUCCESS,
} from './constants';
import { BusinessSize, BusinessType, Country, CountryFlag, Timezone } from './models';
import {
  FetchBusinessSizesFailureAction,
  FetchBusinessSizesRequestAction,
  FetchBusinessSizesSuccessAction,
  FetchBusinessTypesFailureAction,
  FetchBusinessTypesRequestAction,
  FetchBusinessTypesSuccessAction,
  FetchCountriesExternalDataFailureAction,
  FetchCountriesExternalDataRequestAction,
  FetchCountriesExternalDataSuccessAction,
  FetchCurrenciesAction,
  FetchCurrenciesFailureAction,
  FetchCurrenciesSuccessAction,
  FetchTimezonesFailureAction,
  FetchTimezonesRequestAction,
  FetchTimezonesSuccessAction,
  FetchCountriesFailureAction,
  FetchCountriesRequestAction,
  FetchCountriesSuccessAction,
} from './types';

// countries
export const fetchCountriesRequest = (): FetchCountriesRequestAction =>
  createAction(FETCH_COUNTRIES_REQUEST);

export const fetchCountriesSuccess = (
  countries: Country[]
): FetchCountriesSuccessAction =>
  createAction(FETCH_COUNTRIES_SUCCESS, { countries });

export const fetchCountriesFailure = (): FetchCountriesFailureAction =>
  createAction(FETCH_COUNTRIES_FAILURE);

// business types
export const fetchBusinessTypesRequest = (): FetchBusinessTypesRequestAction =>
  createAction(FETCH_BUSINESS_TYPES_REQUEST);

export const fetchBusinessTypesSuccess = (
  businessTypes: BusinessType[]
): FetchBusinessTypesSuccessAction =>
  createAction(FETCH_BUSINESS_TYPES_SUCCESS, { businessTypes });

export const fetchBusinessTypesFailure = (): FetchBusinessTypesFailureAction =>
  createAction(FETCH_BUSINESS_TYPES_FAILURE);

// business sizes
export const fetchBusinessSizesRequest = (): FetchBusinessSizesRequestAction =>
  createAction(FETCH_BUSINESS_SIZES_REQUEST);

export const fetchBusinessSizesSuccess = (
  businessSizes: BusinessSize[]
): FetchBusinessSizesSuccessAction =>
  createAction(FETCH_BUSINESS_SIZES_SUCCESS, { businessSizes });

export const fetchBusinessSizesFailure = (): FetchBusinessSizesFailureAction =>
  createAction(FETCH_BUSINESS_SIZES_FAILURE);

// timezones
export const fetchTimezonesRequest = (): FetchTimezonesRequestAction =>
  createAction(FETCH_TIMEZONES_REQUEST);

export const fetchTimezonesSuccess = (
  timezones: Timezone[]
): FetchTimezonesSuccessAction =>
  createAction(FETCH_TIMEZONES_SUCCESS, { timezones });

export const fetchTimezonesFailure = (): FetchTimezonesFailureAction =>
  createAction(FETCH_TIMEZONES_FAILURE);

// currencies
export const fetchCurrenciesRequest = (): FetchCurrenciesAction => {
  return createAction(FETCH_CURRENCIES_REQUEST);
};

export const fetchCurrenciesSuccess = (
  currencies: string[]
): FetchCurrenciesSuccessAction =>
  createAction(FETCH_CURRENCIES_SUCCESS, { currencies });

export const fetchCurrenciesFailure = (): FetchCurrenciesFailureAction =>
  createAction(FETCH_CURRENCIES_FAILURE);


// countries flags
export const fetchCountriesExternalDataRequest = (): FetchCountriesExternalDataRequestAction =>
  createAction(FETCH_COUNTRIES_FLAGS_REQUEST);

export const fetchCountriesExternalDataSuccess = (
  countries: CountryFlag[]
): FetchCountriesExternalDataSuccessAction =>
  createAction(FETCH_COUNTRIES_FLAGS_SUCCESS, { countries });

export const fetchCountriesExternalDataFailure = (): FetchCountriesExternalDataFailureAction =>
  createAction(FETCH_COUNTRIES_FLAGS_FAILURE);
