import { call, put } from 'redux-saga/effects';
import {
  fetchCountriesFailure,
  fetchCountriesSuccess,
  fetchCountriesExternalDataSuccess,
  fetchCountriesExternalDataFailure,
} from '../actions';
import { CountriesResponse, getCountries, getCountriesData } from '../api';
import { Country, CountryFlag } from '../models';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { ERROR_HANG_TIME } from 'store/app/constants';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchCountriesSaga() {
  try {
    let err: ApiError | null;

    const response: CountriesResponse = yield call(getCountries);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const countries: Country[] = response?.data?.countries;
    yield put(fetchCountriesSuccess(countries));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(fetchCountriesFailure());
  }
}

export function* fetchCountriesExternalDataSaga() {
  try {
    let err: ApiError | null;

    const response = yield call(getCountriesData);

    const countries: CountryFlag[] = response;
    yield put(
      fetchCountriesExternalDataSuccess(
        countries?.sort((a, b) => {
          if (a.name.common > b.name.common) {
            return 1;
          }

          if (a.name.common < b.name.common) {
            return -1;
          }

          return 0;
        })
      )
    );
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(fetchCountriesExternalDataFailure());
  }
}
