import { call, delay, put, select } from 'redux-saga/effects';
import { ApiError, ApiResponse } from 'store/api/types';
import { DeactivateViesRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { deactivateVies } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { ERROR_HANG_TIME } from 'store/app/constants';
import {
  deactivateViesFailure,
  deactivateViesSuccess,
  fetchViesInfoRequest,
} from '../actions';
import { fetchOrganisationsRequest } from 'store/organisation/actions';
import { getAuthUser } from 'store/auth/selectors';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* deactivateViesSaga(action: DeactivateViesRequestAction) {
  const currentUser = yield select(getAuthUser);
  try {
    let err: ApiError | null;
    const { connectionId, entityName } = action.payload;

    const response: ApiResponse = yield call(deactivateVies, connectionId);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success',
      description: `${entityName} deactivated successfully.`, //response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(deactivateViesSuccess(connectionId));
    localStorage.removeItem(`vies-infos-${currentUser.userUuid}`);
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(deactivateViesFailure());
  }
}
