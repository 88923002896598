import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: #eaf6f7;
  min-height: 100vh;

  .ant-steps-item-finish > div > div:nth-child(2) {
    background-color: #41aaaf !important;
    border-color: #41aaaf !important;
  }

  .ant-steps-item > div > div:nth-child(1) {
    padding: 4px 15px !important;
  }

  .ant-steps-item > div > div:nth-child(1)::after {
    height: 4px !important;
  }

  .ant-steps-item-finish > div > div > span {
    color: #ffffff !important;
  }

  .ant-steps-item-active > div > div:nth-child(2) {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    width: 32px !important;
  }

  .ant-steps-item-active > div > div > span {
    color: #e1f0ee !important;
    width: 14px !important;
    height: 14px !important;
    font-size: 14px !important;
  }

  .ant-steps-item-finish > div > div:nth-child(3) > div {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #879494 !important;
  }

  .ant-steps-item-active > div > div:nth-child(3) > div {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #042a2a !important;
  }

  .link-text {
    color: #879494;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
export const FormWrapper = styled(PageWrapper)`
  .link-text {
    color: #879494;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .link {
    text-decoration: underline;
    text-underline-offset: 2px;
    color: #879494;
  }

  .password-link {
    width: 100%;
    text-align: right;
    min-width: 300px;
    max-width: 330px;
  }

  .sign-up-link {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #042a2a;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
`;

export const EntityDetailsWrapper = styled.div`
  .top-wrapper {
    position: fixed;
    z-index: 1;
    top: 70px;
    right: 43px;
    display: flex;
  }

  .top-wrapper-vies {
    position: fixed;
    z-index: -1;
    top: 70px;
    right: 43px;
    display: flex;
  }

  .ant-tabs-nav-wrap {
    padding: 0 40px;
  }

  .ant-tabs-nav {
    margin: 0 !important;
    position: fixed;
    width: 100%;
    height: 50px;
    z-index: 1;
    background: white;
  }
  .ant-tabs-content {
    margin-top: 50px;
  }

  .ant-collapse-borderless {
    background-color: white !important;
  }

  .ant-tabs-extra-content {
    margin-bottom: 10px;
    padding-right: 35px;
  }

  .expired-due-date {
    color: #d13131 !important;
  }

  @media (min-width: 1700px) {
    .ant-col-xxl-offset-2 {
      margin-inline-start: 4.333333333333332% !important;
    }
  }

  @media (min-width: 1920px) {
    .ant-col-xxl-offset-2 {
      margin-inline-start: 12.333333333333332% !important;
    }
  }

  @media (min-width: 2304px) {
    .ant-col-xxl-offset-2 {
      margin-inline-start: 18.333333333333332% !important;
    }
  }
`;

export const ProfileWrapper = styled.div`
  padding: 40px;
  background-color: #f3f7f8;
  minheight: 85vh;

  @media (min-width: 1920px) {
    .ant-col-xxl-offset-2 {
      margin-inline-start: 12.333333333333332% !important;
    }
  }

  @media (min-width: 2304px) {
    .ant-col-xxl-offset-2 {
      margin-inline-start: 18.333333333333332% !important;
    }
  }
`;

export const ActivityLogsWrapper = styled.div`
  width: 340px;
  
  .vies-logs-card {
    border-radius: 10px;
    opacity: 0px;
    background: #ffffff;
    border: 1px solid #e9f6f7;
    padding: 15px 10px 15px 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 325px;
  }
  
  .vies-logs-card-details {
    width: 255px !important;
  }
  .title-date {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #042a2a;
  }
  .user-box {
    display: flex;
    align-items: center;
    gap: 15px;
    //margin-bottom: 15px;
  }
  .date-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dbeae3;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .data-row-details {
  .date-row
    flex-wrap: wrap;
    max-width: 250px;
  }
  .invoice-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .invoice-box{
    display: flex;
    align-items: center;
    margin-right: 10px;
    height: 30px;
    border-radius: 5px;
    background: #E9F6F7;
    padding: 0px 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
  }  
`;
