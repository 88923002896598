import { takeLatest } from 'redux-saga/effects';
import {
  ACTIVATE_ACCOUNT_REQUEST,
  CONFIRM_USER_REQUEST,
  PROVIDER_SIGNUP_REQUEST,
  REGISTER_REQUEST,
  RESEND_ACTIVATION_REQUEST,
} from '../constants';
import { registerSaga } from './registerSaga';
import { confirmUserSaga } from './confirmUserSaga';
import { activateAccountSaga } from './activateAccountSaga';
import { resendActivationSaga } from './resendActivationSaga';
import { providerSignupSaga } from './providerSignupSaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* signupSaga() {
  yield takeLatest(REGISTER_REQUEST, registerSaga);
  yield takeLatest(CONFIRM_USER_REQUEST, confirmUserSaga);
  yield takeLatest(ACTIVATE_ACCOUNT_REQUEST, activateAccountSaga);
  yield takeLatest(RESEND_ACTIVATION_REQUEST, resendActivationSaga);
  yield takeLatest(PROVIDER_SIGNUP_REQUEST, providerSignupSaga);
}
