import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { Task } from 'redux-saga';
import {
  createStore,
  applyMiddleware,
  compose,
  Store,
  Middleware,
} from 'redux';
import createRootReducer from './rootReducer';
import { rootSaga } from './rootSaga';
import { StoreState } from './types';
import { createHistory, UniversalHistory } from './history';

type StoreParams = {
  initialState?: StoreState;
  middleware?: Middleware[];
};

export interface ExtendedStore extends Store<Partial<StoreState>> {
  runSaga: (appSaga: typeof rootSaga) => Task;
}

export const history: UniversalHistory = createHistory();

export const configureStore = ({ initialState, middleware = [] }: StoreParams): ExtendedStore => {
  const devtools =
    typeof window !== 'undefined' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });

  const composeEnhancers = devtools || compose;

  const sagaMiddleware = createSagaMiddleware();
  const ehnancers = [...middleware, routerMiddleware(history), sagaMiddleware];

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...ehnancers))
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () =>
        store.replaceReducer(require('./rootReducer').default)
      );
    }
  }

  return {
    ...store,
    runSaga: sagaMiddleware.run,
  };
};

export default configureStore;
