import styled from 'styled-components';

export const ReportCssWrapper = styled.div`
  .table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    width: 100%;
    margin-bottom: 20px;
    color: #212529;
    vertical-align: top;
    border-color: #dbeae3 !important;
    caption-side: bottom;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .divide-10 {
    height: 10px;
    width: 100%;
  }

  .divide-20 {
    height: 20px;
    width: 100%;
  }

  thead {
    border-style: none !important;
  }

  .table > thead {
    vertical-align: bottom;
  }

  tbody {
    border-style: solid;
    border-width: 0;
  }

  .table > tbody {
    vertical-align: inherit;
  }

  tr {
    border-style: solid;
    border-width: 0;
  }

  .vatreporttable thead tr {
    color: #ffffff !important;
    font-size: 14px !important;
    padding: 0.75rem 1.25rem !important;
    margin-bottom: 0 !important;
    background-color: #058181 !important;
    border: 1px solid #dbeae3 !important;
    font-weight: 600 !important;
    height: 45px;
  }

  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }

  th {
    border-style: solid;
    border-width: 0;
  }

  th {
    text-align: left;
  }

  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }

  .table > thead > tr > th {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #dddddd;
  }

  .table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #dddddd;
  }

  .table > thead > tr > th {
    vertical-align: middle;
  }

  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: currentColor;
  }

  .table > thead:first-child > tr:first-child > th {
    border-top: 0;
  }

  td {
    border-style: solid;
    border-width: 0;
  }

  .vatreporttable tbody td {
    border: 1px solid #dbeae3;
    height: 50px;
    font-size: 14px;
    font-weight: 450;
    background: #ffffff;
  }

  .table > tbody > tr > td {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: middle;
    border-top: 1px solid #dddddd;
  }

  .box {
    clear: both;
    margin: 0px 5px 25px 5px;
    padding: 0px;
  }

  .vatreporttable .box {
    background: #e9f6f7 !important;
    text-align: center !important;
    width: 67px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    color: #41aaaf;
  }

  .box:after,
  .box:before {
    display: table;
    color: "";
  }

  td[tabindex] {
    position: relative;
  }

  .vatreporttable .rightalign {
    text-align: right;
  }

  .nbr {
    border-right: 1px solid #ffffff !important;
  }

  .nbb {
    border-bottom: 1px solid #ffffff !important;
  }

  .vatreporttable .subtotal,
  .vatreporttable .subtotal-2 {
    text-align: left;
    font-weight: 400 !important;
    font-size: 14px;
    font-style: italic;
    line-height: 18px;
    color: #879494;
  }

  .vatreporttable .subtotal {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }

  .nbt {
    border-top: 1px solid #fff !important;
  }

  .nbl {
    border-left: 1px solid #fff !important;
  }

  .notavailable {
    text-decoration: line-through;
    background-color: #f9f9f9 !important;
  }

  .space20 {
    height: 10px !important;
    background: transparent !important;
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .value-field:hover {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 8px 3px;
    text-align: center !important;
    > .show-on-hover {
      display: flex;
      justify-content: center;
    }

    > .value-field-span {
      display: none;
    }
  }

  .show-on-hover {
    display: none;
  }

  .icon-button-hover:hover {
    background-color: #41aaaf !important;
    > span > .hover-icon {
      filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(321deg)
        brightness(150%) contrast(184%);
    }
  }

  .hover-icon {
    filter: brightness(0) saturate(100%) invert(61%) sepia(2%) saturate(1632%)
      hue-rotate(151deg) brightness(94%) contrast(88%);
  }

  .hover-icon:hover {
    filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(321deg)
      brightness(150%) contrast(184%);
  }
`;
