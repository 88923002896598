import { api } from 'store/api';
import { PermissionType, TablePermission } from 'store/api/enums';
import { ApiResponse } from 'store/api/types';
import { User } from 'store/auth/models';
import { LoginDetails, UserRights } from './models';

export interface FetchUserResponse extends ApiResponse {
  data: { members: User[] };
}
export const getUsers = (): Promise<FetchUserResponse> =>
  api.get<FetchUserResponse>('/auth/user/list');

export const setUserPermissions = (
  accountUuid: string,
  permissionType: PermissionType,
  enabled: boolean
): Promise<ApiResponse> =>
  api.post<ApiResponse>(
    `/auth/user/${permissionType}?id=${accountUuid}&enabled=${enabled}`
  );

export type AddMemberRequest = {
  user: User;
  redirectURL: string;
};

export interface AddMemberResponse extends ApiResponse {
  data: { accountUuid: string; userUuid: string };
}

export const addMember = (
  user: User,
  isClient: boolean
): Promise<AddMemberResponse> => {
  const redirectURL = `${process.env.PUBLIC_URL}/signup/validate`;
  const request: AddMemberRequest = { user, redirectURL };
  return api.post<AddMemberResponse>(
    `/auth/user/addUser?isClient=${isClient}`,
    {
      body: request,
    }
  );
};

export const deleteUser = (userToDeleteId: string): Promise<ApiResponse> =>
  api.post<ApiResponse>(`/auth/user/delete/${userToDeleteId}`);

export interface FetchUserHistoryResponse extends ApiResponse {
  data: { loginDetails: LoginDetails[] };
}
export const getUserHistory = (
  historyUserId: string
): Promise<FetchUserHistoryResponse> =>
  api.get<FetchUserHistoryResponse>(`/auth/user/logins/${historyUserId}`);

export interface FetchUserRightsResponse extends ApiResponse {
  data: { userRights: UserRights[] };
}
export const getUserRights = (
  accountId: string
): Promise<FetchUserRightsResponse> =>
  api.get<FetchUserRightsResponse>(`/auth/user/${accountId}/rights/get`);

export const setUserRights = (
  uuid: string,
  tablePermission: TablePermission
): Promise<ApiResponse> =>
  api.put<ApiResponse>(
    `/auth/user/${uuid}/rights/update?permission=${tablePermission}`
  );
