import { MessageType } from './api/enums';
import { ApiError, ApiResponseMessage } from './api/types';
import { Action } from './commonTypes';

/**
 * Creates action object
 *
 * @param type Action type
 * @param payload Action payload
 *
 * @returns Action object
 */
export function createAction<T extends string, P>(
  type: T,
  payload = {} as P
): Action<T, P> {
  return { type, payload };
}

export function checkResponse(responseMessage: ApiResponseMessage) {
  if (responseMessage?.messageType === MessageType.ERROR) {
    const apiError = {
      message: responseMessage.message,
      status: responseMessage.status,
    };
    return apiError as ApiError;
  }
  return null;
}
