import {
  ADD_MEMBER_FAILURE,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USER_HISTORY_FAILURE,
  FETCH_USER_HISTORY_REQUEST,
  FETCH_USER_HISTORY_SUCCESS,
  FETCH_USER_RIGHTS_FAILURE,
  FETCH_USER_RIGHTS_REQUEST,
  FETCH_USER_RIGHTS_SUCCESS,
  RESET_USER_DATA,
  SET_USER_PERMISSIONS_SUCCESS,
  SET_USER_RIGHTS_FAILURE,
  SET_USER_RIGHTS_REQUEST,
  SET_USER_RIGHTS_SUCCESS,
} from './constants';
import { UserRights } from './models';
import {
  AddMemberSuccessAction,
  DeleteUserSuccessAction,
  FetchUserHistorySuccessAction,
  FetchUserRightsSuccessAction,
  FetchUsersSuccessAction,
  SetUserPermissionsSuccessAction,
  SetUserRightsSuccessAction,
  UserAction,
  UserState,
} from './types';
import { PermissionType } from 'store/api/enums';
import { User } from 'store/auth/models';

const initialState: UserState = {
  loading: false,
  members: undefined,
  loginDetails: undefined,
  userRights: undefined,
};

export const userReducer = (
  state = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
    case ADD_MEMBER_REQUEST:
    case DELETE_USER_REQUEST:
    case FETCH_USER_HISTORY_REQUEST:
    case FETCH_USER_RIGHTS_REQUEST:
    case SET_USER_RIGHTS_REQUEST:
      return handleRequest(state);
    case FETCH_USERS_SUCCESS:
      return handleFetchUsersSuccess(state, action);
    case FETCH_USERS_FAILURE:
    case ADD_MEMBER_FAILURE:
    case DELETE_USER_FAILURE:
    case FETCH_USER_HISTORY_FAILURE:
    case FETCH_USER_RIGHTS_FAILURE:
    case SET_USER_RIGHTS_FAILURE:
      return handleFailure(state);
    case SET_USER_PERMISSIONS_SUCCESS:
      return handleSetUserPermissionsSuccess(state, action);
    case ADD_MEMBER_SUCCESS:
      return handleAddMemberSuccess(state, action);
    case DELETE_USER_SUCCESS:
      return handleDeleteUserSuccess(state, action);
    case FETCH_USER_HISTORY_SUCCESS:
      return handleFetchUserHistorySuccess(state, action);
    case FETCH_USER_RIGHTS_SUCCESS:
      return handleFetchUserRightsSuccess(state, action);
    case SET_USER_RIGHTS_SUCCESS:
      return handleSetUserRightsSuccess(state, action);
    case RESET_USER_DATA:
      return handleResetUserData();
    default:
      return state;
  }
};

// request
function handleRequest(state: UserState): UserState {
  return {
    ...state,
    loading: true,
  };
}

function handleFetchUsersSuccess(
  state: UserState,
  action: FetchUsersSuccessAction
): UserState {
  return {
    ...state,
    loading: false,
    members: action.payload.members,
  };
}

function handleSetUserPermissionsSuccess(
  state: UserState,
  action: SetUserPermissionsSuccessAction
): UserState {
  const memberIndex = state.members?.findIndex(
    (m) => m.accountUuid === action.payload.accountUuid
  );
  let newMembers: User[] = [];
  if (state.members && memberIndex && memberIndex > -1) {
    let canManageOrgs = state.members[memberIndex].canManageOrgs;
    let canManageUsers = state.members[memberIndex].canManageUsers;
    if (action.payload.permissionType === PermissionType.ORGANISATIONS)
      canManageOrgs = action.payload.enabled;
    if (action.payload.permissionType === PermissionType.USERS)
      canManageUsers = action.payload.enabled;
    newMembers = [...state.members];
    newMembers[memberIndex] = {
      ...newMembers[memberIndex],
      canManageOrgs,
      canManageUsers,
    };
  }

  return {
    ...state,
    //loading: false,
    members: newMembers.length > 0 ? newMembers : state.members,
  };
}

function handleAddMemberSuccess(
  state: UserState,
  action: AddMemberSuccessAction
): UserState {
  const { newUser } = action.payload;
  const newMembers = state.members ? [...state.members] : [];
  if (newMembers.findIndex((nm) => nm.userUuid === newUser.userUuid) === -1)
    newMembers.push(newUser);
  return {
    ...state,
    loading: false,
    members: newMembers,
  };
}

function handleDeleteUserSuccess(
  state: UserState,
  action: DeleteUserSuccessAction
): UserState {
  const { userToDeleteId } = action.payload;
  const memberIndex = state.members?.findIndex(
    (m) => m.userUuid === userToDeleteId
  );
  let newMembers: User[] = [];
  if (state.members && memberIndex && memberIndex > -1) {
    newMembers = [...state.members];
    newMembers.splice(memberIndex, 1);
  }
  const membs =
    newMembers.length > 0 ||
    (newMembers.length = 0 && state.members?.length === 1)
      ? newMembers
      : state.members;

  return {
    ...state,
    loading: false,
    members: membs,
  };
}

function handleFetchUserHistorySuccess(
  state: UserState,
  action: FetchUserHistorySuccessAction
): UserState {
  return {
    ...state,
    loading: false,
    loginDetails: action.payload.loginDetails,
  };
}

function handleFetchUserRightsSuccess(
  state: UserState,
  action: FetchUserRightsSuccessAction
): UserState {
  return {
    ...state,
    loading: false,
    userRights: action.payload.userRights,
  };
}

function handleSetUserRightsSuccess(
  state: UserState,
  action: SetUserRightsSuccessAction
): UserState {
  const rightsIndex = state.userRights?.findIndex(
    (m) => m.uuid === action.payload.uuid
  );
  let newRights: UserRights[] = [];
  if (state.userRights && rightsIndex && rightsIndex > -1) {
    newRights = [...state.userRights];
    newRights[rightsIndex] = {
      ...newRights[rightsIndex],
      tablePermission: action.payload.tablePermission,
    };
  }

  return {
    ...state,
    loading: false,
    userRights: newRights.length > 0 ? newRights : state.userRights,
  };
}

function handleFailure(state: UserState): UserState {
  return {
    ...state,
    loading: false,
  };
}

// Reset
function handleResetUserData(): UserState {
  return initialState;
}
