import { Checkbox, Form, Typography } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { FormInput, FormTextArea } from 'components/Custom/inputs';
import { LabelWrapper } from 'components/Custom/labels';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { turnDrawerOff } from 'store/app/actions';
import { EmailDataParametersRequest } from 'store/vat/models/emailDataParametersRequest';

const { Text } = Typography;
interface ClientApproveFormProps {
  sendForClientApproval: (values: EmailDataParametersRequest) => void;
}

export const ClientApproveForm: React.FC<ClientApproveFormProps> = ({
  sendForClientApproval,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (values: any) => {
    const formValues = {
      ...values,
    } as EmailDataParametersRequest;

    sendForClientApproval(formValues);
    dispatch(turnDrawerOff());
  };

  return (
    <>
      <Text
        style={{
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '22px',
          textAlign: 'left',
          color: '#879494',
          marginBottom: '40px',
        }}
      >
        Add any notes to the email that will be sent to the client for approval.
      </Text>
      <Form
        style={{
          marginTop: '40px',
        }}
        layout="horizontal"
        key={Date()}
        onFinish={handleSubmit}
        //style={{ marginBottom: '30px' }}
        //form={form}
      >
        <LabelWrapper>
          <span
            className="label-text"
            style={{ fontSize: 14, fontWeight: 400 }}
          >
            {t('common.subject')}
          </span>
        </LabelWrapper>
        <Form.Item
          name="emailSubject"
          rules={[{ required: true, message: t('signup.required-field') }]}
        >
          <FormInput
            width={'100%'} //@ts-ignore
            border_color="#DBEAE3"
          />
        </Form.Item>
        <LabelWrapper>
          <span
            className="label-text"
            style={{ fontSize: 14, fontWeight: 400 }}
          >
            {t('common.note')}
          </span>
        </LabelWrapper>
        <Form.Item
          name="emailMessage"
          rules={[{ required: true, message: t('signup.required-field') }]}
        >
          <FormTextArea
            rows={5}
            placeholder="Add a note to the client here..."
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: '0px' }}
          initialValue={true}
          valuePropName="checked"
          name="isPreparerIncluded"
        >
          <Checkbox
            style={{
              fontSize: '12px',
              fontWeight: '400',
            }}
            defaultChecked
          >
            Send a copy to preparer
          </Checkbox>
        </Form.Item>
        <Form.Item
          initialValue={true}
          valuePropName="checked"
          name="isReviewerIncluded"
        >
          <Checkbox
            style={{
              fontSize: '12px',
              fontWeight: '400',
            }}
            defaultChecked
          >
            Send a copy to reviewer
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <ScopeButton //@ts-ignore
            width="100%"
            height="40px"
            type="primary"
            htmlType="submit"
          >
            {t('common.send-to-client')}
          </ScopeButton>
          <div style={{ marginTop: '20px' }}>
            <ScopeButton //@ts-ignore
              width="100%"
              height="40px"
              bg_color="#E9F6F7"
              type={'default'}
              onClick={() => dispatch(turnDrawerOff())}
            >
              {t('common.cancel')}
            </ScopeButton>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
