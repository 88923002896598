import { Avatar, Space } from 'antd';
import React from 'react';
import { AvatarSizes, renderAvatar } from './render-avatar';
import { ScopeButton, ToggleButton } from 'components/Custom/buttons';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { turnDrawerOn } from 'store/app/actions';
import { DrawerData } from 'store/app/types';
import { DrawerType } from 'store/app/enums';
import { useTranslation } from 'react-i18next';
import { Entity } from 'store/organisation/models';
import { InviteUser } from 'components/UsersComponent/invite-user';
import { getUserLoading, getUserMembers } from 'store/user/selectors';
import * as _ from 'lodash';

interface AvatarGroupRendererProps {
  entity: Entity;
  maxCount?: number;
  size: AvatarSizes;
}

export const AvatarGroupRenderer: React.FC<AvatarGroupRendererProps> = ({
  entity,
  maxCount,
  size,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const members = useSelector(getUserMembers);
  const loading = useSelector(getUserLoading);

  // const saveNewMember = (newMember: NewMember) => {
  //   dispatch(addMemberRequest(newMember));
  //   dispatch(turnDrawerOff());
  // };

  const openMemberWindow = () => {
    const modalData: DrawerData = {
      type: DrawerType.INVITE_USER,
      title: `${t('common.share')} “${entity.organisation} ”`,
      component: (
        <InviteUser
          loading={loading}
          //saveNewMember={saveNewMember}
          members={entity.users}
          allUsers={_.differenceBy(members, entity.users, 'accountUuid')}
          organisationId={entity.organisationId}
        />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const addMember = () => {
    openMemberWindow();
  };

  return (
    <div style={{ minWidth: '164px' }}>
      <Space direction="horizontal" align="center" style={{ columnGap: '0' }}>
        {entity.users?.length > 0 ? (
          <>
            <Avatar.Group
              maxCount={maxCount ? maxCount : 2}
              size={size}
              maxPopoverPlacement={'bottom'}
              maxPopoverTrigger="click"
              maxStyle={{
                color: '#042A2A',
                backgroundColor: '#E9F6F7',
                border: '1px solid #DBEAE3',
              }}
              style={{ marginTop: '5px' }}
            >
              {entity.users.map((user) => (
                <React.Fragment key={user.userUuid}>
                  {renderAvatar(
                    user.firstName,
                    user.lastName,
                    size,
                    user.userUuid,
                    user.profilePicture
                  )}
                </React.Fragment>
              ))}
            </Avatar.Group>
            <ToggleButton
              className="toggle-button"
              type="primary"
              shape="circle"
              style={{ marginTop: '5px' }}
              icon={<PlusOutlined />}
              onClick={() => addMember()}
            >
              <span className="show-on-hover">{t('users.add-user')}</span>
            </ToggleButton>
          </>
        ) : (
          <>
            <ScopeButton
              type={'primary'}
              onClick={() => addMember()} // @ts-ignore
              width={'88px'}
              height={'25px'}
              fontSize={'12px'}
              lineheight={'12px'}
              style={{ padding: '4px 5px' }}
              icon={<PlusOutlined width={'16px'} height={'16px'} />}
            >
              <span>{t('users.add-user')}</span>
            </ScopeButton>
          </>
        )}
      </Space>
    </div>
  );
};
