import { call, put } from 'redux-saga/effects';
import { fetchAddUrlFailure, fetchAddUrlSuccess } from '../actions';
import { FetchAddUrlResponse, getAddOrganisationUrl } from '../api';
import { FetchAddUrlRequestAction } from '../types';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { navigateToExternalPage } from 'utils/validation/common';
import { checkResponse } from 'store/utils';
import { ERROR_HANG_TIME } from 'store/app/constants';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchAddUrlSaga(action: FetchAddUrlRequestAction) {
  try {
    let err: ApiError | null;

    const { providerName, uuid, sourceUrl } = action.payload;
    const response: FetchAddUrlResponse = yield call(
      getAddOrganisationUrl,
      providerName,
      uuid,
      sourceUrl
    );
    const orgUrl: string = response.data.url;

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: `Contacting ${providerName}`,
      description: 'Please be patient, it might take few seconds',
      type: MessageStates.INFO,
    };
    yield put(turnMessageOn(message));
    yield call(navigateToExternalPage, orgUrl);
    yield put(fetchAddUrlSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(fetchAddUrlFailure());
  }
}
