import { Col, Divider, Form, Typography, Row, Select } from 'antd';
import { NewMember } from 'pages/users';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LabelWrapper } from 'components/Custom/labels';
import { FormInput } from 'components/Custom/inputs';
import { ScopeButton } from 'components/Custom/buttons';
import { User } from 'store/auth/models';
import { AccountType, TablePermission } from 'store/api/enums';
import { FormSelect } from 'components/Custom/selects';
import {
  addMemberRequest,
  fetchUserRightsRequest,
  setUserRightsRequest,
} from 'store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RenderUserDetail } from 'utils/render-user-details';
import { getUserMembers, getUserRights } from 'store/user/selectors';
import { turnDrawerOff } from 'store/app/actions';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}
interface InviteUserProps {
  loading: boolean;
  //saveNewMember: (newMember: NewMember) => void;
  allUsers?: User[];
  members?: User[];
  organisationId: string;
}

export const InviteUser: React.FC<InviteUserProps> = ({
  loading,
  //saveNewMember,
  allUsers,
  members,
  organisationId,
}) => {
  const SAVING = 'SAVING';
  const DELETING = 'DELETING';
  const dispatch = useDispatch();
  const rights = useSelector(getUserRights);
  const { t } = useTranslation();
  const [isClient, setIsClient] = React.useState<boolean>(false);
  const [accountId, setAccountId] = React.useState<string>();
  const [showInvitationForm, setShowInvitationForm] =
    React.useState<boolean>(false);
  const [actionTaken, setActionTaken] = React.useState<string | null>(null);
  const [newMemberUser, setNewMemberUser] = React.useState<User>();

  React.useEffect(() => {
    if (actionTaken && rights) {
      if (actionTaken === DELETING) {
        const rightToChange = rights?.find(
          (r) =>
            r.accountUuid == accountId && r.organisationId == organisationId
        );
        const memberUser = members?.find((m) => m.accountUuid === accountId);
        rightToChange &&
          dispatch(
            setUserRightsRequest(
              rightToChange,
              TablePermission.NONE,
              memberUser
            )
          );
        const idx = members?.findIndex((m) => m.accountUuid === accountId);
        idx && idx > -1 && members?.splice(idx, 1);
        setActionTaken(null);
      }
      if (actionTaken === SAVING) {
        const rightToChange = rights?.find(
          (r) => r.organisationId == organisationId
        );
        if (rightToChange) {
          const memb = {
            ...newMemberUser,
            accountUuid: rightToChange.accountUuid,
            userUuid: rightToChange.userUuid,
          } as User;
          dispatch(
            setUserRightsRequest(rightToChange, TablePermission.ALL, memb)
          );
          setActionTaken(null);
          dispatch(turnDrawerOff());
        }
      }
    }
  }, [rights]);

  const onAddUser = () => {
    const rightToChange = rights?.find(
      (r) => r.accountUuid == accountId && r.organisationId == organisationId
    );
    const memberUser = allUsers?.find((m) => m.accountUuid === accountId);
    rightToChange &&
      dispatch(
        setUserRightsRequest(rightToChange, TablePermission.ALL, memberUser)
      );
    dispatch(turnDrawerOff());
  };

  const onMemberChange = (value: any) => {
    dispatch(fetchUserRightsRequest(value));
    setAccountId(value);
  };
  const handleSubmit = (values: FormValues) => {
    const newMember: NewMember = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      isClient,
    };
    const accountType = isClient ? 'CLIENT' : 'STAFF';
    const newMemberUser = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      accountType,
    } as User;
    setActionTaken(SAVING);
    setNewMemberUser(newMemberUser);
    dispatch(addMemberRequest(newMember, true));
    //dispatch(turnDrawerOff());
    //saveNewMember(newMember);
  };

  const memberRemove = (accountId: string) => {
    setActionTaken(DELETING);
    dispatch(fetchUserRightsRequest(accountId));
    setAccountId(accountId);
  };

  const inviteDisabled = () => {
    if (!rights) return true;
    else {
      const idx = rights.findIndex((r) => r.accountUuid === accountId);
      if (idx === -1) return true;
    }
    return false;
  };

  const renderMembersList = () => {
    return (
      <>
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '24px',
            textAlign: 'left',
            color: '#042A2A',
            marginBottom: '15px',
          }}
        >
          {t('users.members')}
        </Typography>
        {members?.map((member) => (
          <RenderUserDetail
            record={member}
            showDelete
            memberRemove={memberRemove}
          />
        ))}
        <ScopeButton //@ts-ignore
          width="319px"
          height="40px"
          type="primary"
          htmlType="submit"
          style={{ marginTop: 40 }}
          onClick={() => setShowInvitationForm(true)}
        >
          {t('users.invite-users')}
        </ScopeButton>
      </>
    );
  };

  const userTypeSelectAfter = (
    <Select defaultValue={false} onChange={() => setIsClient(!isClient)}>
      <Select.Option value={false}>{t('users.user')}</Select.Option>
      <Select.Option value={true}>{t('users.client')}</Select.Option>
    </Select>
  );

  const renderInviteUserForm = () => {
    return (
      <Form
        name="basic"
        labelCol={{ span: 5 }}
        //wrapperCol={{ span: 14 }}
        //form={form}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <Row justify={'center'}>
          <Col span={24}>
            <LabelWrapper>
              <span className="label-text">
                {t('users.invite-user-via-email')}
              </span>
            </LabelWrapper>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t('signup.required-field') },
                {
                  type: 'email',
                  message: t('signup.invalid-email'),
                },
              ]}
            >
              <FormInput
                width="100%"
                height={'45px'} //@ts-ignore
                border_color="#DBEAE3"
                addonAfter={userTypeSelectAfter}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <LabelWrapper>
              <span className="label-text">{t('signup.first-name')}</span>
            </LabelWrapper>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormInput
                width="100%" //@ts-ignore
                border_color="#DBEAE3"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <LabelWrapper>
              <span className="label-text">{t('signup.last-name')}</span>
            </LabelWrapper>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormInput
                width="100%" //@ts-ignore
                border_color="#DBEAE3"
              />
            </Form.Item>
          </Col>
          <Form.Item>
            <ScopeButton type="primary" htmlType="submit">
              {t('users.send-invitation')}
            </ScopeButton>
          </Form.Item>
        </Row>
      </Form>
    );
  };

  return (
    <>
      {!showInvitationForm && renderMembersList()}

      {showInvitationForm && (
        <>
          {allUsers && allUsers?.length > 0 && (
            <>
              <Form layout="vertical">
                <Row justify={'space-between'}>
                  <Col span={24}>
                    <Form.Item
                      name="staffMember"
                      rules={[
                        {
                          required: true,
                          message: t('signup.required-field'),
                        },
                      ]}
                    >
                      <FormSelect //@ts-ignore
                        width="100%"
                        height="45px"
                        bordercolor="#DBEAE3"
                        placeholder="Select existing user"
                        optionLabelProp="title"
                        onChange={(value: any) => onMemberChange(value)}
                      >
                        {allUsers?.map((member, idx) => (
                          <Select.Option
                            key={idx}
                            title={`${member.firstName} ${member.lastName}`}
                            value={member.accountUuid}
                          >
                            <RenderUserDetail
                              record={member}
                              showOption
                              showRole
                            />
                          </Select.Option>
                        ))}
                      </FormSelect>
                    </Form.Item>
                  </Col>

                  <Col>
                    <Form.Item>
                      <ScopeButton
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        onClick={onAddUser}
                        disabled={inviteDisabled()}
                      >
                        {t('users.add-user')}
                      </ScopeButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Divider />
            </>
          )}
          {renderInviteUserForm()}
        </>
      )}
    </>
  );
};
