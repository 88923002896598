import { call, put, select } from 'redux-saga/effects';
import { saveMappingFailure, saveMappingSuccess } from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { SaveMappingRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { PostMappingResponse, postMapping } from '../api';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { ERROR_HANG_TIME } from 'store/app/constants';
import { push } from 'connected-react-router';
import routes from 'routes';
import {
  fetchOrganisationsSuccess,
  //postSaveRefreshMappinData,
} from 'store/organisation/actions';
import {
  getOrgMappings,
  getOrgOrganisations,
} from 'store/organisation/selectors';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* saveMappingSaga(action: SaveMappingRequestAction) {
  try {
    let err: ApiError | null;
    const { connectionId, mappingData } = action.payload;

    const saveMappingResponse: PostMappingResponse = yield call(
      postMapping,
      connectionId,
      mappingData
    );

    err = checkResponse(saveMappingResponse?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success !',
      description: saveMappingResponse.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));
    const newMapping = saveMappingResponse.data.mapping;
    const orgs = yield select(getOrgOrganisations);
    const maps = yield select(getOrgMappings);
    if (mappingData.mappingId) {
      const idx = maps.findIndex((m) => m.mappingId === mappingData.mappingId);
      if (idx !== -1) {
        maps[idx] = newMapping;
      }
      //yield put(postSaveRefreshMappinData(mappingData));
    } else {
      if (maps.findIndex((m) => m.mappingId === newMapping.mappingId) === -1) {
        maps.push(newMapping);
        //yield put(fetchOrganisationsSuccess(orgs, maps));
      }
    }
    yield put(fetchOrganisationsSuccess(orgs, maps));
    yield put(push(routes.organisations.all));
    yield put(saveMappingSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(saveMappingFailure());
  }
}
