//src/components/ImageCrop.jsx

import { useEffect, useState } from 'react';
import { Modal } from './pic-modal';
import { ImageCropModalContent } from './pic-image-crop-modal-content';
import { useImageCropContext } from './pic-image-crop-provider';
import React from 'react';

export const ImageCrop = ({ profilePicture, onClose, uploadImage }) => {
  const [openModal, setOpenModal] = useState(false);

  const { getProcessedImage, setImage, resetStates } = useImageCropContext();

  const handleDone = async () => {
    const avatar = await getProcessedImage();
    resetStates();
    setOpenModal(false);
    uploadImage(avatar);
  };

  useEffect(() => {
    const currentImage = profilePicture?.startsWith('data')
      ? profilePicture
      : `data:image/png;base64,${profilePicture}`;
    setImage(currentImage);
  }, [profilePicture]);

  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">

      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        <ImageCropModalContent
          profilePicture={profilePicture}
          handleDone={handleDone}
          handleClose={() => {
            const currentImage = profilePicture?.startsWith('data')
            ? profilePicture
            : `data:image/png;base64,${profilePicture}`;
            setImage(currentImage);
            onClose();
          }}
        />
      </Modal>
    </div>
  );
};

export default ImageCrop;
