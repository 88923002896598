import { Col, Form, Typography, Row, Select } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { FormSelect } from 'components/Custom/selects';
import { StaffMember } from 'pages/account';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ChangeOwnerProps {
  updateOwner: (accountId: string) => void;
  staffMembers: StaffMember[];
}

interface ChangeOwnerValues {
    staffMember: string;
  }

const { Paragraph } = Typography;

export const ChangeOwner: React.FC<ChangeOwnerProps> = ({
  updateOwner,
  staffMembers,
}) => {
  const { t } = useTranslation();
  const handleChangeOwner = (values: ChangeOwnerValues) =>
    updateOwner(values?.staffMember);
  return (
    <Form layout="vertical" onFinish={handleChangeOwner}>
      <Row justify={'space-between'}>
        <Col span={24}>
          <Paragraph
            style={{
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              textAlign: 'left',
              color: '#879494',
              marginBottom: '40px',
            }}
          >
            You can transfer your account ownership to another user. Your
            account will turn into a regular user account.
          </Paragraph>
        </Col>

        <Col span={24}>
          <Form.Item
            name="staffMember"
            rules={[
              {
                required: true,
                message: t('signup.required-field'),
              },
            ]}
          >
            <FormSelect width="100%" height="45px" bordercolor="#DBEAE3" placeholder="Select User">
              {staffMembers &&
                staffMembers.map((sm, idx) => (
                  <Select.Option key={idx} value={sm.id}>
                    {sm.name}
                  </Select.Option>
                ))}
            </FormSelect>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <ScopeButton type="primary" htmlType="submit">
              {t('users.change-owner')}
            </ScopeButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
