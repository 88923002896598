//src/components/cropper/Cropper.jsx

import EasyCropper from 'react-easy-crop';
import { useImageCropContext } from './pic-image-crop-provider';
import React from 'react';

const Cropper = ({ profilePicture }) => {
  const {
    image,
    zoom,
    setZoom,
    rotation,
    setRotation,
    crop,
    setCrop,
    onCropComplete,
  } = useImageCropContext();

  return (
    <EasyCropper
      image={image || undefined}
      crop={crop}
      zoom={zoom}
      rotation={rotation}
      cropShape="round"
      aspect={1}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
      setRotation={setRotation}
      showGrid={false}
      cropSize={{ width: 185, height: 185 }}
      style={{
        containerStyle: {
          height: 280,
          width: 280,
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        },
      }}
    />
  );
};

export default Cropper;
