import React from 'react';
import { Col, Form, Row } from 'antd';
import styled from 'styled-components';
import { ScopeButton } from 'components/Custom/buttons';
import { useTranslation } from 'react-i18next';
import { turnDrawerOff } from 'store/app/actions';
import { useDispatch } from 'react-redux';
import { FormInput } from 'components/Custom/inputs';
import { LabelWrapper } from 'components/Custom/labels';
import { connectCin7EntityRequest } from 'store/organisation/actions';

const LoadEntityWrapper = styled.div`

  .entity-box {
    width: 100%;
    min-height: 80px;
    border-radius: 10px;
    border: 1px solid #E9F6F7
    opacity: 0px;
    background: #ffffff;
    display:'flex';
    padding: 15px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .entity-box-selected {
    border: 1px solid #41AAAF
  }
  .entity-name{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #042A2A;

  }
  .entity-other-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #879494;
  }
`;
interface FormValues {
  key: string;
  cin7accountId: string;
  orgName: string;
}
export const ConnectCin7: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (values: FormValues) => {
    //selectedTemplate && onLoadTemplate(selectedTemplate);
    /*     const key = '12749c6c-0135-01a5-3b5b-7e502daa342f';
    const cin7accountId = '8bad2786-42f2-470d-97a1-c73e2ce15106';
    const orgName = 'Cin7-Tax'; */
    dispatch(
      connectCin7EntityRequest(values.key, values.cin7accountId, values.orgName)
    );
    dispatch(turnDrawerOff());
  };

  return (
    <LoadEntityWrapper>
      <Form
        name="basic"
        labelCol={{ span: 5 }}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <Row align={'middle'} style={{ marginBottom: 25 }}>
          <Col span={24}>
            <LabelWrapper>
              <span className="label-text">{t('common.account-id')}</span>
            </LabelWrapper>
            <Form.Item
              name="cin7accountId"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormInput />
            </Form.Item>
          </Col>
          <Col span={24}>
            <LabelWrapper>
              <span className="label-text">{t('common.key')}</span>
            </LabelWrapper>
            <Form.Item
              name="key"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormInput />
            </Form.Item>
          </Col>
          <Col span={24}>
            <LabelWrapper>
              <span className="label-text">
                {t('organisation.friendly-name')}
              </span>
            </LabelWrapper>
            <Form.Item
              name="orgName"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormInput />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <ScopeButton
            type="primary" //@ts-ignore
            width="100%"
            htmlType="submit"
          >
            {t('common.connect')}
          </ScopeButton>
        </Form.Item>
      </Form>
    </LoadEntityWrapper>
  );
};
