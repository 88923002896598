import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Form, Row, Typography, Spin, Steps } from 'antd';
import { PasswordInputSettings } from 'antd-password-input-strength';
import { PageWrapper } from 'components/Custom/wrappers';
import { StrongTitleSpan } from 'components/Custom/spans';
import {
  FormPasswordInput,
  FormPasswordInputWithValidation,
  FormInput,
} from 'components/Custom/inputs';
import { ScopeButton } from 'components/Custom/buttons';
import Logo from 'assets/images/generateTAX-logo.svg';
import { CenterLogo } from 'components/Custom/logo';
import { CheckOutlined } from '@ant-design/icons';

interface ValidateUserFormProps {
  //success: boolean;
  //error?: string;
  loading: boolean;
  userExists: boolean | null;
  activate: (password: string) => void;
  resend: (email: string) => void;
  //activationStarted: boolean;
  resetPassword?: boolean;
}
export const ValidateUserForm: React.FC<ValidateUserFormProps> = ({
  loading,
  userExists,
  activate,
  resend,
  //activationStarted,
  resetPassword,
}) => {
  const { t } = useTranslation();
  const handleSubmit = (values: any) => {
    activate(values.password);
  };

  const handleResendSubmit = (values: any) => {
    resend(values.email);
  };

  const defaultSettings: PasswordInputSettings = {
    colorScheme: {
      levels: ['#ff4033', '#fe940d', '#ffd908', '#cbe11d', '#6ecc3a'],
      noLevel: 'lightgrey',
    },
    height: 5,
    alwaysVisible: true,
  };
  return (
    <PageWrapper>
      <Spin spinning={loading} style={{ marginTop: '100px' }} size="large">
        <Row justify="center">
          <CenterLogo src={Logo} />
        </Row>
        {userExists && (
          <Row justify="center">
            <Typography.Title
              level={3}
              style={{
                fontWeight: 400,
                fontSize: 24,
                lineheight: '24px',
                marginTop: '40px',
              }}
            >
              {resetPassword
                ? t('forgot-password.change-your-password')
                : t('signup.almost-there')}
            </Typography.Title>
          </Row>
        )}
        {!resetPassword && userExists && (
          <Row justify="center">
            <Col span={8}>
              <Steps
                current={2}
                labelPlacement="vertical"
                items={[
                  {
                    title: t('signup.fillInDetailsStep'),
                  },
                  {
                    title: t('signup.emailVerifiedStep'),
                  },
                  {
                    title: t('signup.setPasswordStep'),
                    icon: <CheckOutlined width={'14px'} height={'14px'} />,
                  },
                ]}
              />
            </Col>
          </Row>
        )}
        <Row justify="center" style={{ textAlign: 'center' }}>
          <Col span={9}>
            <>
              {userExists ? (
                <Row justify="center">
                  <Col span={23} style={{ marginTop: '40px' }}>
                    <StrongTitleSpan>
                      {t('signup.create-password')}
                    </StrongTitleSpan>
                    <Typography.Text
                      style={{
                        fontWeight: 400,
                        fontSize: 12,
                        lineheight: '18px',
                        color: '#879494',
                      }}
                    >
                      <br />
                      {t('signup.password-description')}
                    </Typography.Text>
                  </Col>
                  <Col span={16} style={{ marginTop: '50px' }}>
                    <Form
                      layout="vertical"
                      onFinish={handleSubmit}
                      style={{ marginBottom: '30px' }}
                    >
                      <Row
                        justify="center"
                        style={{
                          display: 'flex',
                          flexFlow: 'row',
                          marginBottom: '40px',
                        }}
                      >
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: t('signup.pleaseEnterPassword'),
                            },
                            {
                              min: 8,
                              message: '',
                            },
/*                             {
                              pattern:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^_-])[A-Za-z\d@.#$!%*?^&_-]{8,20}$/,
                              message: t('signup.not-match-pattern'),
                            }, */
                          ]}
                        >
                          <FormPasswordInput
                            placeholder={t('signup.password')}
                            //settings={defaultSettings}
                          />
                        </Form.Item>
                        <Form.Item
                          name="confirm"
                          dependencies={['password']}
                          rules={[
                            {
                              required: true,
                              message: t('signup.pleaseConfirmPassword'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('password') === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(t('signup.passwordDoNotMatch'))
                                );
                              },
                            }),
                          ]}
                        >
                          <FormPasswordInput
                            lmargin="30px"
                            placeholder={t('signup.repeatPassword')}
                          />
                        </Form.Item>
                      </Row>
                      <Col span={24}>
                        <Form.Item>
                          <ScopeButton type="primary" htmlType="submit">
                            {t('common.submit')}
                          </ScopeButton>
                        </Form.Item>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              ) : (
                <Row justify="center">
                  <Col span={24}>
                    <Alert
                      type="warning"
                      message={
                        resetPassword
                          ? t('forgot-password.invalid-reset-title')
                          : t('signup.invalid-activation-title')
                      }
                      description={
                        resetPassword
                          ? t('forgot-password.invalid-reset-description')
                          : t('signup.invalid-activation-description')
                      }
                      style={{
                        marginTop: '20px',
                        backgroundColor: 'white',
                        padding: '15px',
                        border: '1px solid #DBEAE3',
                        borderRadius: '15px',
                      }}
                    />
                  </Col>
                  <Col span={24} style={{ marginTop: '10px' }}>
                    <Form
                      layout="vertical"
                      onFinish={handleResendSubmit}
                      style={{ marginBottom: '30px' }}
                    >
                      <Row justify="center">
                        <Col span={16}>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: t('signup.required-field'),
                              },
                              {
                                type: 'email',
                                message: t('signup.invalid-email'),
                              },
                            ]}
                          >
                            <FormInput placeholder={t('signup.email')} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify={'center'}>
                        <Form.Item>
                          <ScopeButton type="primary" htmlType="submit">
                            {resetPassword
                              ? t('forgot-password.resend')
                              : t('signup.resend')}
                          </ScopeButton>
                        </Form.Item>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              )}
            </>
          </Col>
        </Row>
      </Spin>
    </PageWrapper>
  );
};
