import { UserDetails } from 'store/signup/models';
import CryptoJS from 'crypto-js';

export class RememberUser {
  public static EMAIL = 'email';
  public static PASSWORD = 'password';
  public static SECRET = 'oK7HHgeRRB';

  public static save(loginParams: UserDetails): void {
    const { localStorage } = window;
    const emailEncrypted = CryptoJS.AES.encrypt(
      loginParams.email,
      RememberUser.SECRET
    ).toString();

    const passwordEncrypted = CryptoJS.AES.encrypt(
      loginParams.password,
      RememberUser.SECRET
    ).toString();

    localStorage.setItem(RememberUser.EMAIL, emailEncrypted);
    localStorage.setItem(RememberUser.PASSWORD, passwordEncrypted);
  }

  public static retrieve(): UserDetails | null {
    const { localStorage } = window;

    const email2Decrypt = localStorage.getItem(RememberUser.EMAIL);
    const password2Decrypt = localStorage.getItem(RememberUser.PASSWORD);
    if (email2Decrypt !== null && password2Decrypt !== null) {
      const email = CryptoJS.AES.decrypt(
        email2Decrypt,
        RememberUser.SECRET
      ).toString(CryptoJS.enc.Utf8);

      const password = CryptoJS.AES.decrypt(
        password2Decrypt,
        RememberUser.SECRET
      ).toString(CryptoJS.enc.Utf8);

      const loginParams = {
        email,
        password,
      } as UserDetails;

      if (RememberUser.valid(loginParams)) {
        return loginParams;
      }
    }

    return null;
  }

  //   public static getAccessToken(): string | null {
  //     return window.localStorage.getItem(AuthTokenStorage.ACCESS_TOKEN);
  //   }

  public static clear(): void {
    const { localStorage } = window;
    localStorage.removeItem(RememberUser.EMAIL);
    localStorage.removeItem(RememberUser.PASSWORD);
  }

  private static valid(params: UserDetails): boolean {
    return !empty(params.email) && !empty(params.password);
  }
}

function empty(x: string | number | null) {
  return x === null;
}
