import { takeLatest } from 'redux-saga/effects';
import {
  CONNECT_CIN7_ENTITY_REQUEST,
  DISCONNECT_ORGANISATION_REQUEST,
  EDIT_ORGANISATION_REQUEST,
  FETCH_ADD_URL_REQUEST,
  FETCH_ORGANISATIONS_REQUEST,
} from '../constants';
import { fetchOrganisationsSaga } from './fetchOrganisationsSaga';
import { fetchAddUrlSaga } from './fetchAddUrlSaga';
import { disconnectOrganisationSaga } from './disconnectOrganisationSaga';
import { editOrganisationsSaga } from './editOrganisationsSaga';
import { connectCin7EntitySaga } from './connectCin7EntitySaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* organisationSaga() {
  yield takeLatest(FETCH_ORGANISATIONS_REQUEST, fetchOrganisationsSaga);
  yield takeLatest(FETCH_ADD_URL_REQUEST, fetchAddUrlSaga);
  yield takeLatest(CONNECT_CIN7_ENTITY_REQUEST, connectCin7EntitySaga);
  yield takeLatest(DISCONNECT_ORGANISATION_REQUEST, disconnectOrganisationSaga);
  yield takeLatest(EDIT_ORGANISATION_REQUEST, editOrganisationsSaga);
}
