import { Typography } from 'antd';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

export const ReportingPage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Reporting | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This is a page for Reporting" />
      </Helmet>
      <Typography.Title level={3}>Reporting Page</Typography.Title>
    </React.Fragment>
  );
};
