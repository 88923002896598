import { Provider } from 'store/api/enums';

export function getProviderName(provider: string) {
  if (provider === Provider.CIN7) return 'Cin7';

  return toTitleCase(provider);
}

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}
