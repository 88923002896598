import { notification } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { turnMessageOff } from 'store/app/actions';
import { getMessage, getShowMessage } from 'store/app/selectors';

export enum MessageStates {
  SUCCESS,
  ERROR,
  INFO,
  WARNING,
  OPEN,
}

export const MessageBoxContainer: React.FC = () => {
  const dispatch = useDispatch();
  const showMessage = useSelector(getShowMessage);
  const message = useSelector(getMessage);

  const [notificationApi, contextHolder] = notification.useNotification();
  const openMessage = () => {
    let props: any = {
      message: message?.title,
      description: message?.description,
      placement: 'bottomLeft',
    };
    if (typeof message?.duration === 'number')
      props = { ...props, duration: message.duration };

    if (message?.button) props = { ...props, btn: message.button };

    switch (message?.type) {
      case MessageStates.SUCCESS:
        notificationApi.success(props);
        break;
      case MessageStates.ERROR:
        notificationApi.error(props);
        break;
      case MessageStates.INFO:
        notificationApi.info(props);
        break;
      case MessageStates.WARNING:
        notificationApi.warning(props);
        break;
      case MessageStates.OPEN:
        notificationApi.open(props);
        break;
    }
    dispatch(turnMessageOff());
  };

  React.useEffect(() => {
    showMessage && openMessage();
  }, [showMessage]);

  return <div>{contextHolder}</div>;
};
