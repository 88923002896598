import {
  CurrentPage,
  DrawerData,
  Locale,
  MessageData,
  ModalData,
  SetCurrentPageAction,
  SetLocaleAction,
  SetMenuSize,
  SetRefreshDone,
  TurnDrawerOffAction,
  TurnDrawerOnAction,
  TurnMessageOffAction,
  TurnMessageOnAction,
  TurnModalOffAction,
  TurnModalOnAction,
  UpdateMappingData,
  UpdateUserPictures,
} from './types';
import {
  SET_CURRENT_PAGE,
  SET_LOCALE,
  SET_MENU_SIZE,
  SET_REFRESH_DONE,
  TURN_DRAWER_OFF,
  TURN_DRAWER_ON,
  TURN_MESSAGE_OFF,
  TURN_MESSAGE_ON,
  TURN_MODAL_OFF,
  TURN_MODAL_ON,
  UPDATE_MAPPING_DATA,
  UPDATE_USER_PICTURES,
} from './constants';
import { createAction } from '../utils';
import { MenuSize } from './enums';
import { MappingData } from 'store/returns/models';

export const setLocale = (locale: Locale): SetLocaleAction =>
  createAction(SET_LOCALE, { locale });

export const setCurrentPage = (
  currentPage: CurrentPage
): SetCurrentPageAction => createAction(SET_CURRENT_PAGE, { currentPage });

// Messaging
export const turnMessageOn = (message: MessageData): TurnMessageOnAction =>
  createAction(TURN_MESSAGE_ON, { message });

export const turnMessageOff = (): TurnMessageOffAction =>
  createAction(TURN_MESSAGE_OFF);

// Modal
export const turnModalOn = (modalData: ModalData): TurnModalOnAction =>
  createAction(TURN_MODAL_ON, { modalData });

export const turnModalOff = (): TurnModalOffAction =>
  createAction(TURN_MODAL_OFF);

// Drawer
export const turnDrawerOn = (drawerData: DrawerData): TurnDrawerOnAction =>
  createAction(TURN_DRAWER_ON, { drawerData });

export const turnDrawerOff = (): TurnDrawerOffAction =>
  createAction(TURN_DRAWER_OFF);

// Refresh Done
export const setRefreshDone = (): SetRefreshDone =>
  createAction(SET_REFRESH_DONE);

// Set Menu Size
export const setMenuSize = (menuSize: MenuSize): SetMenuSize =>
  createAction(SET_MENU_SIZE, { menuSize });

// Update Mapping Data
export const updateMappingData = (
  mappingData?: MappingData
): UpdateMappingData => createAction(UPDATE_MAPPING_DATA, { mappingData });

// Update User Pictures
export const updateUserPictures = (
  profilePicture: string,
  userUuid: string
): UpdateUserPictures =>
  createAction(UPDATE_USER_PICTURES, { profilePicture, userUuid });
