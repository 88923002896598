import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography } from 'antd';
import { PageWrapper } from 'components/Custom/wrappers';
import Logo from 'assets/images/generateTAX-logo.svg';
import EmailLogo from 'assets/images/Emails-amico@3x.png';
import { CenterLogo } from 'components/Custom/logo';
import { BoldSpanLargeSize } from 'components/Custom/spans';
import { Link } from 'react-router-dom';
import routes from 'routes';

interface SignUpSuccessProps {
  resetPassword?: boolean;
}
export const SignUpSuccess: React.FC<SignUpSuccessProps> = ({
  resetPassword,
}) => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Row justify="center">
        <CenterLogo src={Logo} />
      </Row>
      <Row justify="center">
        <CenterLogo width="300px" height="300px" src={EmailLogo} />
      </Row>
      <Row justify="center">
        <Col
          span={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row justify="center">
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                flexFlow: 'column',
                marginBottom: '25px',
              }}
            >
              <div>
                <Typography.Title
                  level={3}
                  style={{
                    fontWeight: 400,
                    minWidth: '400px',
                  }}
                >
                  {resetPassword ? (
                    t('forgot-password.email-sent-title')
                  ) : (
                    <>
                      {t('signup.success-title')}{' '}
                      <BoldSpanLargeSize>
                        {t('signup.signup-app-title')}
                      </BoldSpanLargeSize>
                    </>
                  )}
                </Typography.Title>
                <Typography.Text
                  style={{
                    fontWeight: 300,
                    fontSize: '16px',
                    minWidth: '624px',
                  }}
                >
                  {resetPassword
                    ? t('forgot-password.email-sent-description1')
                    : t('signup.success-description1')}
                  <br />
                  {resetPassword
                    ? t('forgot-password.email-sent-description2')
                    : t('signup.success-description2')}
                </Typography.Text>
              </div>
            </Col>
            <Col
              span={20}
              style={{
                backgroundColor: 'white',
                padding: '15px',
                border: '1px solid #DBEAE3',
                borderRadius: '15px',
              }}
            >
              <Typography.Title
                level={4}
                style={{
                  fontWeight: 700,
                  fontSize: 14, //@ts-ignore
                  lineheight: '20px',
                  color: '#042A2A',
                  marginTop: 0,
                }}
              >
                {t('signup.spam-title')}
              </Typography.Title>

              <Typography.Text
                style={{
                  fontSize: '12px',
                  fontWeight: 400, //@ts-ignore
                  lineheight: '18px',
                  color: '#879494',
                }}
              >
                {`${t('signup.spam-description1')} ${t(
                  'signup.spam-description2'
                )}`}
              </Typography.Text>
            </Col>
            <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
              <Link className="link link-text" to={routes.login}>
                {t('forgot-password.back-to-login')}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageWrapper>
  );
};
// const t = {
//   email: 'kamo@mail.com',
//   redirectURL: 'http://localhost:8000/forgot-password/confirm',
// };
