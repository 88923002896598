import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { providerSignupRequest, registerRequest } from 'store/signup/actions';
import {
  fetchBusinessSizesRequest,
  fetchBusinessTypesRequest,
  fetchCountriesRequest,
  fetchCountriesExternalDataRequest,
} from 'store/common/actions';
import { getSignUpLoading } from 'store/signup/selectors';
import {
  getCommonBusinessSizes,
  getCommonBusinessTypes,
  getCommonCountries,
  getCommonLoading,
  getCountriesExternalData,
} from 'store/common/selectors';
import { SignUpForm } from 'components/SignUpForm';
import { UserDetails } from 'store/signup/models';
import { RegisterRequestAction } from 'store/signup/types';
import { Helmet } from 'react-helmet-async';
import { Country } from 'store/common/models';
import { Provider } from 'store/api/enums';
import { Form } from 'antd';
import { getCookie } from 'utils/get-cookie';

interface SsoValues {
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  subIdentifier: string;
  realmId: string;
  tokenCode: string;
}
export const SignUpPage: React.FC = () => {
  const signupLoading = useSelector(getSignUpLoading);
  const countries = useSelector(getCommonCountries);
  const countriesExternalData = useSelector(getCountriesExternalData);
  const businessTypes = useSelector(getCommonBusinessTypes);
  const businessSizes = useSelector(getCommonBusinessSizes);
  const commonLoading = useSelector(getCommonLoading);
  const loading = signupLoading || commonLoading;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [ssoSignUp, setSsoSignUp] = useState<boolean>(false);
  const [showPage, setShowPage] = React.useState(false);

  const checkForSSO = () => {
    const ssoCookie = getCookie('SSO-USER-DETAILS');
    const ssoLocal = localStorage.getItem('SSO-USER-DETAILS');
    let ssoValues: SsoValues | null = null;

    localStorage.removeItem('SSO-USER-DETAILS');
    if (ssoCookie) {
      ssoValues = JSON.parse(ssoCookie);
    } else if (ssoLocal) {
      ssoValues = JSON.parse(ssoLocal);
    }
    if (ssoValues) {
      form.setFieldsValue({
        ['firstName']: ssoValues.userFirstName,
        ['lastName']: ssoValues.userLastName,
        ['email']: ssoValues.userEmail,
        ['subIdentifier']: ssoValues.subIdentifier,
        ['tokenCode']: ssoValues.tokenCode,
        ['realmId']: ssoValues.realmId,
      });
      setSsoSignUp(true);
    }
  };
  const onRegister = (
    user: UserDetails,
    gCaptchaResponse: string
  ): RegisterRequestAction => {
    return dispatch(registerRequest(user, gCaptchaResponse, ssoSignUp));
  };

  const callProviderSSO = (provider: Provider) => {
    dispatch(providerSignupRequest(provider));
  };
  const [scopeCountries, setScopeCountries] = React.useState<Country[]>([]);
  useEffect(() => {
    dispatch(fetchCountriesRequest());
    dispatch(fetchCountriesExternalDataRequest());
    dispatch(fetchBusinessSizesRequest());
    dispatch(fetchBusinessTypesRequest());
    checkForSSO();
    setShowPage(true);
  }, []);

  useEffect(() => {
    if (countries && countries.length > 0) {
      const scopeCnt: Country[] = countries.filter((c) => c.scopeSupported);

      setScopeCountries(scopeCnt);
    }
  }, [countries]);

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`Sign Up | ${process.env.PROJECT_NAME}`}</title>
            <meta
              name="description"
              content="This a page for signing up new clients"
            />
          </Helmet>

          <div>
            <SignUpForm
              onRegister={onRegister}
              countries={countries}
              countriesExternalData={countriesExternalData}
              businessTypes={businessTypes}
              businessSizes={businessSizes}
              scopeCountries={scopeCountries}
              loading={loading}
              callProviderSSO={callProviderSSO}
              form={form}
              ssoSignUp={ssoSignUp}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );
};
