import { Row, Col, Typography } from 'antd';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import routes from 'routes';

export const PrivacyPolicy: React.FC = () => {
  const { Text, Title } = Typography;
  const textStyle = { fontSize: '14px', fontWeight: 300 };
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Privacy policy | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for terms of use" />
      </Helmet>
      <Row justify="center" style={{ backgroundColor: '#f6f6f7' }}>
        <Col
          span={15}
          style={{ backgroundColor: 'white', padding: '20px 60px' }}
        >
          <Title level={4}>Privacy Policy</Title>
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            generate.TAX Service
          </Text>
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '18px', fontWeight: '300' }}>
            www.generate.tax
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            Thank you for visiting our Website and/or using our Service. We
            respect your privacy. When it comes to your personal information, we
            believe in transparency not surprises. We want you to know what
            personal data we collect, what we do with it as well as your choices
            and rights at law.
            <br />
            <br />
            Although our goal is to always be as clear and transparent as
            possible, we appreciate that legal documents can sometimes be
            difficult to read. However, we strongly encourage you to read this
            Privacy Policy with care. Please do not hold back from contacting us
            for any clarification you may need with respect to the processing of
            your personal data by us. This Privacy Policy shall be without
            prejudice to any confidentiality obligations subsisting under
            relevant contractual arrangements and any liability associated with
            or arising therefrom.
            <br />
            <br />
            We may need to revise and update this Privacy Policy, from time to
            time, to make sure we provide you, at all times, with the
            information you are entitled to receive under applicable law, as
            well as for us to ensure that, our Privacy Policy addresses, at all
            times, your data protection concerns, and that we are/remain
            compliant with applicable law.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>KEY TERMS</Text>
          <br />
          <br />
          <Text style={textStyle}>
            When we say ‘us’, ‘we’ or ‘our’, we mean generate.TAX Limited ,
            having its registered and business office address located at SCOPE,
            Digital Hub, Malta Life Sciences Park, San Gwann, SGN 3000, Malta, –
            and any associated or other group companies. We own, develop and
            maintain the generate.TAX Service (or are duly licensed by our
            licensor to this effect), this being a tax generation tool, designed
            for Xero accounting software.
            <br />
            <br />
            When we refer to ‘you’ or ‘your’, we mean the Account Owner, User
            and other data subjects whose personal data is being processed by
            us, including visitors of our Website or anyone attending our
            events;
            <br />
            <br />
            When we refer to ‘Account Owner’, we mean the person or entity who
            registers for an account with us directly or using the third-party
            methods such as Xero and Google to use the Service, and, where the
            context permits, includes any entity on whose behalf that person
            registers to use the Service;
            <br />
            <br />
            When we refer to ‘data subject’, we mean any identified or
            identifiable natural person whose personal data is being processed
            by us; an identifiable natural person is one who can be identified,
            directly or indirectly, in particular by reference to, inter alia,
            an identifier such as a name, an identification number, location
            data, bank account and payment information, an online identifier or
            to one or more factors specific to that natural person; If you are
            no longer identified or identifiable and your personal data has been
            anonymized, this privacy policy shall not apply;
            <br />
            <br />
            When we refer to ‘personal data/information’, we mean any
            information relating to a data subject;
            <br />
            <br />
            When we refer to ‘processing’, we mean, inter alia, any operation/s
            performed on personal data, whether or not by automated means, such
            as collection, recording, organisation, storage, alteration,
            retrieval, use, disclosure by transmission, dissemination or
            otherwise making available, restriction, erasure or destruction;
            <br />
            <br />
            When we refer to ‘Service’, we mean the SaaS by the name of
            generate.TAX, which provides online accounting and personal finance
            management services, as may be changed, modified or updated by Us,
            from time to time, and which is made available to the Account Owner
            and other Users via the Website;
            <br />
            <br />
            When we say ‘SaaS’, we mean a software-as-a-service, hence a
            creation of cloud computing whereby software is licensed by software
            vendors and distributed on a subscription basis, and is centrally
            hosted;
            <br />
            <br />
            When we refer to ‘User’, we mean any person or entity, other than
            the Account Owner, that uses the Service with the authority of the
            Account Owner;
            <br />
            <br />
            When we refer to ‘Website’, we mean the generate.TAX internet site
            at the domain www.generate.tax through which Account Owners and
            Users can also access the generate.TAX dashboard by logging into
            their respective account to use the Service;
            <br />
            <br />
            When we refer to ‘third party applications’, we mean other SaaS or
            accounting applications hosted on the cloud, the likes of Xero
            accounting, for use in conjunction with the Service.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            APPLICABLE DATA PROTECTION LEGISLATION
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            As an entity established in Malta, and therefore in the EU, the main
            data protection law that is applicable to us in so far as your
            personal data is concerned, is the General Data Protection
            Regulation, namely Regulation (EU) 2016/679 of the European
            Parliament and of the Council of 27 April 2016 on the protection of
            natural persons with regard to the processing of Personal Data and
            on the free movement of such data, repealing Directive 95/46/EC (the
            ‘GDPR’), which Regulation became directly applicable on 25th May
            2018, and Chapter 586 of the Laws of Malta, the Data Protection Act
            (the ‘DPA’), including the various subsidiary legislation issued
            under the same, which DPA implements and further specifies the
            relevant provisions of the GDPR and repeals and replaces Chapter 440
            of the Laws of Malta, this being the former local legislation
            governing data protection, as amended or supplemented, from time to
            time.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            THE PRIVACY POLICY
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            We act as a ‘controller’ in relation to your personal data when we
            process such data in our own right, for our own purposes. This
            includes the processing of personal data relating to our customers,
            hence Account Owners or potential ones, our suppliers, our
            recognized or potential business partners, our third party service
            providers as well as our employees.
            <br />
            <br />
            We act as a ‘processor’ in relation to your personal data when we
            process such data in our quality as processor, acting on behalf of a
            data controller or other processor. This may include the processing
            of personal data relating to organisations or persons you added to
            the Service or otherwise added with your authority or as a result of
            your use of the Service (‘connected organisations’), including
            customers, suppliers, third party service providers, employees,
            hence including Users, of the Account Owner itself. In this case,
            the Account Owner is deemed to be the controller if it controls and
            determines the purposes and means of processing of personal data,
            and it shall be deemed to be another processor along the processing
            chain if the controller is another party. We are considered as
            processors since we may carry out certain activities which may
            include the collecting, recording, organising, storing, using,
            transmitting, retrieving or deleting of personal data, on your
            behalf, in your quality as data controller or other processor. As
            processors, we shall process your personal data strictly on the
            documented instructions received from the controller.
            <br />
            <br />
            This Privacy Policy attempts to describe what, why, how and on what
            legal basis we process personal data, the relevant security measures
            to protect the privacy of your personal data, prevent the
            unauthorised access to or processing of your personal data, as well
            as details regarding the various rights you are entitled to exercise
            at law, as data subjects, in relation to your personal data.
            <br />
            <br />
            We assume responsibility for our processing activities, and shall be
            accountable to data subjects, in our quality as a controller and/or
            processor, in accordance with and to the extent applicable, as
            prescribed by applicable data protection legislation.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            LAWFUL PROCESSING AND LEGAL BASIS FOR PROCESSING PERSONAL DATA
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            We only process personal data, received from you or otherwise
            obtained from other sources, through lawful means, as described
            below, that is absolutely relevant and limited to what is necessary
            in relation to the purpose for which it is processed, and, only if
            we:
            <ul style={{ listStyleType: 'disc', margin: '15px' }}>
              <li>
                require that data to be able to provide you with the Service you
                request from us in accordance with and in furtherance of the
                performance of the contract allowing you to use the Service. The
                said contract can be viewed on the ‘Terms of Use’ section on our
                Website or else you may access it by clicking the following URL:{' '}
                <Link to={routes.termsOfUse} target="_blank">
                  {' '}
                  https://app.generate.tax/termsofuse
                </Link>
                ;
              </li>
              <li>
                are legally required to collect/use/process that data and to
                keep it for a predetermined period of time in satisfaction of a
                legal obligation. We have a legal obligation to use and process
                your personal information when required to do so to be able to
                comply with applicable tax, employment and other government or
                other applicable law and regulations, or to comply with a court
                order;
              </li>
              <li>
                believe this to be necessary for our legitimate business
                interests:
                <br />
                <ul style={{ listStyleType: 'circle', marginLeft: '15px' }}>
                  <li>
                    to operate and develop and promote our business interests;
                  </li>

                  <li>
                    for business administration purposes, say, for instance to
                    process invoices, and so on;
                  </li>

                  <li>
                    to be in a position to establish, exercise, enforce and
                    defend legal claims;
                  </li>

                  <li>
                    to analyse and improve the safety and security of our
                    Service and Website such as by implementing and enhancing
                    security measures and protections and protecting against
                    fraud, spam, abuse and other malicious activity;
                  </li>

                  <li>
                    to provide and improve the quality of our Service and
                    Website since we strive to offer, at all times, an
                    innovative product and service to our Users and visitors of
                    our Website;
                  </li>
                </ul>
              </li>
              <li>
                obtained your prior consent, provided that, in those instances
                where we process personal data on the basis of your consent
                (which we will never presume but which we would have obtained in
                a clear and manifest manner from you), you can retract your
                consent at any time, and this, in the same manner as you would
                have provided it to us. Should you exercise your right to
                withdraw your consent at any time, in the manner and following
                the procedure prescribed by us, we will determine whether at
                that stage an alternative legal basis exists for the processing
                of your personal data (for example, on the basis of a legal
                obligation to which we are subject or our legitimate business
                interest) where we would be legally authorised (or even obliged)
                to process your personal data without needing your consent, and
                if so, notify you accordingly. When we ask for such personal
                data, you are at liberty to provide the same, however should you
                decline to provide us with the necessary data that we require to
                provide the Service or other requested services, we may find
                ourselves in a position of not necessarily being able to provide
                you with the Service or such services (especially if consent is
                the only legal ground that is available to us to permit such
                processing).
              </li>
            </ul>
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            HOW DO WE COLLECT YOUR PERSONAL DATA
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            When you use our Service or visit our Website we may come into
            possession of and undertake processing activities with respect to
            your personal data. We may obtain personal information from
            different sources, provided that, we have a valid lawful basis for
            doing so:
            <br />
            <ul style={{ listStyleType: 'disc', margin: '15px' }}>
              <li>
                you provide some of it directly to us (such as when you register
                with us to use the Service, submit customer queries or
                participate on community forums);
              </li>
              <li>
                we record some of it automatically when you use our Service or
                Website (by applying cookies and other tracking technologies);
              </li>
              <li>
                information we obtain from third parties (including the Account
                Owner, providers of third party applications, and so on).
              </li>
            </ul>
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            WHAT PERSONAL DATA DO WE COLLECT, WHY AND THE LEGAL BASIS FOR DOING
            SO?
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            When you use our Service or visit our Website we may carry out
            processing activities in relation to your personal data. When we say
            personal data we mean any data in relation to you, assuming you are
            an identified or identifiable natural person. Therefore, if any data
            we process can be linked or traced back to you, in consequence of us
            being in possession of, say, your first name/last name, email
            address, physical billing address, telephone/mobile number, IP
            Address, unique device IDs, bank account details, payment
            information, and so on, this data warrants protection under
            applicable data protection legislation. If you can no longer be
            identified/identifiable (for example, when personal data has been
            aggregated and anonymised) then this notice does not apply.
            <br />
            <br />
            It shall be lawful for us to collect and process personal
            information regarding you or your device, for the fulfilment of or
            in satisfaction of different lawful purposes, provided that a valid
            legal basis subsists for doing so, be it by virtue of a contractual
            obligation subsisting in furtherance of the provision of the Service
            to you, in accordance with a legal obligation subsisting in our
            regard, a legitimate interest we wish to protect except where such
            interest is overridden by your interests or fundamental rights and
            freedoms, or we have otherwise obtained your prior consent.
            <br />
            <br />
            This includes the processing of personal data as follows:
            <br />
            <ul style={{ listStyleType: 'disc', margin: '15px' }}>
              <li>
                your contact details, specifically, your email address, first
                name and last name, and other business information, which you
                provide to us for the purpose of becoming an Account Owner or
                User, and to enable us to continue to manage your account and
                relationship with us, in furtherance of the performance of the
                contract allowing you to use the Service, including:
              </li>

              <li>
                to allow you to log in/open support tickets/requests and enable
                us to provide technical support to you;
              </li>

              <li>
                to enable us to communicate with you by sending you e-mails
                about your transactions, as well as providing Service or
                Website-related announcements including updates, additional
                features or functionality of the Service and Website, and so on;
              </li>

              <li>
                to send to you tailored marketing communications about our
                products, services, offers, programs and promotions or those of
                our trusted providers of third party applications, which we
                believe may interest or be relevant to you, subject to your
                right to object to your data being used for direct marketing
                purposes;
              </li>

              <li>
                to ask you for your feedback or to take part in any research we
                are conducting;
              </li>

              <li>
                information which you agree to submit to us by email, or online,
                in the requested form, and other information and communications
                that you contribute or share on the Website, as a result of the
                use of our Service or upon visiting our Website, including when:
                <ul style={{ listStyleType: 'circle', marginLeft: '15px' }}>
                  <li>
                    signing up for free trials – we store and process this
                    personal information only for the purpose of enabling you to
                    use the Service, free of charge, for a limited period of 30
                    days, and thereafter, we shall have a legitimate interest to
                    continue to store and process these details for a reasonable
                    period of time so as to enable us to keep record of your
                    trial history and thus identify potential abusive practices;
                  </li>

                  <li>
                    using customer message boards or requesting information from
                    us, or participating in community forums and blogs available
                    on the Website, whether you are an Account Owner or a User
                    or not – we use the personal information submitted in this
                    regard only to allow us to respond to your message, and,
                    except where such information is visible on a publicly
                    available community forum or blog, such information will be
                    removed once you are satisfied with the answers/feedback
                    provided;
                  </li>
                </ul>
              </li>
              <li>
                contact details and other information which you agree to share
                with us at events, roadshows, and so on, whether you are an
                Account Owner or a User, or a potential one, or otherwise, which
                we have a legitimate interest to continue to store and process,
                for the purpose of enabling us to identify/solicit/procure new
                business from previous leads;
              </li>

              <li>
                information you share with us in connection with or in pursuance
                of surveys, contests or marketing promotions you agree to
                participate in;
              </li>

              <li>
                information obtained automatically upon using our Service or
                accessing, reading or downloading information from our Website.
                Please see our cookie policy under the heading below entitled
                ‘Use of Cookies’;
              </li>

              <li>
                when you use our Service, we process usage patterns and
                preferences, namely information about how you interact with the
                Service and the Website, including timestamps, clicks,
                scrolling, browsing times, searches, transactions, referral
                pages, load times, and problems you may encounter, such as
                loading errors. Most actions carried out by Users are logged so
                as to provide information to Account Owners regarding what data
                was used by whom. Logged in data includes the IP address of the
                User and what action was requested. We also process the list of
                connected organisations and groups so that you do not have to
                connect these anew every time you use the Service. This
                processing activity enables us to provide a better-quality
                Service in furtherance of the performance of the contract
                allowing you to use the Service. This information is stored for
                around 6 months until it is deleted. Account Owners and Users
                should keep a backup of this data in case they need to retain
                this for a longer period;
              </li>

              <li>
                in furtherance of the performance of the contract allowing you
                to use the Service, and, in so far as the Service may be (and is
                usually) used in conjunction with third party applications, you
                acknowledge that providers or facilitators of third party
                applications may access your personal data or data of connected
                organisations, without which, the inter-operability of the
                Service with such third party applications, may not be possible.
                Furthermore, any data requested by an Account Owner or User from
                the provider or facilitator of such third party application may
                be temporarily stored in memory on our server for 1 hour for
                caching purposes. Caching may be used to improve the quality and
                performance of the Service experienced by the User in case
                he/she needs to reload the data. The User may control this
                cached data such that it may be disabled or deleted at any point
                in time directly from the add-in preferences available on the
                generate.TAX dashboard.
              </li>
            </ul>
            <br />
            <br />
            Please note that the information collected or otherwise processed is
            strictly intended for the sole use by us in the manner and for the
            purpose/s afore-described, and, it is not shared, unless otherwise
            provided herein, nor is it leased or sold in any manner to any other
            organisation. Where it is not possible or feasible for us to make
            use of anonymous and/or anonymised data, we are nevertheless
            committed to protecting your privacy and the security of your
            personal data at all times.
            <br />
            <br />
            It is further understood that, by providing us with or allowing us
            to access personal data relating to individuals other than yourself,
            you are letting us know that you have obtained the necessary
            authorisations for you to send us that personal data or to procure
            or permit us to access that data in the manner described in this
            Privacy Policy.
            <br />
            <br />
            Furthermore, our Service and Website are intended for a general
            audience interested in or using an online accounting and finance
            management tool. For this reason, we do not knowingly collect or
            process information with regard to children or other individuals who
            are not legally capable of using the same. Nor do we knowingly
            collect or process special categories of personal data as understood
            by applicable data protection legislation. If we become aware that
            we have collected or otherwise processed information with regard to
            a child or information that is otherwise captured by the ‘special
            category of data’ provisions, we will promptly do our utmost to
            delete it, unless we are legally obligated to retain such data, or
            otherwise comply with applicable data protection legislation. If you
            believe that we have mistakenly or unintentionally collected or
            otherwise processed information with regard to a child or special
            categories of data subjects, please contact us.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            WITH WHOM DO WE SHARE YOUR PERSONAL DATA?
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            There will be times when we may share, disclose or transmit your
            personal data with/to third parties, namely:
            <br />
            <ul style={{ listStyleType: 'disc', margin: '15px' }}>
              <li>
                our group companies, affiliated or associated companies,
                including relevant employees or agents, in furtherance of the
                provision, delivery or performance of the Service, as
                afore-described;
              </li>

              <li>
                third party service providers, entities to whom we may outsource
                certain functions/duties and partners, who assist and enable us
                to use personal data to, say, support delivery of or provide
                functionality to, the Service or Website;
              </li>

              <li>
                data transmitted to or received from providers or facilitators
                of third party applications, in furtherance of the provision,
                delivery or performance of the Service, as afore-described;
              </li>

              <li>
                where required by a regulator, public authority, law enforcement
                body or the lawful order of a court of law, or is otherwise
                required for us to comply with any applicable law or
                regulations, or to enforce or defend a legal claim;
              </li>

              <li>
                if we are involved in an actual or potential business
                reorganization, including merger, division, acquisition or sale
                of some or all of our assets or any part of our business, your
                personal information may be shared with any such interested
                party or their advisors;
              </li>

              <li>
                any other person provided that we obtain your prior consent.
              </li>
            </ul>
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            STORAGE OF PERSONAL DATA AND USE OF SUB-PROCESSORS
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            We may appoint sub-processors to assist us in our processing
            activities, provided that, no processor will be engaged by us unless
            we have first obtained the prior written Authorisation of the
            controller. The controller shall also be informed by us of any
            intended changes concerning the addition or replacement of other
            processors so as to give the opportunity to the controller to object
            to such changes. We shall ensure that our sub-processors are bound
            by the same data protection obligations as set out in the contract
            existing between us, in our quality as processor, and the Account
            Owner, in its quality as controller (see ‘Terms of Use’ section on
            our Website or else you may access it by clicking the following URL:
            <Link to={routes.termsOfUse} target="_blank">
              {' '}
              https://app.generate.tax/terms-of-use
            </Link>
            . This includes the provision of sufficient guarantees by the
            sub-processor toward the implementation of appropriate technical and
            organisational measures to meet the requirements of applicable data
            protection legislation. We shall remain answerable to the controller
            for the failure or improper performance of our sub-processor’s
            obligations.
            <br />
            <br />
            We do not host any of your data on our own data centre. We use the
            business cloud services of Microsoft Azure whose servers are located
            in Ireland, Europe. This location was strategically chosen due to
            its position and protection afforded under applicable data
            protection legislation in the EU. You can access Microsoft Azure’s
            website via the following URL:{' '}
            <a
              href="https://azure.microsoft.com/en-us/?v=18.20"
              target="_blank"
            >
              https://azure.microsoft.com/en-us/?v=18.20
            </a>
            <br />
            <br />
            We also use Zoho, a client relationship management tool and a
            helpdesk tool through which your data may be stored on servers
            located in either Ireland or the United States, accessible from the
            following URL:{' '}
            <a href="https://www.zoho.com/gdpr.html" target="_blank">
              https://www.zoho.com/gdpr.html
            </a>
            ,
            <a href="https://www.zoho.com/crm/gdpr/" target="_blank">
              https://www.zoho.com/crm/gdpr/
            </a>{' '}
            and
            <a href="https://www.zoho.com/desk/gdpr.html" target="_blank">
              https://www.zoho.com/desk/gdpr.html
            </a>
            <br />
            <br />
            As such our focus is to ensure that data flowing through our network
            and servers does not get intercepted or tampered. All communications
            and data flows over the internet use Secure Socket Layer (SSL) thus
            ensuring that data is encrypted in the process. Independent
            vulnerability tests are carried out on the full cycle to ensure the
            application's security levels are of the highest standard.
            <br />
            <br />
            We maintain an up-to-date list of sub-processors that are currently
            engaged by us to carry out processing activities on behalf of the
            controller.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            INTERNATIONAL DATA TRANSFERS
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            When we share your data, if it is transferred to, or processed in,
            third countries, hence countries outside of the EEA, we shall ensure
            that appropriate safeguards are put in place so as to protect your
            personal data and not undermine your rights under applicable data
            protection legislation, and, consequently, such data shall only be
            shared with, transferred to or processed in countries that have been
            identified as providing an adequate level of protection equivalent
            to EU standards by the EU Commission, or by making sure that
            approved transfer mechanisms have been executed with/by the data
            recipient/importer including the acceptance and use, in their
            entirety, of the European Commission’s Standard Contractual Clauses
            (accessible on the following URL:{' '}
            <a
              href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"
              target="_blank"
            >
              https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en
            </a>
            ), or the employment of binding corporate rules for transfers within
            a group companies, where applicable (accessible on the following
            URL:{' '}
            <a
              href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/binding-corporate-rules_en"
              target="_blank"
            >
              https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/binding-corporate-rules_en
            </a>
            ).
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            WHAT ARE YOUR RIGHTS?
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            Where we act as controller of your personal data, other than the
            right to receive transparent information regarding the processing of
            your personal data – which we have attempted to satisfy by way of
            providing information and making relevant disclosures throughout
            this Privacy Policy – you are entitled to exercise a number of other
            rights by sending us an email on{' '}
            <a href="mailto:helpme@generate.tax">helpme@generate.tax</a>:
            <br />
            <ul style={{ listStyleType: 'disc', margin: '15px' }}>
              <li>
                the right of access – you can obtain from us a confirmation as
                to whether or not personal data concerning you is being
                processed by us, and, shall have the right to access, your
                personal data, free of charge and without undue delay;
              </li>

              <li>
                the right to rectification – you can obtain from us the
                rectification of inaccurate personal data concerning you,
                without undue delay. You can do so either by making the
                necessary changes directly through the generate.TAX dashboard
                after logging into your account to use the Service or by
                contacting us to request the required changes;
              </li>

              <li>
                the right ‘to be forgotten’ – you can obtain from us the
                deletion of any or all personal information, without undue
                delay, upon the occurrence of one or more circumstances,
                including, inter alia, the information being no longer necessary
                to fulfil the purpose for which it was originally collected or
                processed, the withdrawal of your consent where consent was the
                legal basis for processing, the unlawful processing of your
                personal data, your objection to processing provided that there
                are no over-riding legitimate grounds to continue processing,
                and so on;
              </li>

              <li>
                the right to restrict – you have the right to obtain from us the
                restriction of processing of your data, upon the occurrence of
                one or more circumstances, including , inter alia, the accuracy
                of the data is contested, your personal data is unlawfully
                processed, you require it to be able to establish, enforce or
                defend a legal claim, you restrict processing pending
                verification as to whether our legitimate interests for
                processing over-ride yours, and so on;
              </li>

              <li>
                the right to object - you have the right to object to the
                processing of your personal information, on grounds relating to
                your situation, unless we have compelling legitimate grounds for
                processing which over-ride your rights and freedoms or to enable
                us to establish, enforce or defend legal claims. Where personal
                data is processed for direct marketing purposes, you may also
                object to such processing, at any time, including profiling, to
                the extent that it is related to such direct marketing. You may
                do so by clicking on any ‘unsubscribe’ button following receipt
                of any such marketing communications or by contacting us in
                furtherance of the exercise of your right;
              </li>

              <li>
                the right of data portability – the right to receive personal
                data concerning you and provided to us, in a structured and
                machine-readable form, and to transmit that data to another
                controller without interruption, where processing by automated
                means is concerned.
              </li>
            </ul>
            <br />
            <br />
            You also have the right to lodge a complaint with a competent
            supervisory authority concerned with the processing of personal
            data, subject to applicable data protection legislation.
            Additionally, if we rely on consent for the processing of your
            personal information, you have the right to withdraw it at any time
            and free of charge, provided that, we may notify you of an
            alternative legal basis, if any, on which we may determine to
            continue processing such data. When you do so, this will not affect
            the lawfulness of the processing before your consent withdrawal.
            <br />
            <br />
            If you are someone who does not have a relationship with us, but
            believe that an Account Owner or User has entered your personal data
            into our Service or Website, you will need to contact the relevant
            Account Owner for any questions you may have with regard to the
            processing of your personal data and the exercise of any of the
            rights available to you under applicable data protection
            legislation, as per above.
            <br />
            <br />
            Therefore where we act as a processor of your personal data, our
            obligation shall consist in providing timely and reasonable
            assistance to the Account Owner, in its quality as controller, to
            enable it to respond to any requests from you to exercise any such
            right.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            SECURITY AND HOW DO WE PROTECT YOUR PERSONAL DATA
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            While no service is completely secure, we have a security team
            dedicated to maintaining the integrity of your personal data and
            keeping information safe. We maintain and implement administrative,
            technical and organisational safeguards, in accordance with the
            highest industry standards, that are intended to appropriately
            protect against accidental or unlawful destruction, accidental loss,
            unauthorized alteration, unauthorized disclosure or access, misuse
            and any other unlawful form of processing of, personal information
            in our possession, and make every effort to prevent any such
            unfortunate occurrences.
            <br />
            <br />
            We built the Service with your privacy in mind even during the
            initial application and system design stage. Our employees who have
            access to, handle or manage personal data, in the course of the
            performance of their functions, are required to comply with strict
            security protocols.
            <br />
            <br />
            We employ security measures such as using firewalls to protect
            against intruders, use technologies such as Secure Socket Layer
            (SSL) to ensure that data processed by/flowing from us over the
            internet is encrypted, thereby remaining private and integral,
            building redundancies throughout our network (so that if one server
            goes down, another can cover for it) and testing for and protecting
            against network vulnerabilities.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            RETENTION OF YOUR PERSONAL DATA
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            Without prejudice to any other data retention provisions provided
            elsewhere in this Privacy Policy, the precise periods for which we
            keep your personal information vary depending on the nature of the
            information and why we need it.
            <br />
            <br />
            We retain personal information regarding you or your use of our
            Service, and any connected organisations, for as long as an ongoing
            business relationship subsists with you, hence for as long as your
            account remains active and for as long as needed to provide you with
            the Service, and for as long as is relevant and necessary to achieve
            the purpose for which such information was originally processed.
            <br />
            <br />
            In so far as Account Owners and Users, or trial users, may have a
            seasonal site or possibly even come back to us after an account
            becomes inactive or a trial period ends, or perhaps even after the
            Service has been terminated, as the case may be, we do not
            immediately delete your personal information. Instead, we keep your
            personal information for a reasonable period of time so that it will
            be there for you if you decide to come back.
            <br />
            <br />
            Thereafter, factors which we consider in determining the appropriate
            retention periods for different categories of personal data,
            comprise, our legal and statutory obligations hence the minimum
            retention period prescribed by applicable employment, tax,
            accounting, regulatory or other relevant legislation; the period
            recommended as being best practice in the industry; the period
            during which a claim may be made or within which we can assert and
            enforce our rights and protect our interests with respect to/under
            an existing contractual arrangement or undertaking; and other
            relevant criteria.
            <br />
            <br />
            Please note that in the course of providing the Service or using the
            Website, we may collect and maintain aggregated, anonymized
            information which we may retain indefinitely.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>COOKIES</Text>
          <br />
          <br />
          <Text style={textStyle}>
            When you use our Service or visit our Website, we collect certain
            categories of personal data automatically through the use of cookies
            and similar tracking technologies to help us analyse how our Users
            use our Service and Website.
            <br />
            <br />
            When using our Service or Website, we collect and process certain
            personal data. This may include the internet address of the website
            or the domain name/IP address of the computer from which you
            accessed the site, the requested web page or download, whether the
            request was successful or not, the time and date when you accessed
            the site, information about your browser, network or device (such as
            browser type and version, operating system of the machine running
            your web browser, internet service provider, preference settings,
            unique device IDs and language and other regional settings). We use
            cookies and similar technologies for the purpose of:
            <br />
            <ul style={{ listStyleType: 'disc', margin: '15px' }}>
              <li>
                operating and enabling functionality of the Service or Website;
              </li>

              <li>
                optimizing, enhancing and customizing your user experience, for
                instance, we use your location information to determine your
                language preferences or display accurate date and time
                information;
              </li>

              <li>
                performing analytics with the aim of evaluating and improving
                our Service and Website. We do not base this on you
                individually, but we base this assessment on aggregate
                information which we collect, for example, if we find that most
                Users use a particular integration or feature, we might wish to
                expand and invest on that integration or feature;
              </li>

              <li>
                displaying targeted advertising that is/may be relevant to you.
              </li>
            </ul>
            <br />
            Our cookies policy requires us to request you to accept or reject
            the processing by us of certain data, as afore-described. If you
            reject such processing by us, it is possible for the Service or the
            Website, as the case may be, not to work as it is supposed to.
            <br />
            <br />
            We may also use cookies or other tracking technologies set by third
            parties across our Website or Service, such as Google Analytics and
            Agile CRM, so as to enable third party features or functionality to
            be provided through our Service or on our Website including
            providing customized advertising and content and performing
            analytics.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            INTERNET COMMUNICATIONS
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            You should be aware that data shared or transmitted over the
            internet may be transmitted across international borders. We cannot
            be held responsible for anything done or omitted to be done by
            yourself or any third party in connection with any personal data
            prior to our receiving it, including but not limited to, any
            transfers of personal data from you to us via a country having a
            lower level of data protection than that in place in the EU, and
            this, by any technological means whatsoever, including mobile and
            other cloud based applications.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            LINKS TO OTHER WEBSITES
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            Our Website may have a number of links to other local and
            international organisations and entities. In some cases, for the
            benefit of the visitor, it may be required that we link our Website
            to other websites of other organisations, after permission is
            obtained from them, respectively. It is important for you to note
            that upon linking to another website, you are no longer on our
            Website and you become subject to the privacy policy of the new
            website.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>UPDATES</Text>
          <br />
          <br />
          <Text style={textStyle}>
            We will update this Privacy Policy, from time to time, to reflect
            changes in technology, law, our business operations or any other
            reason we determine is necessary or appropriate, and shall, to this
            effect, replace this page with an updated version. If we make
            material changes to it or the ways we process personal information,
            we will notify you by, say, prominently posting a notice of the
            changes on our Website before they take effect or by directly
            sending you a notification of the same.
            <br />
            <br />
            We encourage you to periodically check and review this Privacy
            Policy for any changes since your last visit. This will help ensure
            you better understand your relationship with us, including the ways
            we process your personal information.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            HOW CAN YOU CONTACT US?
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            Please email us at{' '}
            <a href="mailto:helpme@generate.tax">helpme@generate.tax</a> if you
            have any questions, comments or feedback about this Privacy Policy
            or our privacy practices, or if you would like to exercise any of
            your rights.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Text style={textStyle}>
            <i>This Policy was last updated on 29th September 2022.</i>
          </Text>
          <br />
          <br />
        </Col>
      </Row>
    </React.Fragment>
  );
};
