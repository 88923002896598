import { Drawer } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { turnDrawerOff } from 'store/app/actions';
import { getDrawerData, getDrawerOpen } from 'store/app/selectors';
import styled from 'styled-components';
import { ReactComponent as IconClose } from 'assets/images/icons/iconClose.svg';

const DrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;

  .drawer-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 21px;
  }
  .drawer-title {
    display: flex;
    flex-direction: column;
    max-width: 300px;
  }
  .drawer-title-main {
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #042a2a;
  }
  .drawer-title-sub {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #879494;
  }
  .drawer-close-button {
    cursor: pointer;
    border: 1px solid transparent;
    width: 25px;
    height: 25px;
    left: 24px;
    gap: 0px;
    border-radius: 5px;
    //border: 1px;
    display: flex;
    justify-content: center;
    &:hover {
      border: 1px solid #41aaaf;
    }
  }
  .drawer-content {
    width: 100%;
  }
`;

export const DrawerContainer: React.FC = () => {
  const dispatch = useDispatch();
  const drawerData = useSelector(getDrawerData);
  const drawerOpen = useSelector(getDrawerOpen);

  const close = () => dispatch(turnDrawerOff());

  return (
    <Drawer
      //title={drawerData?.title}
      placement="right"
      onClose={close}
      open={drawerOpen}
      closable={false}
      width={400}
      style={{ background: '#E9F6F7' }}

      // extra={
      //   <Space>
      //     <Button onClick={onClose}>Cancel</Button>
      //     <Button type="primary" onClick={onClose}>
      //       OK
      //     </Button>
      //   </Space>
      // }
    >
      <DrawerContentWrapper>
        <div className="drawer-header">
          <div className="drawer-title">
            <span className="drawer-title-main">{drawerData?.title}</span>
            <span className="drawer-title-sub">{drawerData?.subtitle}</span>
          </div>
          <div className="drawer-close-button" onClick={close}>
            <IconClose />
          </div>
        </div>
        <div className="drawer-content">{drawerData?.component}</div>
      </DrawerContentWrapper>
    </Drawer>
  );
};
