import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { CommonState } from './types';
import {
  BusinessSize,
  BusinessType,
  Country,
  CountryFlag,
  Timezone,
} from 'store/common/models';

const commonSelector = (state: StoreState): CommonState => state.common;

export const getCommonLoading = createSelector(
  commonSelector,
  (common: CommonState): CommonState['loading'] => common.loading
);

export const getCommonCountries = createSelector(
  commonSelector,
  (common: CommonState): Country[] => common.countries
);

export const getCommonBusinessTypes = createSelector(
  commonSelector,
  (common: CommonState): BusinessType[] => common.businessTypes
);

export const getCommonBusinessSizes = createSelector(
  commonSelector,
  (common: CommonState): BusinessSize[] => common.businessSizes
);

export const getCommonTimezones = createSelector(
  commonSelector,
  (common: CommonState): Timezone[] => common.timezones
);

export const getCommonCurrencies = createSelector(
  commonSelector,
  (common: CommonState): string[] => common.currencies
);

export const getCountriesExternalData = createSelector(
  commonSelector,
  (common: CommonState): CountryFlag[] => common.countriesExternalData
);