import { takeLatest } from 'redux-saga/effects';
import {
  DELETE_MAPPING_REQUEST,
  FETCH_ADD_RETURN_DATA_REQUEST,
  SAVE_MAPPING_REQUEST,
} from '../constants';
import { fetchAddReturnDataSaga } from './fetchAddReturnDataSaga';
import { saveMappingSaga } from './saveMappingSaga';
import { deleteMappingSaga } from './deleteMappingSaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* returnSaga() {
  yield takeLatest(FETCH_ADD_RETURN_DATA_REQUEST, fetchAddReturnDataSaga);
  yield takeLatest(SAVE_MAPPING_REQUEST, saveMappingSaga);
  yield takeLatest(DELETE_MAPPING_REQUEST, deleteMappingSaga);
}
