//src/components/ImageCropModalContent.jsx

import { useImageCropContext } from './pic-image-crop-provider';
import Cropper from './pic-cropper';
import { RotationSlider, ZoomSlider } from './pic-sliders';
import React from 'react';
import { Button, Space, Upload } from 'antd';
import type { GetProp, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const ImageCropModalContent = ({
  handleDone,
  handleClose,
  profilePicture,
}) => {
  const { setImage } = useImageCropContext();
  const { t } = useTranslation();

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleFileChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, (url) => {
        setImage(url);
      });
    }
  };

  return (
    <div className="text-center relative">
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <div className="crop-container mb-4">
          <Cropper profilePicture={profilePicture} />
        </div>
        <Upload
          accept="image/*"
          multiple={false}
          showUploadList={false}
          onChange={handleFileChange}
        >
          <Button icon={<UploadOutlined />}>
            {t('common.upload-another-picture')}
          </Button>
        </Upload>
        <Space style={{ width: '100%', columnGap: 12 }}>
          <ZoomSlider className="mb-4" />
          <RotationSlider className="mb-4" />
        </Space>

        <Space>
          <Button className="" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button type="primary" className="w-full" onClick={handleDone}>
            {`${t('common.done')} & ${t('common.save')}`}
          </Button>
        </Space>
      </Space>
    </div>
  );
};
export default ImageCropModalContent;
