import { call, put } from 'redux-saga/effects';
import { disableMfaFailure, disableMfaSuccess } from '../actions';
import { disableMfa } from '../api';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn, turnModalOff } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { fetchMeRequest } from 'store/auth/actions';
import { ERROR_HANG_TIME } from 'store/app/constants';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* disableMfaSaga() {
  try {
    let err: ApiError | null;

    const response: ApiResponse = yield call(disableMfa);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Disabled !',
      description: 'MFA disabled successfully.',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(disableMfaSuccess());
    yield put(fetchMeRequest());
    yield put(turnModalOff());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(disableMfaFailure());
  }
}
