import { createAction } from 'store/utils';
import {
  ACTIVATE_ACCOUNT_FAILURE,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_SUCCESS,
  CONFIRM_USER_FAILURE,
  CONFIRM_USER_REQUEST,
  CONFIRM_USER_SUCCESS,
  PROVIDER_SIGNUP_FAILURE,
  PROVIDER_SIGNUP_REQUEST,
  PROVIDER_SIGNUP_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESEND_ACTIVATION_FAILURE,
  RESEND_ACTIVATION_REQUEST,
  RESEND_ACTIVATION_SUCCESS,
} from './constants';
import { UserDetails } from './models/userDetails';
import {
  ActivateAccountFailureAction,
  ActivateAccountRequestAction,
  ActivateAccountSuccessAction,
  ConfirmUserFailureAction,
  ConfirmUserRequestAction,
  ConfirmUserSuccessAction,
  ProviderSignupFailureAction,
  ProviderSignupRequestAction,
  ProviderSignupSuccessAction,
  RegisterFailureAction,
  RegisterRequestAction,
  RegisterSuccessAction,
  ResendActivationFailureAction,
  ResendActivationRequestAction,
  ResendActivationSuccessAction,
} from './types';
import { ConfirmedUser } from './models';
import { Provider } from 'store/api/enums';

// Register
export const registerRequest = (
  user: UserDetails,
  gCaptchaResponse: string,
  ssoSignUp: boolean
): RegisterRequestAction =>
  createAction(REGISTER_REQUEST, { user, gCaptchaResponse, ssoSignUp });

export const registerSuccess = (): RegisterSuccessAction =>
  createAction(REGISTER_SUCCESS);

export const registerFailure = (): RegisterFailureAction =>
  createAction(REGISTER_FAILURE);

// Confirm user
export const confirmUserRequest = (
  verificationKey: string,
  passwordReset: boolean = false
): ConfirmUserRequestAction =>
  createAction(CONFIRM_USER_REQUEST, { verificationKey, passwordReset });

export const confirmUserSuccess = (
  params: ConfirmedUser
): ConfirmUserSuccessAction => createAction(CONFIRM_USER_SUCCESS, params);

export const confirmUserFailure = (): ConfirmUserFailureAction =>
  createAction(CONFIRM_USER_FAILURE);

// Activate Account
export const activateAccounRequest = (
  user: UserDetails,
  resetPassword?: boolean
): ActivateAccountRequestAction =>
  createAction(ACTIVATE_ACCOUNT_REQUEST, { user, resetPassword });

export const activateAccountSuccess = (): ActivateAccountSuccessAction =>
  createAction(ACTIVATE_ACCOUNT_SUCCESS);

export const activateAccountFailure = (): ActivateAccountFailureAction =>
  createAction(ACTIVATE_ACCOUNT_FAILURE);

// Resend Activation Link
export const resendActivationRequest = (
  email: string,
  invited?: boolean
): ResendActivationRequestAction =>
  createAction(RESEND_ACTIVATION_REQUEST, { email, invited });

export const resendActivationSuccess = (): ResendActivationSuccessAction =>
  createAction(RESEND_ACTIVATION_SUCCESS);

export const resendActivationFailure = (): ResendActivationFailureAction =>
  createAction(RESEND_ACTIVATION_FAILURE);

// Provider signup
export const providerSignupRequest = (
  provider: Provider
): ProviderSignupRequestAction =>
  createAction(PROVIDER_SIGNUP_REQUEST, { provider });

export const providerSignupSuccess = (): ProviderSignupSuccessAction =>
  createAction(PROVIDER_SIGNUP_SUCCESS);

export const providerSignupFailure = (): ProviderSignupFailureAction =>
  createAction(PROVIDER_SIGNUP_FAILURE);
