import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { SignUpState } from './types';

const selectSignUp = (app: StoreState): SignUpState => app.signUp;

export const getSignUpLoading = createSelector(
  selectSignUp,
  (signUp: SignUpState): SignUpState['loading'] => signUp.loading
);

export const getSignUpUserId = createSelector(
  selectSignUp,
  (signUp: SignUpState): SignUpState['userId'] => signUp.userId
);

export const getSignUpUserActivated = createSelector(
  selectSignUp,
  (signUp: SignUpState): SignUpState['userActivated'] => signUp.userActivated
);
