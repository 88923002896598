import { api } from 'store/api';
import { ConfirmedUser, UserDetails } from './models';
import { ApiResponse } from 'store/api/types';
import { Provider } from 'store/api/enums';

export type RegisterRequest = {
  user: UserDetails;
  redirectURL: string;
  gCaptchaResponse: string;
};

export const register = (
  user: UserDetails,
  gCaptchaResponse: string
): Promise<ApiResponse> => {
  const redirectURL = `${process.env.PUBLIC_URL}/signup/validate`;
  const registerRequest: RegisterRequest = {
    user,
    redirectURL,
    gCaptchaResponse,
  };
  return api.post<ApiResponse>('/common/registerUser', {
    body: registerRequest,
  });
};

export interface ConfirmResponse extends ApiResponse {
  data: ConfirmedUser;
}
export const confirmUser = (
  verificationKey: string
): Promise<ConfirmResponse> =>
  api.get<ConfirmResponse>(`/common/verifyUser/${verificationKey}`);

export type ActivateAccountRequest = UserDetails;
export const activate = (
  user: ActivateAccountRequest,
  resetPassword?: Boolean
): Promise<ApiResponse> =>
  api.post<ApiResponse>(`/common/activate?resetPassword=${resetPassword}`, {
    body: { user },
  });

export type ResendActivationRequest = {
  email: string;
  redirectURL: string;
};

export const resend = (email: string): Promise<ApiResponse> => {
  const redirectURL = `${process.env.PUBLIC_URL}/signup/validate`;
  const resendRequest: ResendActivationRequest = {
    email,
    redirectURL,
  };
  return api.put<ApiResponse>('/common/resendActivation', {
    body: resendRequest,
  });
};

export interface ProviderSignupResponse extends ApiResponse {
  data: { url: string };
}
export const providerSignup = (
  provider: Provider
): Promise<ProviderSignupResponse> => {
  const hostURL = process.env.PUBLIC_URL;
  let url: string;
  switch (provider) {
    case Provider.XERO:
      url = `/oauth2/signInWithXero/registration?hostURL=${hostURL}`;
      break;
    case Provider.GOOGLE:
      url = `/oauth2/signInWithGoogle/registration?hostURL=${hostURL}`;
      break;
    case Provider.QUICKBOOKS:
      url = '/signInWithQuickbooks/registration';
      break;
    default:
      throw new Error('Problem with provider link');
  }
  return api.get<ProviderSignupResponse>(url);
};
