import { Divider, Flex, Input, Popconfirm, Row, Space, Tooltip } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from 'store/auth/selectors';
import { StyledCard } from 'components/Custom/card';
import { getProviderLink } from 'utils/get-provider-link';
import { CardTitleSpan } from 'components/Custom/spans';
import { CardInfo } from 'components/OrganisationDetails/card-info';
import {
  EditOrganisationParams,
  Organisation,
} from 'store/organisation/models';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Country } from 'store/common/models';
import { getProviderName } from 'utils/get-provider-name';
import {
  CloseOutlined,
  CopyOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { editOrganisationsRequest } from 'store/organisation/actions';
import { turnDrawerOff, turnMessageOn } from 'store/app/actions';
import { Provider } from 'store/api/enums';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';

interface ConnectEntityProps {
  organisation?: Organisation;
  countries?: Country[];
  activeEntity: boolean;
  activateVatChecker: (connectionId: string, entityName: string) => void;
  deactivateVatChecker: (connectionId: string, entityName: string) => void;
  disconnectOrganisation: (organisationId: string) => void;
}

export const ViesEntityDetails: React.FC<ConnectEntityProps> = ({
  organisation,
  countries,
  activeEntity,
  activateVatChecker,
  deactivateVatChecker,
  disconnectOrganisation,
}) => {
  const { t } = useTranslation();
  //const loggedUser = useSelector(getAuthUser);
  const dispatch = useDispatch();
  const [nameEditMode, setNameEditMode] = useState(false);
  const [nameText, setNameText] = useState(organisation?.friendlyName);

  const onChangeText = (e: any) => setNameText(e.target.value);

  const editModeOn = () => setNameEditMode(true);
  const editModeOff = () => setNameEditMode(false);

  const saveFriendlyName = () => {
    if (nameText) {
      const newName = nameText;
      const newDetails: EditOrganisationParams = {
        newName,
        clientCode: undefined,
        officeLocation: undefined,
        refId: organisation?.uuid ? organisation?.uuid : '',
      };

      dispatch(editOrganisationsRequest(newDetails));
      editModeOff();
      organisation = {
        ...organisation,
        friendlyName: nameText,
      } as Organisation;
    } else {
      alert('Cannot save an empty name');
    }
  };

  // const onActivateClick = () => {
  //   if(organisation.)
  // }
  const getCountry = (iso) => {
    const countryName = countries?.find((c) => c.iso === iso)?.name;
    return countryName ? countryName : iso;
  };

  const getWebhookUrl = (uuid: string) => {
    const server = process.env.API_URL.replace('api.', 'papi.')
      .replace('/api', '')
      .replace('papi.', 'api.');
    const url = `${server}/cin7/webhook/${uuid}`;
    return url;
  };

  const copyCurrentValue = (url: string) => {
    navigator.clipboard.writeText(url);

    const message: MessageData = {
      title: 'Success',
      description: 'Copied to clipboard!',
      type: MessageStates.INFO,
    };
    dispatch(turnMessageOn(message));
  };

  const onDisconnectOrganisation = (organisationId: string | undefined) => {
    organisationId && disconnectOrganisation(organisationId);
  };

  const renderEntityDetailsCard = () => {
    return (
      <StyledCard //@ts-ignore
        width={'fit-content'}
        style={{ marginTop: '20px' }}
      >
        <div style={{ overflow: 'visible' }}>
          <Space style={{ columnGap: 20 }}>
            <a
              href={getProviderLink(
                organisation?.providerName.toLowerCase(),
                organisation?.shortCode
              )}
              target="_blank"
              rel="noreferrer"
              style={{ height: '32px', display: 'block' }}
            >
              <img
                src={
                  organisation?.providerName
                    ? require(`shared/assets/images/${organisation.providerName.toLowerCase()}_small.png`)
                        .default
                    : ''
                }
                width={30}
              />
            </a>
            <CardTitleSpan>
              {`${
                organisation && getProviderName(organisation?.providerName)
              } details`}
            </CardTitleSpan>
          </Space>
          <Divider style={{ margin: '16px 0' }} />

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '4px',
              flexDirection: 'column',
              rowGap: 5,
              overflow: 'auto',
              maxWidth: 1000,
              width: '100%' /*minWidth: 916.6*/,
            }}
          >
            {organisation?.legalName && (
              <CardInfo
                label={t('signup.company-name')}
                data={organisation?.legalName}
              />
            )}

            {organisation?.friendlyName && (
              <Flex justify="space-between" align="end">
                <CardInfo
                  label={t('organisation.friendly-name')}
                  data={
                    nameEditMode ? (
                      <Input value={nameText} onChange={onChangeText} />
                    ) : (
                      //organisation?.friendlyName
                      nameText
                    )
                  }
                />

                <div
                  className="edit-details-link"
                  style={{ paddingTop: '10px' }}
                  // onClick={() =>
                  //   nameEditMode ? saveFriendlyName() : editModeOn()
                  // }
                >
                  {nameEditMode ? (
                    <Space>
                      <SaveOutlined onClick={saveFriendlyName} />
                      <CloseOutlined onClick={editModeOff} />
                    </Space>
                  ) : (
                    <EditOutlined onClick={editModeOn} />
                  )}
                </div>
              </Flex>
            )}

            {organisation?.creator && (
              <CardInfo
                label={t('organisation.created-by')}
                data={organisation?.creator}
              />
            )}

            {organisation?.createTimestamp && (
              <CardInfo
                label={t('organisation.created-on')}
                data={moment(organisation?.createTimestamp).format(
                  'DD/MM/YYYY'
                )}
              />
            )}

            {organisation?.currency && (
              <CardInfo
                label={t('organisation.currency')}
                data={organisation?.currency}
              />
            )}

            {organisation?.version && (
              <CardInfo
                label={t('organisation.version')}
                data={organisation?.version}
              />
            )}

            {organisation?.taxNumber && (
              <CardInfo
                label={t('organisation.tax-number')}
                data={organisation?.taxNumber}
              />
            )}

            {organisation?.country && (
              <CardInfo
                label={t('organisation.country')}
                data={getCountry(organisation?.country)}
              />
            )}

            {organisation?.providerName === Provider.CIN7 && (
              <Flex justify="space-between" align="end">
                <CardInfo label="Webhook Url" data="" />
                <div
                  className="edit-details-link"
                  style={{ paddingTop: '10px' }}
                  // onClick={() =>
                  //   nameEditMode ? saveFriendlyName() : editModeOn()
                  // }
                >
                  {' '}
                  <Tooltip
                    title={
                      <>
                        <strong>Click to Copy</strong>
                        <br />
                        {getWebhookUrl(organisation?.uuid)}
                      </>
                    }
                    placement="left"
                  >
                    <CopyOutlined
                      onClick={() => {
                        organisation &&
                          copyCurrentValue(getWebhookUrl(organisation?.uuid));
                        dispatch(turnDrawerOff());
                      }}
                    />
                  </Tooltip>
                </div>
              </Flex>
            )}

            <Popconfirm
              title={
                activeEntity ? 'Deactivating Entity!' : 'Activating Entity!'
              }
              description={`Are you sure you want to ${
                activeEntity ? 'deactivate' : 'activate'
              } the selected entity?`}
              onConfirm={() => {
                if (organisation) {
                  activeEntity
                    ? deactivateVatChecker(
                        organisation?.connectionId,
                        organisation?.friendlyName
                      )
                    : activateVatChecker(
                        organisation?.connectionId,
                        organisation?.friendlyName
                      );
                }
                dispatch(turnDrawerOff());
              }}
              placement="bottom"
              okText={t('common.yes')}
              cancelText={t('common.cancel')}
            >
              <ScopeButton
                type="default" //@ts-ignore
                width="206px"
                height="32px"
                style={{ margin: '0 30px', marginTop: '12px' }}

                // disabled={
                //   currentUser &&
                //   AccountType[currentUser.accountType] === AccountType.CLIENT
                // }
              >
                {activeEntity ? 'Deactivate' : 'Activate'}
              </ScopeButton>
            </Popconfirm>
            <Popconfirm
              title={
                organisation?.connectedProperly
                  ? t('organisation.disconnected-entity-title')
                  : t('organisation.removed-entity-title')
              }
              description={
                <span>
                  {organisation?.connectedProperly
                    ? t('organisation.disconnected-entity-description')
                    : t('organisation.removed-entity-description')}
                </span>
              }
              onConfirm={() => {
                onDisconnectOrganisation(organisation?.uuid);
                dispatch(turnDrawerOff());
              }}
              placement="left"
              okText={t('common.yes')}
              cancelText={t('common.cancel')}
            >
              <ScopeButton
                type="primary" //@ts-ignore
                width="206px"
                height="32px"
                style={{ margin: '0 30px', marginTop: '12px' }}
                // disabled={
                //   currentUser &&
                //   AccountType[currentUser.accountType] === AccountType.CLIENT
                // }
              >
                {organisation?.connectedProperly
                  ? t('common.disconnect')
                  : t('common.remove')}
              </ScopeButton>
            </Popconfirm>
          </div>
        </div>
      </StyledCard>
    );
  };
  return <div>{renderEntityDetailsCard()}</div>;
};
