import {
  DELETE_MAPPING_FAILURE,
  DELETE_MAPPING_SUCCESS,
  FETCH_ADD_RETURN_DATA_FAILURE,
  FETCH_ADD_RETURN_DATA_POST_SUCCESS,
  FETCH_ADD_RETURN_DATA_PRE_SUCCESS,
  FETCH_ADD_RETURN_DATA_RATES_SUCCESS,
  FETCH_ADD_RETURN_DATA_REQUEST,
  RESET_RETURN_DATA,
  SAVE_MAPPING_FAILURE,
  SAVE_MAPPING_REQUEST,
  SAVE_MAPPING_SUCCESS,
} from './constants';
import {
  FetchAddReturnDataPostSuccessAction,
  FetchAddReturnDataPreSuccessAction,
  FetchAddReturnDataRatesSuccessAction,
  ReturnAction,
  ReturnState,
} from './types';

const initialState: ReturnState = {
  loading: false,
  error: undefined,
  errorCode: undefined,
  returnData: undefined,
  preLoading: false,
  taxRatesLoading: false,
};

export const returnReducer = (
  state = initialState,
  action: ReturnAction
): ReturnState => {
  switch (action.type) {
    case FETCH_ADD_RETURN_DATA_REQUEST:
      return handleFetchAddReturnDataRequest(state);
    case FETCH_ADD_RETURN_DATA_PRE_SUCCESS:
      return handleFetchAddReturnDataPreSuccess(state, action);
    case FETCH_ADD_RETURN_DATA_RATES_SUCCESS:
      return handleFetchAddReturnDataRatesSuccess(state, action);
    case FETCH_ADD_RETURN_DATA_POST_SUCCESS:
      return handleFetchAddReturnDataPostSuccess(state, action);
    case FETCH_ADD_RETURN_DATA_FAILURE:
      return handleFetchAddReturnDataFailure(state);
    case SAVE_MAPPING_REQUEST:
    case SAVE_MAPPING_FAILURE:
      return handleRequest(state);
    case SAVE_MAPPING_SUCCESS:
    case DELETE_MAPPING_SUCCESS:
      return handleSuccess(state);
    case DELETE_MAPPING_FAILURE:
    case SAVE_MAPPING_FAILURE:
      return handleFailure(state);
    case RESET_RETURN_DATA:
      return handleResetReturnData();
    default:
      return state;
  }
};

// Fetch returns
function handleFetchAddReturnDataRequest(state: ReturnState): ReturnState {
  return {
    ...state,
    loading: true,
    preLoading: true,
  };
}

function handleFetchAddReturnDataPreSuccess(
  state: ReturnState,
  action: FetchAddReturnDataPreSuccessAction
): ReturnState {
  const { returnTypes, returnTypesEdit, returnFrequency, connectionId } =
    action.payload;

  const returnData = {
    ...state.returnData,
    returnTypes,
    returnTypesEdit,
    returnFrequency,
    connectionId,
  };
  return {
    ...state,
    preLoading: false,
    taxRatesLoading: true,
    returnData,
  };
}

function handleFetchAddReturnDataRatesSuccess(
  state: ReturnState,
  action: FetchAddReturnDataRatesSuccessAction
): ReturnState {
  const { taxRates } = action.payload;
  const returnData = { ...state.returnData, taxRates };
  return {
    ...state,
    taxRatesLoading: false,
    returnData,
  };
}

function handleFetchAddReturnDataPostSuccess(
  state: ReturnState,
  action: FetchAddReturnDataPostSuccessAction
): ReturnState {
  const { sections } = action.payload;
  const secs = [...sections];
  secs.map((s) => {
    s.subsections.sort((a, b) => a.sortOrder - b.sortOrder);
  });
  const returnData = { ...state.returnData, sections };
  return {
    ...state,
    loading: false,
    returnData,
  };
}

function handleFetchAddReturnDataFailure(state: ReturnState): ReturnState {
  return {
    ...state,
    loading: false,
    taxRatesLoading: false,
    preLoading: false,
    returnData: undefined,
  };
}

// Common
function handleRequest(state: ReturnState): ReturnState {
  return {
    ...state,
    loading: true,
  };
}

function handleSuccess(state: ReturnState): ReturnState {
  return {
    ...state,
    loading: false,
  };
}

function handleFailure(state: ReturnState): ReturnState {
  return {
    ...state,
    loading: false,
  };
}

// Reset
function handleResetReturnData(): ReturnState {
  return initialState;
}
