import { Space, Spin, Tabs, Divider, Flex, Popconfirm } from 'antd';
import * as React from 'react';
import { Entity, Mapping, Organisation } from 'store/organisation/models';
import type { Country } from 'store/common/models';
import { MappingDetails } from './mapping';
import { useTranslation } from 'react-i18next';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { EntityDetailsWrapper } from 'components/Custom/wrappers';
import { StyledCard } from 'components/Custom/card';
import { CardTitleLargeSpan, CardTitleSpan } from 'components/Custom/spans';
import { ScopeButton } from 'components/Custom/buttons';
import { SSOImg } from 'components/Custom/images';
import ImgWithXeroSignIn from 'assets/images/xero_icon.png';
import { AvatarGroupRenderer } from 'utils/avatar-group-renderer';
import { VatReturnLog } from 'store/vat/models';
import { AvatarSizes } from 'utils/render-avatar';
import { CardInfo } from './card-info';
import { getProviderLink } from 'utils/get-provider-link';
import { User } from 'store/auth/models';
import { AccountType } from 'store/api/enums';

interface OrganisationDetailsProps {
  loading: boolean;
  organisation?: Organisation;
  disconnectOrganisation: (organisationId: string) => void;
  countries: Country[];
  selectedMaps: Mapping[];
  vatReturnLogs: VatReturnLog[];
  openEditFriendlyName: () => void;
  addReturn: () => void;
  editReturnMapping: (connectionId: string, mappingId: string) => void;
  vatGenerate: (
    connectionId: string,
    mappingId: string,
    dataLogId?: string
  ) => void;
  connectOrganisation: () => void;
  currentUser?: User;
}
export const OrganisationDetails: React.FC<OrganisationDetailsProps> = ({
  loading,
  organisation,
  disconnectOrganisation,
  countries,
  selectedMaps,
  vatReturnLogs,
  openEditFriendlyName,
  addReturn,
  editReturnMapping,
  vatGenerate,
  connectOrganisation,
  currentUser,
}) => {
  const { t } = useTranslation();
  // const [modalOpen, setModalOpen] = React.useState(false);
  // const [nameValue, setNameValue] = React.useState<any>(
  //   organisation?.friendlyName
  // );

  const getCountry = (iso) => {
    const countryName = countries.find((c) => c.iso === iso)?.name;
    return countryName ? countryName : iso;
  };

  // const handleOk = () => {
  //   editOrganisationName(nameValue);
  //   setModalOpen(false);
  // };

  // const handleCancel = () => {
  //   setModalOpen(false);
  // };

  // const handleOpenModal = () => {
  //   setNameValue(organisation?.friendlyName);
  //   setModalOpen(true);
  // };

  const handleAddReturn = () => {
    addReturn();
  };

  const renderReturns = () => {
    return (
      <div style={{ padding: '0 40px' }}>
        {selectedMaps.map((m, idx) => (
          <div
            id={m.mappingId}
            key={idx}
            style={{
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <MappingDetails
              mapping={m}
              countryName={
                organisation?.returnTypesEdit.find(
                  (returnType) => returnType.id == m.returnTypeId
                )?.countryName
              }
              vatReturnLogs={vatReturnLogs}
              connectionId={organisation?.connectionId}
              vatGenerate={vatGenerate}
              editReturnMapping={editReturnMapping}
              connected={!!organisation?.connectedProperly}
              currentUser={currentUser}
            />
          </div>
        ))}

        <>
          {selectedMaps?.length > 0 ? (
            <div
              style={{
                paddingTop: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <StyledCard
                //width={'fit-content'}
                style={{
                  border: '1px dashed #41AAAF',
                  boxShadow: '0px 4px 10px 0px #0000000D',
                  margin: '20px 0',
                  display: 'flex',
                  justifyContent: 'center',
                  //cursor: 'pointer',
                  maxWidth: 1000,
                  width: '100%' /*minWidth: 916.6*/,
                  overflow: 'auto',
                  fontSize: 14,
                  fontWeight: 400,
                }}
                //onClick={handleAddReturn}
              >
                {/*  <RoundAddButton onClick={handleAddReturn}>+</RoundAddButton> */}
                {/*t('organisation.add-return')*/}
                <ScopeButton
                  type={'primary'}
                  onClick={handleAddReturn} // @ts-ignore
                  width={'105px'}
                  height={'30px'}
                  fontSize={'12px'}
                  lineheight={'12px'}
                  style={{ padding: '4px 5px', margin: '7px 13px' }}
                  icon={<PlusOutlined width={'16px'} height={'16px'} />}
                  disabled={
                    !organisation?.connectedProperly ||
                    (currentUser &&
                      AccountType[currentUser.accountType] ===
                        AccountType.CLIENT)
                  }
                >
                  {t('organisation.add-return')}
                </ScopeButton>
              </StyledCard>
            </div>
          ) : (
            <div
              style={{
                paddingTop: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <StyledCard
                //width={'fit-content'}
                style={{
                  border: '1px dashed #41AAAF',
                  boxShadow: '0px 4px 10px 0px #0000000D',
                  margin: '20px 0',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '94px',
                  maxWidth: 1000,
                  width: '100%' /*minWidth: 916.6*/,
                  overflow: 'auto',
                }}
              >
                <Space direction="vertical" align="center">
                  <CardTitleLargeSpan style={{ fontSize: 24 }}>
                    {t('organisation.empty-tax-return')}
                  </CardTitleLargeSpan>
                  <CardTitleSpan style={{ fontSize: 16, fontWeight: 300 }}>
                    {t('organisation.add-return-get-started')}
                  </CardTitleSpan>
                  <ScopeButton
                    type="primary" //@ts-ignore
                    height="40px"
                    width="197px"
                    style={{ marginTop: '30px' }}
                    onClick={handleAddReturn}
                    disabled={
                      !organisation?.connectedProperly ||
                      (currentUser &&
                        AccountType[currentUser.accountType] ===
                          AccountType.CLIENT)
                    }
                  >
                    {t('organisation.add-tax-return')}
                  </ScopeButton>
                </Space>
              </StyledCard>
            </div>
          )}
        </>
      </div>
    );
  };

  const renderEntityDetailsCard = () => {
    return (
      <StyledCard //@ts-ignore
        width={'fit-content'}
        style={{ marginTop: '20px' }}
      >
        <div style={{ overflow: 'visible' }}>
          <Space style={{ columnGap: 20 }}>
            <a
              href={getProviderLink(
                organisation?.providerName.toLowerCase(),
                organisation?.shortCode
              )}
              target="_blank"
              rel="noreferrer"
              style={{ height: '32px', display: 'block' }}
            >
              <SSOImg src={ImgWithXeroSignIn} />
            </a>
            <CardTitleSpan>{t('organisation.xero-details')}</CardTitleSpan>
          </Space>
          <Divider style={{ margin: '16px 0' }} />

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '4px',
              flexDirection: 'column',
              rowGap: 5,
              overflow: 'auto',
              maxWidth: 1000,
              width: '100%' /*minWidth: 916.6*/,
            }}
          >
            {organisation?.legalName && (
              <CardInfo
                label={t('signup.company-name')}
                data={organisation?.legalName}
              />
            )}

            {organisation?.friendlyName && (
              <Flex justify="space-between">
                <CardInfo
                  label={t('organisation.friendly-name')}
                  data={organisation?.friendlyName}
                />
                {currentUser &&
                  AccountType[currentUser.accountType] !==
                    AccountType.CLIENT && (
                    <div
                      className="edit-details-link"
                      style={{ paddingTop: '10px' }}
                      onClick={openEditFriendlyName}
                    >
                      <EditOutlined />
                    </div>
                  )}
              </Flex>
            )}

            {organisation?.creator && (
              <CardInfo
                label={t('organisation.created-by')}
                data={organisation?.creator}
              />
            )}

            {organisation?.createTimestamp && (
              <CardInfo
                label={t('organisation.created-on')}
                data={moment(organisation?.createTimestamp).format(
                  'DD/MM/YYYY'
                )}
              />
            )}

            {organisation?.currency && (
              <CardInfo
                label={t('organisation.currency')}
                data={organisation?.currency}
              />
            )}

            {organisation?.version && (
              <CardInfo
                label={t('organisation.version')}
                data={organisation?.version}
              />
            )}

            {organisation?.taxNumber && (
              <CardInfo
                label={t('organisation.tax-number')}
                data={organisation?.taxNumber}
              />
            )}

            {organisation?.country && (
              <CardInfo
                label={t('organisation.country')}
                data={getCountry(organisation?.country)}
              />
            )}
            {!organisation?.connectedProperly && (
              <ScopeButton
                type="primary" //@ts-ignore
                width="206px"
                height="32px"
                style={{ margin: '0 30px', marginTop: '12px' }}
                onClick={() => connectOrganisation()}
                disabled={
                  currentUser &&
                  AccountType[currentUser.accountType] === AccountType.CLIENT
                }
              >
                Connect
              </ScopeButton>
            )}
            <Popconfirm
              title={
                organisation?.connectedProperly
                  ? t('organisation.disconnected-entity-title')
                  : t('organisation.removed-entity-title')
              }
              description={
                <span>
                  {organisation?.connectedProperly
                    ? t('organisation.disconnected-entity-description')
                    : t('organisation.removed-entity-description')}
                </span>
              }
              onConfirm={() => onDisconnectOrganisation(organisation?.uuid)}
              placement="left"
              okText={t('common.yes')}
              cancelText={t('common.cancel')}
            >
              <ScopeButton
                type="default" //@ts-ignore
                width="206px"
                height="32px"
                style={{ margin: '0 30px', marginTop: '12px' }}
                disabled={
                  currentUser &&
                  AccountType[currentUser.accountType] === AccountType.CLIENT
                }
              >
                {organisation?.connectedProperly
                  ? t('common.disconnect')
                  : t('common.remove')}
              </ScopeButton>
            </Popconfirm>
          </div>
        </div>
      </StyledCard>
    );
  };

  const renderOverviewTab = () => {
    return (
      <div
        style={{
          backgroundColor: '#f3f7f8',
          display: 'flex',
          flexDirection: 'row',
          //justifyContent: 'center',
          margin: 'auto',
        }}
      >
        <div style={{ float: 'left', width: '60%', margin: '0 10%' }}>
          {renderReturns()}
        </div>
        <div
          style={{
            height: '100vh',
            position: 'fixed',
            float: 'right',
            right: 17,
            width: '25%',
          }}
        >
          {renderEntityDetailsCard()}
        </div>
      </div>
    );
  };

  const TabItems = [
    {
      label: t('organisation.overview'),
      key: 'overview',
      children: renderOverviewTab(),
    },
    {
      label: t('organisation.reporting'),
      key: 'reporting',
      children: (
        <div
          style={{
            display: 'flex',
            padding: 50,
            justifyContent: 'center',
            color: '#2c828a',
          }}
        >
          Coming soon...
        </div>
      ),
    },
  ];

  const onDisconnectOrganisation = (organisationId: string | undefined) => {
    organisationId && disconnectOrganisation(organisationId);
  };

  const entityToSend = {
    organisation: organisation?.friendlyName,
    users: organisation?.users,
    organisationId: organisation?.uuid,
  } as Entity;

  return (
    <EntityDetailsWrapper>
      <Spin spinning={loading} size="large">
        <Tabs defaultActiveKey="1" items={TabItems} />
        {currentUser &&
          AccountType[currentUser.accountType] !== AccountType.CLIENT && (
            <div className="top-wrapper">
              <ScopeButton
                type={'primary'}
                onClick={handleAddReturn} // @ts-ignore
                width={'105px'}
                height={'30px'}
                fontSize={'12px'}
                lineheight={'12px'}
                style={{ padding: '4px 5px', margin: '7px 13px' }}
                icon={<PlusOutlined width={'16px'} height={'16px'} />}
                disabled={!organisation?.connectedProperly}
              >
                <span>{t('organisation.add-return')}</span>
              </ScopeButton>
              <AvatarGroupRenderer
                entity={entityToSend}
                maxCount={4}
                size={AvatarSizes.DEFAULT}
              />
            </div>
          )}
      </Spin>
    </EntityDetailsWrapper>
  );
};
