import { call, put } from 'redux-saga/effects';
import { generateReportFailure, generateReportSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { LoadReportRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { LoadReportResponse, loadReport } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { ERROR_HANG_TIME } from 'store/app/constants';
import { VatReportData } from '../models';
import { MessageType } from 'store/api/enums';
import { push } from 'connected-react-router';
import routes from 'routes';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* loadReportSaga(action: LoadReportRequestAction) {
  try {
    let err: ApiError | null;
    const { vatLoadRequestData } = action.payload;
    let pg = 0;
    let totalPgs = 0;
    let noDataMessage = '';
    do {
      pg++;

      const response: LoadReportResponse = yield call(
        loadReport,
        vatLoadRequestData,
        pg
      );

      err = checkResponse(response?.responseMessage);
      if (err) throw err;

      if (
        !response.data &&
        response.responseMessage.messageType === MessageType.WARNING
      ) {
        noDataMessage = response.responseMessage.message;
      } else {
        const vatReportData: VatReportData = response.data;
        totalPgs = vatReportData?.totalPages ? vatReportData?.totalPages : 0;
        const lastPage = pg === totalPgs || totalPgs === 0;
        yield put(generateReportSuccess(vatReportData, lastPage));
      }
    } while (pg < totalPgs);
    if (noDataMessage) {
      const message: MessageData = {
        title: 'Server Message',
        description: noDataMessage,
        type: MessageStates.WARNING,
      };
      yield put(turnMessageOn(message));
      yield put(generateReportFailure());
      yield put(push(routes.main));
    }
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(generateReportFailure());
  }
}
