import { createMemoryHistory, createBrowserHistory, MemoryHistory, History } from 'history';
import { StoreState } from './types';

type HistoryParams = {
  initialEntries?: string[];
};

export type UniversalHistory = MemoryHistory<StoreState> | History<StoreState>;

export const createHistory = ({ initialEntries = [] }: HistoryParams = {}): UniversalHistory => {
  if (__BROWSER__) {
    const history = window.browserHistory || createBrowserHistory<StoreState>();
    if (process.env.NODE_ENV === 'development' && !window.browserHistory) {
      window.browserHistory = history;
    }
    return history;
  }
  return createMemoryHistory<StoreState>({ initialEntries });
};
