import { Alert } from 'antd';
import * as React from 'react';
import { TaxReportCountry } from 'store/app/enums';
import { VatReportData } from 'store/vat/models';
interface IrelandFormProps {
  data?: VatReportData;
  renderValueCell: (boxNumber: string) => void;
  //subsectionsMap: Map<string, number>;
  //updateSubsectionsMap: (key: string, value: number) => void;
  taxReportCountry: TaxReportCountry;
}
export const IrelandForm: React.FC<IrelandFormProps> = ({
  data,
  renderValueCell,
  //subsectionsMap,
  //updateSubsectionsMap,
  taxReportCountry,
}) => {
  React.useEffect(() => {
    if (data === undefined || data === null) return;

    const currencySignSpans = document.querySelectorAll('.currency-sign');
    currencySignSpans.forEach((currencySignSpan) => {
      if (data.currencySymbol !== null) {
        currencySignSpan.textContent = data.currencySymbol;
      }
    });

    data?.sections.forEach((section, i) => {
      const sectionNameCell = document.querySelector(`#section10${i}-name`);
      if (sectionNameCell) {
        sectionNameCell.textContent = section.name;
      }
    });

    data?.subsections.forEach((subsection) => {
      const boxNumber = subsection.code;

      const subsectionNameCell = document.getElementById(
        `subsection${boxNumber}-name`
      );
      if (subsectionNameCell) subsectionNameCell.textContent = subsection.name;
      const subsectionBoxNameCell = document.getElementById(
        `subsection${boxNumber}-box-name`
      );
/*       const subsectionValueCell = document.getElementById(
        `subsection${boxNumber}-value`
      ); */

      const subsectionValueSpan = document.getElementById(
        `subsection${boxNumber}-value-span`
      );

      // if (subsectionsMap.has(boxNumber)) {
      //   const existingValue = subsectionsMap.get(boxNumber);
      //   const updatedValue =
      //     (existingValue ? existingValue : 0) + subsection.net;
      //   updateSubsectionsMap(boxNumber, updatedValue);
      // } else {
      //   updateSubsectionsMap(boxNumber, subsection.net);
      // }

      if (subsectionBoxNameCell !== null && //subsectionValueCell !== null &&
        subsectionValueSpan !== null) {
        subsectionBoxNameCell.textContent = subsection.code;
        //const textContent: any = subsectionsMap.get(boxNumber);
        const textContent: any = subsection.net;
        //subsectionValueCell.textContent = textContent.toFixed(2);
        subsectionValueSpan.textContent = textContent.toFixed(2);
      }
    });
  }, [data]);
  switch (taxReportCountry) {
    case TaxReportCountry.IRELAND_STANDARD:
      return (
        <>
          <table className="vatreporttable table">
            <thead>
              <tr>
                <th id="section100-name">General</th>
                <th></th>
                <th style={{ width: '130px', textAlign: 'center' }}>
                  Amount - <span className="currency-sign"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="subsectionT1-name">General - VAT on Sales</td>
                <td id="subsectionT1-box-name" className="box">
                  T1
                </td>
                <td
                  id="subsectionT1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('T1')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT2-name">General - VAT on Purchases</td>
                <td id="subsectionT2-box-name" className="box">
                  T2
                </td>
                <td
                  id="subsectionT2-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('T2')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT3-name">General - VAT on Purchases</td>
                <td id="subsectionT3-box-name" className="box">
                  T3
                </td>
                <td
                  id="subsectionT3-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T3')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT4-name">General - VAT on Purchases</td>
                <td id="subsectionT4-box-name" className="box">
                  T4
                </td>
                <td
                  id="subsectionT4-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('T4')}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="vatreporttable table">
            <thead>
              <tr>
                <th id="section102-name">Intra-EU Trade (INTRASTAT)</th>
                <th></th>
                <th style={{ width: '130px', textAlign: 'center' }}>
                  Amount - <span className="currency-sign"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="subsectionE1-name">
                  Total goods to other EU countries
                </td>
                <td id="subsectionE1-box-name" className="box">
                  E1
                </td>
                <td
                  id="subsectionE1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('E1')}
                </td>
              </tr>
              <tr>
                <td id="subsectionE2-name">
                  Total goods from other EU countries
                </td>
                <td id="subsectionE2-box-name" className="box">
                  E2
                </td>
                <td
                  id="subsectionE2-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('E2')}
                </td>
              </tr>
              <tr>
                <td id="subsectionES1-name">
                  Total services to other EU countries
                </td>
                <td id="subsectionES1-box-name" className="box">
                  ES1
                </td>
                <td
                  id="subsectionES1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('ES1')}
                </td>
              </tr>
              <tr>
                <td id="subsectionES2-name">
                  Total services from other EU countries
                </td>
                <td id="subsectionES2-box-name" className="box">
                  ES2
                </td>
                <td
                  id="subsectionES2-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('ES2')}
                </td>
              </tr>
              <tr>
                <td id="subsectionPA1-name">
                  Total services from other EU countries
                </td>
                <td id="subsectionPA1-box-name" className="box">
                  PA1
                </td>
                <td
                  id="subsectionPA1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('PA1')}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    case TaxReportCountry.IRELAND_DETAILED:
      return (
        <>
          <table className="vatreporttable table">
            <thead>
              <tr>
                <th id="section100-name">General - VAT on Sales (Box T1)</th>
                <th></th>
                <th style={{ width: 130, textAlign: 'center' }}>
                  Amount - <span className="currency-sign"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="subsectionT1a-name">Sales @ 23%</td>
                <td id="subsectionT1a-box-name" className="box">
                  T1a
                </td>
                <td
                  id="subsectionT1a-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('T1a')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT1b-name">Sales @ 13.5%</td>
                <td id="subsectionT1b-box-name" className="box">
                  T1b
                </td>
                <td
                  id="subsectionT1b-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T1b')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT1c-name">Sales @ 9%</td>
                <td id="subsectionT1c-box-name" className="box">
                  T1c
                </td>
                <td
                  id="subsectionT1c-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T1c')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT1d-name">Sales Reverse Charge @ 23%</td>
                <td id="subsectionT1d-box-name" className="box">
                  T1d
                </td>
                <td
                  id="subsectionT1d-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T1d')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT1e-name">Sales Reverse Charge @ 13.5%</td>
                <td id="subsectionT1e-box-name" className="box">
                  T1d
                </td>
                <td
                  id="subsectionT1e-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T1e')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT1f-name">Sales Reverse Charge @ 9%</td>
                <td id="subsectionT1f-box-name" className="box">
                  T1d
                </td>
                <td
                  id="subsectionT1f-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T1f')}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="vatreporttable table">
            <thead>
              <tr>
                <th id="section101-name">
                  General - VAT on Purchases (Box T2)
                </th>
                <th></th>
                <th style={{ width: 130, textAlign: 'center' }}>
                  Amount - <span className="currency-sign"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="subsectionT2a-name">Purchases @ 23%</td>
                <td id="subsectionT2a-box-name" className="box">
                  T2a
                </td>
                <td
                  id="subsectionT2a-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('T2a')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT2b-name">Purchases @ 13.5%</td>
                <td id="subsectionT2b-box-name" className="box">
                  T2b
                </td>
                <td
                  id="subsectionT2b-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T2b')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT2c-name">Purchases @ 9%</td>
                <td id="subsectionT2c-box-name" className="box">
                  T2c
                </td>
                <td
                  id="subsectionT2c-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T2c')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT2d-name">Purchases Reverse Charge @ 23%</td>
                <td id="subsectionT2d-box-name" className="box">
                  T2d
                </td>
                <td
                  id="subsectionT2d-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T2d')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT2e-name">
                  Purchases Reverse Charge @ 13.5%
                </td>
                <td id="subsectionT2e-box-name" className="box">
                  T2e
                </td>
                <td
                  id="subsectionT2e-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('T2e')}
                </td>
              </tr>
              <tr>
                <td id="subsectionT2f-name">Purchases Reverse Charge @ 9%</td>
                <td id="subsectionT2f-box-name" className="box">
                  T2f
                </td>
                <td
                  id="subsectionT2f-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('T2f')}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="vatreporttable table">
            <thead>
              <tr>
                <th id="section102-name">Intra-EU Trade (INTRASTAT)</th>
                <th></th>
                <th style={{ width: 130, textAlign: 'center' }}>
                  Amount - <span className="currency-sign"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="subsectionE1-name">
                  Total goods to other EU countries
                </td>
                <td id="subsectionE1-box-name" className="box">
                  E1
                </td>
                <td
                  id="subsectionE1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                   {renderValueCell('E1')}
                </td>
              </tr>
              <tr>
                <td id="subsectionE2-name">
                  Total goods from other EU countries
                </td>
                <td id="subsectionE2-box-name" className="box">
                  E2
                </td>
                <td
                  id="subsectionE2-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                   {renderValueCell('E2')}
                </td>
              </tr>
              <tr>
                <td id="subsectionES1-name">
                  Total services to other EU countries
                </td>
                <td id="subsectionES1-box-name" className="box">
                  ES1
                </td>
                <td
                  id="subsectionES1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('ES1')}
                </td>
              </tr>
              <tr>
                <td id="subsectionES2-name">
                  Total services from other EU countries
                </td>
                <td id="subsectionES2-box-name" className="box">
                  ES2
                </td>
                <td
                  id="subsectionES2-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('ES2')}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    case TaxReportCountry.IRELAND_RTD:
      return (
        <>
          <table className="vatreporttable table">
            <thead>
              <tr>
                <th style={{ width: '100px' }}></th>
                <th id="section100-name"></th>
                <th id="section101-name"></th>
                <th id="section102-name"></th>
                <th id="section103-name"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="nbb"></td>
                <td id="subsectionE3-box-name" className="box">
                  E3
                </td>
                <td id="subsectionE4-box-name" className="box">
                  E4
                </td>
                <td id="subsectionE5-box-name" className="box">
                  E5
                </td>
                <td id="subsectionE6-box-name" className="box">
                  E6
                </td>
              </tr>
              <tr>
                <td>
                  <span id="subsectionE3-name">Exempt</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td
                  id="subsectionE3-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('E3')}
                </td>
                <td
                  id="subsectionE4-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('E4')}
                </td>
                <td
                  id="subsectionE5-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('E5')}
                </td>
                <td
                  id="subsectionE6-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('E6')}
                </td>
              </tr>
              <tr>
                <td className="nbb"></td>
                <td id="subsectionD4-box-name" className="box">
                  D4
                </td>
                <td className="nbr nbb"></td>
                <td className="nbr nbb"></td>
                <td className="nbr nbb"></td>
              </tr>
              <tr>
                <td>
                  <span id="subsectionD4-name">0% Exp</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td
                  id="subsectionD4-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('D4')}
                </td>
                <td className="nbr nbb"></td>
                <td className="nbr nbb"></td>
                <td className="nbr nbb"></td>
              </tr>
              <tr>
                <td className="nbb"></td>
                <td id="subsectionD1-box-name" className="box">
                  D1
                </td>
                <td id="subsectionD2-box-name" className="box">
                  D2
                </td>
                <td id="subsectionJ1-box-name" className="box">
                  J1
                </td>
                <td id="subsectionJ2-box-name" className="box">
                  J2
                </td>
              </tr>
              <tr>
                <td>
                  <span id="subsectionD1-name">0% Home</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td
                  id="subsectionD1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('D1')}
                </td>
                <td
                  id="subsectionD2-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('D2')}
                </td>
                <td
                  id="subsectionJ1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('J1')}
                </td>
                <td
                  id="subsectionJ2-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('J2')}
                </td>
              </tr>
              <tr>
                <td className="nbb"></td>
                <td id="subsectionC5-box-name" className="box">
                  C5
                </td>
                <td id="subsectionC6-box-name" className="box">
                  C6
                </td>
                <td id="subsectionH5-box-name" className="box">
                  H5
                </td>
                <td id="subsectionH6-box-name" className="box">
                  H6
                </td>
              </tr>
              <tr>
                <td>
                  <span id="subsectionC5-name">4.5%</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td
                  id="subsectionC5-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('C5')}
                </td>
                <td
                  id="subsectionC6-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('C6')}
                </td>
                <td
                  id="subsectionH5-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('H5')}
                </td>
                <td
                  id="subsectionH6-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('H6')}
                </td>
              </tr>
              <tr>
                <td className="nbb"></td>
                <td id="subsectionBC5-box-name" className="box">
                  BC5
                </td>
                <td id="subsectionBC6-box-name" className="box">
                  BC6
                </td>
                <td id="subsectionBH5-box-name" className="box">
                  BH5
                </td>
                <td id="subsectionBH6-box-name" className="box">
                  BH6
                </td>
              </tr>
              <tr>
                <td>
                  <span id="subsectionBC5-name">9%</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td
                  id="subsectionBC5-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('BC5')}
                </td>
                <td
                  id="subsectionBC6-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('BC6')}
                </td>
                <td
                  id="subsectionBH5-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                   {renderValueCell('BH5')}
                </td>
                <td
                  id="subsectionBH6-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                   {renderValueCell('BH6')}
                </td>
              </tr>
              <tr>
                <td className="nbb"></td>
                <td id="subsectionAC5-box-name" className="box">
                  AC5
                </td>
                <td id="subsectionAC6-box-name" className="box">
                  AC6
                </td>
                <td id="subsectionAH5-box-name" className="box">
                  AH5
                </td>
                <td id="subsectionAH6-box-name" className="box">
                  AH6
                </td>
              </tr>
              <tr>
                <td>
                  <span id="subsectionAC5-name">13.5%</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td
                  id="subsectionAC5-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('AC5')}
                </td>
                <td
                  id="subsectionAC6-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('AC6')}
                </td>
                <td
                  id="subsectionAH5-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('AH5')}
                </td>
                <td
                  id="subsectionAH6-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('AH6')}
                </td>
              </tr>
              <tr>
                <td className="nbb"></td>
                <td id="subsectionB5-box-name" className="box">
                  B5
                </td>
                <td id="subsectionB6-box-name" className="box">
                  B6
                </td>
                <td id="subsectionG5-box-name" className="box">
                  G5
                </td>
                <td id="subsectionG6-box-name" className="box">
                  G6
                </td>
              </tr>
              <tr>
                <td>
                  <span id="subsectionB5-name">5.4%</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td
                  id="subsectionB5-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('B5')}
                </td>
                <td
                  id="subsectionB6-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('B6')}
                </td>
                <td
                  id="subsectionG5-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('G5')}
                </td>
                <td
                  id="subsectionG6-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                 {renderValueCell('G6')}
                </td>
              </tr>
              <tr>
                <td className="nbb"></td>
                <td id="subsectionP1-box-name" className="box">
                  P1
                </td>
                <td id="subsectionP2-box-name" className="box">
                  P2
                </td>
                <td id="subsectionR1-box-name" className="box">
                  R1
                </td>
                <td id="subsectionR2-box-name" className="box">
                  R2
                </td>
              </tr>
              <tr>
                <td>
                  <span id="subsectionP1-name">Std Rate</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td
                  id="subsectionP1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('P1')}
                </td>
                <td
                  id="subsectionP2-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('P2')}
                </td>
                <td
                  id="subsectionR1-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('R1')}
                </td>
                <td
                  id="subsectionR2-value"
                  className="rightalign value-field"
                  tabIndex={1}
                >
                  {renderValueCell('R2')}
                </td>
              </tr>
              <tr>
                <td className="nbb"></td>
                <td id="subsectionZ1-box-name" className="box">
                  Z1
                </td>
                <td id="subsectionZ2-box-name" className="box">
                  Z2
                </td>
                <td id="subsectionZ3-box-name" className="box">
                  Z3
                </td>
                <td id="subsectionZ5-box-name" className="box">
                  Z5
                </td>
              </tr>
              <tr>
                <td className="subtotal">
                  <span id="subsectionZ1-name">TOTALS</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td id="subsectionZ1-value" className="rightalign" tabIndex={1}>
                {renderValueCell('Z1')}
                </td>
                <td id="subsectionZ2-value" className="rightalign" tabIndex={1}>
                {renderValueCell('Z2')}
                </td>
                <td id="subsectionZ3-value" className="rightalign" tabIndex={1}>
                {renderValueCell('Z3')}
                </td>
                <td id="subsectionZ5-value" className="rightalign" tabIndex={1}>
                {renderValueCell('Z5')}
                </td>
              </tr>

              <tr>
                <td className="nbb"></td>
                <td className="nbb"></td>
                <td id="subsectionPA2-box-name" className="box">
                  PA2
                </td>
                <td id="subsectionPA3-box-name" className="box">
                  PA3
                </td>
                <td id="subsectionPA4-box-name" className="box">
                  PA4
                </td>
              </tr>

              <tr>
                <td>
                  <span id="subsectionPA2-name">Postponed Accounting</span>
                  <span> - </span>
                  <span className="currency-sign"></span>
                </td>
                <td className="nbb"></td>
                <td
                  id="subsectionPA2-value"
                  className="rightalign"
                  tabIndex={1}
                >
                  {renderValueCell('PA2')}
                </td>
                <td
                  id="subsectionPA3-value"
                  className="rightalign"
                  tabIndex={1}
                >
                  {renderValueCell('PA3')}
                </td>
                <td
                  id="subsectionPA4-value"
                  className="rightalign"
                  tabIndex={1}
                >
                 {renderValueCell('PA4')}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    default:
      return (
        <Alert description="No Ireland forms found" type={'error'} showIcon />
      );
  }
};
