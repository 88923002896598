import { createAction } from 'store/utils';
import {
  ActivateViesFailureAction,
  ActivateViesRequestAction,
  ActivateViesSuccessAction,
  CheckContactFailureAction,
  CheckContactRequestAction,
  CheckContactsFailureAction,
  CheckContactsRequestAction,
  CheckContactsSuccessAction,
  CheckContactSuccessAction,
  DeactivateViesFailureAction,
  DeactivateViesRequestAction,
  DeactivateViesSuccessAction,
  FetchContactFailureAction,
  FetchContactRequestAction,
  FetchContactsFailureAction,
  FetchContactsRequestAction,
  FetchContactsSuccessAction,
  FetchContactSuccessAction,
  FetchViesInfoFailureAction,
  FetchViesInfoRequestAction,
  FetchViesInfoSuccessAction,
  RefreshContactFailureAction,
  RefreshContactRequestAction,
  RefreshContactsFailureAction,
  RefreshContactsRequestAction,
  RefreshContactsSuccessAction,
  RefreshContactSuccessAction,
} from './types';
import {
  ACTIVATE_VIES_FAILURE,
  ACTIVATE_VIES_REQUEST,
  ACTIVATE_VIES_SUCCESS,
  CHECK_CONTACT_FAILURE,
  CHECK_CONTACT_REQUEST,
  CHECK_CONTACT_SUCCESS,
  CHECK_CONTACTS_FAILURE,
  CHECK_CONTACTS_REQUEST,
  CHECK_CONTACTS_SUCCESS,
  DEACTIVATE_VIES_FAILURE,
  DEACTIVATE_VIES_REQUEST,
  DEACTIVATE_VIES_SUCCESS,
  FETCH_CONTACT_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_VIES_INFO_FAILURE,
  FETCH_VIES_INFO_REQUEST,
  FETCH_VIES_INFO_SUCCESS,
  REFRESH_CONTACT_FAILURE,
  REFRESH_CONTACT_REQUEST,
  REFRESH_CONTACT_SUCCESS,
  REFRESH_CONTACTS_FAILURE,
  REFRESH_CONTACTS_REQUEST,
  REFRESH_CONTACTS_SUCCESS,
} from './constants';
import { Contact, ViesCheck, ViesInfo } from './models';

// Get Vies info
export const fetchViesInfoRequest = (
  connectionId: string | undefined = undefined
): FetchViesInfoRequestAction =>
  createAction(FETCH_VIES_INFO_REQUEST, { connectionId });

export const fetchViesInfoSuccess = (
  viesInfos: ViesInfo[]
): FetchViesInfoSuccessAction =>
  createAction(FETCH_VIES_INFO_SUCCESS, { viesInfos });

export const fetchViesInfoFailure = (): FetchViesInfoFailureAction =>
  createAction(FETCH_VIES_INFO_FAILURE);

// Activate Vies
export const activateViesRequest = (
  connectionId: string,
  entityName: string
): ActivateViesRequestAction =>
  createAction(ACTIVATE_VIES_REQUEST, { connectionId, entityName });

export const activateViesSuccess = (
  connectionId: string
): ActivateViesSuccessAction =>
  createAction(ACTIVATE_VIES_SUCCESS, { connectionId });

export const activateViesFailure = (): ActivateViesFailureAction =>
  createAction(ACTIVATE_VIES_FAILURE);

// Deactivate Vies
export const deactivateViesRequest = (
  connectionId: string,
  entityName: string
): DeactivateViesRequestAction =>
  createAction(DEACTIVATE_VIES_REQUEST, { connectionId, entityName });

export const deactivateViesSuccess = (
  connectionId: string
): DeactivateViesSuccessAction =>
  createAction(DEACTIVATE_VIES_SUCCESS, { connectionId });

export const deactivateViesFailure = (): DeactivateViesFailureAction =>
  createAction(DEACTIVATE_VIES_FAILURE);

// Get Contacts
export const fetchContactsRequest = (
  connectionId: string
): FetchContactsRequestAction =>
  createAction(FETCH_CONTACTS_REQUEST, { connectionId });

export const fetchContactsSuccess = (
  contacts: Contact[]
): FetchContactsSuccessAction =>
  createAction(FETCH_CONTACTS_SUCCESS, { contacts });

export const fetchContactsFailure = (): FetchContactsFailureAction =>
  createAction(FETCH_CONTACTS_FAILURE);

// Get Contact
export const fetchContactRequest = (
  contactUuid: string
): FetchContactRequestAction => {
  return createAction(FETCH_CONTACT_REQUEST, { contactUuid });
};

export const fetchContactSuccess = (
  viesChecks: ViesCheck[],
  contact: Contact
): FetchContactSuccessAction =>
  createAction(FETCH_CONTACT_SUCCESS, { viesChecks, contact });

export const fetchContactFailure = (): FetchContactFailureAction =>
  createAction(FETCH_CONTACT_FAILURE);

// Check Contacts
export const checkContactsRequest = (
  connectionId: string
): CheckContactsRequestAction =>
  createAction(CHECK_CONTACTS_REQUEST, { connectionId });

export const checkContactsSuccess = (
  lastViesCheckDate: string,
  contacts: Contact[],
  connectionId: string
): CheckContactsSuccessAction =>
  createAction(CHECK_CONTACTS_SUCCESS, {
    lastViesCheckDate,
    contacts,
    connectionId,
  });

export const checkContactsFailure = (): CheckContactsFailureAction =>
  createAction(CHECK_CONTACTS_FAILURE);

// Check Contact
export const checkContactRequest = (
  contactUuid: string
): CheckContactRequestAction =>
  createAction(CHECK_CONTACT_REQUEST, { contactUuid });

export const checkContactSuccess = (
  viesChecks: ViesCheck[],
  contact: Contact
): CheckContactSuccessAction =>
  createAction(CHECK_CONTACT_SUCCESS, { viesChecks, contact });

export const checkContactFailure = (): CheckContactFailureAction =>
  createAction(CHECK_CONTACT_FAILURE);

// Refresh Contacts
export const refreshContactsRequest = (
  connectionId: string
): RefreshContactsRequestAction =>
  createAction(REFRESH_CONTACTS_REQUEST, { connectionId });

export const refreshContactsSuccess = (): RefreshContactsSuccessAction =>
  createAction(REFRESH_CONTACTS_SUCCESS);

export const refreshContactsFailure = (): RefreshContactsFailureAction =>
  createAction(REFRESH_CONTACTS_FAILURE);

// Refresh Contact
export const refreshContactRequest = (
  contactId: string,
  connectionId: string
): RefreshContactRequestAction =>
  createAction(REFRESH_CONTACT_REQUEST, { contactId, connectionId });

export const refreshContactSuccess = (): RefreshContactSuccessAction =>
  createAction(REFRESH_CONTACT_SUCCESS);

export const refreshContactFailure = (): RefreshContactFailureAction =>
  createAction(REFRESH_CONTACT_FAILURE);
