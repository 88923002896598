import { ValidationResponse, Validator, ValidationValue } from './types';

export const composeValidators =
  (...validators: Validator[]) =>
  (value: ValidationValue): ValidationResponse =>
    validators.reduce(
      (error: ValidationResponse, validator: Validator) =>
        error || validator(value),
      undefined
    );

export function navigateToExternalPage(url: string) {
  window.location.assign(url);
}
