import { ConfigProvider, Table, Typography } from 'antd';
import { StyledCard } from 'components/Custom/card';
import * as React from 'react';

export const renderReconciliations = (data?: Map<string, string>) => {
  const arrayData: any[] = [];
  for (const key in data) {
    const value = data[key];
    arrayData.push({ key, value });
  }

  const columns = [
    {
      title: 'Control Account',
      width: '50%',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <StyledCard width="850px">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#E9F6F7',
              borderColor: '#DBEAE3',
            },
          },
        }}
      >
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '24px',
            textAlign: 'left',
            color: '#000000',
            marginBottom: '20px',
          }}
        >
          Validate vs Control Account
        </Typography>
        <Table
          dataSource={arrayData}
          columns={columns}
          rowKey="key"
          size="small"
          pagination={false}
          bordered
        />
      </ConfigProvider>
    </StyledCard>
  );
};
