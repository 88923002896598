import { createAction } from 'store/utils';
import {
  AddMemberSuccessAction,
  AddMemberRequestAction,
  FetchUsersFailureAction,
  FetchUsersRequestAction,
  FetchUsersSuccessAction,
  SetUserPermissionsFailureAction,
  SetUserPermissionsRequestAction,
  SetUserPermissionsSuccessAction,
  DeleteUserRequestAction,
  DeleteUserSuccessAction,
  DeleteUserFailureAction,
  FetchUserHistoryRequestAction,
  FetchUserHistorySuccessAction,
  FetchUserRightsRequestAction,
  FetchUserRightsSuccessAction,
  FetchUserHistoryFailureAction,
  FetchUserRightsFailureAction,
  SetUserRightsRequestAction,
  SetUserRightsSuccessAction,
  SetUserRightsFailureAction,
  AddMemberFailureAction,
  ResetUserDataAction,
} from './types';
import { User } from 'store/auth/models';
import {
  ADD_MEMBER_FAILURE,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USER_HISTORY_FAILURE,
  FETCH_USER_HISTORY_REQUEST,
  FETCH_USER_HISTORY_SUCCESS,
  FETCH_USER_RIGHTS_FAILURE,
  FETCH_USER_RIGHTS_REQUEST,
  FETCH_USER_RIGHTS_SUCCESS,
  RESET_USER_DATA,
  SET_USER_PERMISSIONS_FAILURE,
  SET_USER_PERMISSIONS_REQUEST,
  SET_USER_PERMISSIONS_SUCCESS,
  SET_USER_RIGHTS_FAILURE,
  SET_USER_RIGHTS_REQUEST,
  SET_USER_RIGHTS_SUCCESS,
} from './constants';
import { PermissionType, TablePermission } from 'store/api/enums';
import { NewMember } from 'pages/users';
import { LoginDetails, UserRights } from './models';

// Register
export const fetchUsersRequest = (): FetchUsersRequestAction =>
  createAction(FETCH_USERS_REQUEST);

export const fetchUsersSuccess = (members: User[]): FetchUsersSuccessAction =>
  createAction(FETCH_USERS_SUCCESS, { members });

export const fetchUsersFailure = (): FetchUsersFailureAction =>
  createAction(FETCH_USERS_FAILURE);

// Set User Permissions
export const setUserPermissionsRequest = (
  accountUuid: string,
  permissionType: PermissionType,
  enabled: boolean
): SetUserPermissionsRequestAction =>
  createAction(SET_USER_PERMISSIONS_REQUEST, {
    accountUuid,
    permissionType,
    enabled,
  });

export const setUserPermissionsSuccess = (
  accountUuid: string,
  permissionType: PermissionType,
  enabled: boolean
): SetUserPermissionsSuccessAction =>
  createAction(SET_USER_PERMISSIONS_SUCCESS, {
    accountUuid,
    permissionType,
    enabled,
  });

export const setUserPermissionsFailure = (): SetUserPermissionsFailureAction =>
  createAction(SET_USER_PERMISSIONS_FAILURE);

// Add member
export const addMemberRequest = (
  newMember: NewMember,
  getRights: boolean = false
): AddMemberRequestAction =>
  createAction(ADD_MEMBER_REQUEST, {
    newMember,
    getRights,
  });

export const addMemberSuccess = (newUser: User): AddMemberSuccessAction =>
  createAction(ADD_MEMBER_SUCCESS, { newUser });

export const addMemberFailure = (): AddMemberFailureAction =>
  createAction(ADD_MEMBER_FAILURE);

// Delete user
export const deleteUserRequest = (
  userToDeleteId: string
): DeleteUserRequestAction =>
  createAction(DELETE_USER_REQUEST, {
    userToDeleteId,
  });

export const deleteUserSuccess = (
  userToDeleteId: string
): DeleteUserSuccessAction => {
  return createAction(DELETE_USER_SUCCESS, { userToDeleteId });
};

export const deleteUserFailure = (): DeleteUserFailureAction =>
  createAction(DELETE_USER_FAILURE);

// Fetch User History
export const fetchUserHistoryRequest = (
  historyUserId: string
): FetchUserHistoryRequestAction =>
  createAction(FETCH_USER_HISTORY_REQUEST, {
    historyUserId,
  });

export const fetchUserHistorySuccess = (
  loginDetails: LoginDetails[]
): FetchUserHistorySuccessAction =>
  createAction(FETCH_USER_HISTORY_SUCCESS, { loginDetails });

export const fetchUserHistoryFailure = (): FetchUserHistoryFailureAction =>
  createAction(FETCH_USER_HISTORY_FAILURE);

// Fetch User Rights
export const fetchUserRightsRequest = (
  accountId: string,
  userId?: string
): FetchUserRightsRequestAction =>
  createAction(FETCH_USER_RIGHTS_REQUEST, {
    accountId,
    userId,
  });

export const fetchUserRightsSuccess = (
  userRights: UserRights[]
): FetchUserRightsSuccessAction =>
  createAction(FETCH_USER_RIGHTS_SUCCESS, { userRights });

export const fetchUserRightsFailure = (): FetchUserRightsFailureAction =>
  createAction(FETCH_USER_RIGHTS_FAILURE);

// Set User Rights
export const setUserRightsRequest = (
  userRights: UserRights,
  tablePermission: TablePermission,
  user?: User
): SetUserRightsRequestAction =>
  createAction(SET_USER_RIGHTS_REQUEST, {
    userRights,
    tablePermission,
    user,
  });

export const setUserRightsSuccess = (
  uuid: string,
  tablePermission: TablePermission
): SetUserRightsSuccessAction =>
  createAction(SET_USER_RIGHTS_SUCCESS, { uuid, tablePermission });

export const setUserRightsFailure = (): SetUserRightsFailureAction =>
  createAction(SET_USER_RIGHTS_FAILURE);

// RESET
export const resetUserData = (): ResetUserDataAction =>
  createAction(RESET_USER_DATA);
