import { call, put } from 'redux-saga/effects';
import { cancelReturnFailure, cancelReturnSuccess } from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { CancelReturnRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { cancelReturn } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { ERROR_HANG_TIME } from 'store/app/constants';
import routes from 'routes';
import { push } from 'connected-react-router';
import { updateSubmissionLog } from 'store/organisation/actions';
import { DataLogStatus, SubmissionUpdateField } from 'store/app/enums';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* cancelReturnSaga(action: CancelReturnRequestAction) {
  try {
    let err: ApiError | null;
    const { dataLogId, connectionId, mappingId } = action.payload;

    const response: ApiResponse = yield call(cancelReturn, dataLogId);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success !',
      description: response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));
    yield put(
      updateSubmissionLog(
        mappingId,
        SubmissionUpdateField.STATUS,
        DataLogStatus.CANCELLED
      )
    );

    yield put(push(routes.main));

    yield put(cancelReturnSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(cancelReturnFailure());
  }
}
