import { call, put } from 'redux-saga/effects';
import { resendActivationFailure, resendActivationSuccess } from '../actions';
import { resend } from '../api';
import { ResendActivationRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { ERROR_HANG_TIME } from 'store/app/constants';
import { MessageType } from 'store/api/enums';
import { push } from 'connected-react-router';
import routes from 'routes';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* resendActivationSaga(action: ResendActivationRequestAction) {
  try {
    let err: ApiError | null;

    const { email, invited } = action.payload;
    const response: ApiResponse = yield call(resend, email);
    err = checkResponse(response?.responseMessage);
    if (err) throw err;
    if (invited) {
      const message: MessageData = {
        title: 'Success',
        description: 'Activation email sent.',
        type: MessageStates.SUCCESS,
      };
      yield put(turnMessageOn(message));
    } else if (response.responseMessage.messageType === MessageType.INFO) {
      const message: MessageData = {
        title: 'Server message',
        description: response.responseMessage.message,
        type: MessageStates.INFO,
      };
      yield put(turnMessageOn(message));
    } else yield put(push(routes.signUp.signupSuccess));
    yield put(resendActivationSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(resendActivationFailure());
  }
}
