import React, { useState } from 'react';
import { ResetPasswordRequestForm } from 'components/ResetPasswordRequestForm';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordEmailRequest } from 'store/auth/actions';
import {
  getAuthLoading,
  //getAuthSuccess,
} from 'store/auth/selectors';
import { Helmet } from 'react-helmet-async';
import { ResetPasswordSuccess } from 'components/ResetPasswordRequestForm/success';
import { UserDetails } from 'store/signup/models';

export const ResetPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  //const [sentEmail, setSentEmail] = useState<string>('');
  const sendResetPasswordEmail = (forgotEmail: string) => {
    const user = { forgotEmail } as UserDetails;
    dispatch(resetPasswordEmailRequest(user));
    //setSentEmail(forgotEmail);
  };
  const loading = useSelector(getAuthLoading);

  return (
    <div>
      <Helmet>
        <title>{`Reset Password | ${process.env.PROJECT_NAME}`}</title>
        <meta
          name="description"
          content="This a page to request resetting password"
        />
      </Helmet>
      {/* {sentEmail ? (
        <ResetPasswordSuccess sentEmail={sentEmail} />
      ) : ( */}
      <ResetPasswordRequestForm
        onRequestResetPassword={sendResetPasswordEmail}
        loading={loading}
      />
      {/* )} */}
    </div>
  );
};
