export const FETCH_ORGANISATIONS_REQUEST =
  'organisations/fetch-organisations-request';
export const FETCH_ORGANISATIONS_SUCCESS =
  'organisations/fetch-organisations-success';
export const FETCH_ORGANISATIONS_FAILURE =
  'organisations/fetch-organisations-failure';

export const DISCONNECT_ORGANISATION_REQUEST =
  'organisations/disconnect-organisations-request';
export const DISCONNECT_ORGANISATION_SUCCESS =
  'organisations/disconnect-organisations-success';
export const DISCONNECT_ORGANISATION_FAILURE =
  'organisations/disconnect-organisations-failure';

export const EDIT_ORGANISATION_REQUEST =
  'organisations/edit-organisations-request';
export const EDIT_ORGANISATION_SUCCESS =
  'organisations/edit-organisations-success';
export const EDIT_ORGANISATION_FAILURE =
  'organisations/edit-organisations-failure';

export const FETCH_ADD_URL_REQUEST = 'organisations/fetch-add-url-request';
export const FETCH_ADD_URL_SUCCESS = 'organisations/fetch-add-url-success';
export const FETCH_ADD_URL_FAILURE = 'organisations/fetch-add-url-failure';

export const CONNECT_CIN7_ENTITY_REQUEST =
  'organisations/connect-cin7-entity-request';
export const CONNECT_CIN7_ENTITY_SUCCESS =
  'organisations/connect-cin7-entity-success';
export const CONNECT_CIN7_ENTITY_FAILURE =
  'organisations/connect-cin7-entity-failure';

// export const POST_SAVE_REFRESH_MAPPING_DATA =
//   'organisations/post-save-refresh-mapping-data';

export const UPDATE_ENTITY_MEMBERS = 'organisations/update-entity-members';
export const SET_GETTING_TAXES = 'organisations/set-getting-taxes';
export const UPDATE_SUBMISSION_LOG = 'organisations/update-submission-log';

export const RESET_ORGANISATION_DATA = 'organisations/reset-organisation-data';
