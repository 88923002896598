import { api } from 'store/api';
import { ApiResponse } from 'store/api/types';

import { EmptyResponse } from 'store/commonTypes';
import { UserDetails } from 'store/signup/models';
import { User } from './models';

export interface LoginResponse extends ApiResponse {
  data: { useMFA: boolean; email: string; password: string };
}

export const login = (
  loginParams: UserDetails,
  gCaptchaResponse: string,
  mfaCode: string | null
): Promise<LoginResponse> =>
  api.post<LoginResponse>('/auth/login', {
    body: { user: loginParams, gCaptchaResponse, mfaCode },
  });

export interface UserResponse extends ApiResponse {
  data: { user: User };
}

export const getCurrentUser = (): Promise<UserResponse> =>
  api.get<UserResponse>('/auth/account/details');

// export const getUser = (uuid: string): Promise<UserResponse> =>
//   api.get<UserResponse>(`/auth/account/member/${uuid}/details`);

export const logout = (): Promise<EmptyResponse> =>
  api.post<EmptyResponse>('/auth/logout');

export type ResetPasswordRequest = { user: UserDetails; redirectURL: string };
export const resetPasswordEmail = (
  user: UserDetails
): Promise<EmptyResponse> => {
  const redirectURL = `${process.env.PUBLIC_URL}/forgot-password/confirm`;
  const resetPasswordRequest: ResetPasswordRequest = { user, redirectURL };
  return api.put<EmptyResponse>('/common/forgotten/send', {
    body: resetPasswordRequest,
  });
};
