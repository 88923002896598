import { call, put, select } from 'redux-saga/effects';
import { fetchProfilePictureSuccess } from '../actions';
import { getProfilePicture } from '../api';
import { FetchProfilePictureRequestAction } from '../types';
import { ApiError } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { updateUserPictures } from 'store/app/actions';
import { getAuthUser } from 'store/auth/selectors';
import { fetchMeSuccess } from 'store/auth/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchProfilePictureSaga(
  action: FetchProfilePictureRequestAction
) {
  try {
    let err: ApiError | null;

    const { userId } = action.payload;
    const response: any = yield call(getProfilePicture, userId);

    const profilePicture = response.data['profile-picture'];
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(updateUserPictures(profilePicture, userId));

    yield put(fetchProfilePictureSuccess(profilePicture));

    const currentUser = yield select(getAuthUser);
    if (userId === currentUser.userUuid) {
      yield put(fetchMeSuccess({ ...currentUser, profilePicture }));
    }
  } catch (error: any) {
    yield put(fetchProfilePictureSuccess(''));
  }
}
