import { Button, Popconfirm, Space } from 'antd';
import React from 'react';
import { User } from 'store/auth/models';
import { AvatarSizes, renderAvatar } from './render-avatar';
import { BoldNameSpan, GraySpan } from 'components/Custom/spans';
import { AccountType } from 'store/api/enums';
import { DeleteOutlined } from '@ant-design/icons';

interface RenderUserDetailProps {
  record: User;
  showOption?: boolean;
  showRole?: boolean;
  hideEmail?: boolean;
  showDelete?: boolean;
  memberRemove?: (accountId: string) => void;
}

export const RenderUserDetail: React.FC<RenderUserDetailProps> = ({
  record,
  showOption = false,
  showRole = false,
  hideEmail = false,
  showDelete = false,
  memberRemove,
}) => {
  const onMemberRemove = () => memberRemove && memberRemove(record.accountUuid);
  return (
    <Space
      style={{
        borderBottom: !showOption ? '1px solid #DBEAE3' : 'none',
        padding: !showOption ? '14px 0px' : '8px 0px',
      }}
    >
      {renderAvatar(
        record?.firstName,
        record?.lastName,
        AvatarSizes.DEFAULT,
        record?.userUuid,
        record?.profilePicture
      )}
      <Space
        direction="vertical"
        style={{ rowGap: '0', minWidth: !showOption ? 210 : 170 }}
      >
        <BoldNameSpan
          style={{ fontSize: !showOption ? 15 : 13, fontWeight: 600 }}
        >{`${record?.firstName} ${record?.lastName}`}</BoldNameSpan>

        {!hideEmail && (
          <BoldNameSpan //@ts-ignore
            fontWeight={400}
            style={{ color: '#879494' }}
          >
            {record?.email}
          </BoldNameSpan>
        )}
      </Space>
      {showRole && (
        <GraySpan style={{ marginLeft: '0' }}>
          {AccountType[record?.accountType]}
        </GraySpan>
      )}

      {showDelete && AccountType[record.accountType] !== AccountType.OWNER && (
        <Popconfirm
          title={'Remove user from entity'}
          description={
            <span>{'Are you sure you wish to remove this user?'}</span>
          }
          onConfirm={onMemberRemove}
          placement="left"
          okText={'Yes'}
          cancelText={'Cancel'}
        >
          <Button type="link" shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      )}
    </Space>
  );
};
