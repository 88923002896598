import { call, put } from 'redux-saga/effects';
import {
  BusinessTypesMap,
  BusinessTypesResponse,
  getBusinessTypes,
} from '../api';
import {
  fetchBusinessTypesFailure,
  fetchBusinessTypesSuccess,
} from '../actions';
import { BusinessType } from '../models';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { ERROR_HANG_TIME } from 'store/app/constants';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchBusinessTypesSaga() {
  try {
    let err: ApiError | null;
    const response: BusinessTypesResponse = yield call(getBusinessTypes);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const businessTypesMap: BusinessTypesMap = response?.data.businessTypes;
    const businessTypes: BusinessType[] = [];

    for (const key in businessTypesMap) {
      businessTypes.push({ id: key, name: businessTypesMap[key] });
    }

    yield put(fetchBusinessTypesSuccess(businessTypes));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(fetchBusinessTypesFailure());
  }
}
