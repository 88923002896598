import {
  CHANGE_OWNER_FAILURE,
  CHANGE_OWNER_REQUEST,
  CHANGE_OWNER_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DISABLE_MFA_FAILURE,
  DISABLE_MFA_REQUEST,
  DISABLE_MFA_SUCCESS,
  DISCONNECT_PROVIDER_FAILURE,
  DISCONNECT_PROVIDER_REQUEST,
  DISCONNECT_PROVIDER_SUCCESS,
  ENABLE_MFA_FAILURE,
  ENABLE_MFA_REQUEST,
  ENABLE_MFA_SUCCESS,
  FETCH_CONNECT_URL_FAILURE,
  FETCH_CONNECT_URL_REQUEST,
  FETCH_CONNECT_URL_SUCCESS,
  FETCH_PROFILE_PICTURE_FAILURE,
  FETCH_PROFILE_PICTURE_REQUEST,
  FETCH_PROFILE_PICTURE_SUCCESS,
  FETCH_QR_CODE_FAILURE,
  FETCH_QR_CODE_REQUEST,
  FETCH_QR_CODE_SUCCESS,
  RESET_ACCOUNT_DATA,
  UPDATE_DETAILS_FAILURE,
  UPDATE_DETAILS_REQUEST,
  UPDATE_DETAILS_SUCCESS,
  UPLOAD_PROFILE_PICTURE_FAILURE,
  UPLOAD_PROFILE_PICTURE_REQUEST,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
} from './constants';
import {
  AccountAction,
  AccountState,
  FetchProfilePictureSuccessAction,
  FetchQRCodeSuccessAction,
} from './types';

const initialState: AccountState = {
  loading: false,
  QRCodeData: undefined,
  profilePicture: '',
};

export const accountReducer = (
  state = initialState,
  action: AccountAction
): AccountState => {
  switch (action.type) {
    case FETCH_CONNECT_URL_REQUEST:
    case FETCH_QR_CODE_REQUEST:
    case DISCONNECT_PROVIDER_REQUEST:
    case ENABLE_MFA_REQUEST:
    case DISABLE_MFA_REQUEST:
    case UPDATE_DETAILS_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case CHANGE_OWNER_REQUEST:
    case DELETE_ACCOUNT_REQUEST:
    case UPLOAD_PROFILE_PICTURE_REQUEST:
    case FETCH_PROFILE_PICTURE_REQUEST:
      return handleRequest(state);
    case FETCH_CONNECT_URL_SUCCESS:
    case DISCONNECT_PROVIDER_SUCCESS:
    case ENABLE_MFA_SUCCESS:
    case DISABLE_MFA_SUCCESS:
    case UPDATE_DETAILS_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_OWNER_SUCCESS:
    case DELETE_ACCOUNT_SUCCESS:
    case UPLOAD_PROFILE_PICTURE_SUCCESS:
      return handleSuccess(state);
    case FETCH_PROFILE_PICTURE_SUCCESS:
      return handleFetchProfilePictureSuccess(state, action);
    case FETCH_QR_CODE_SUCCESS:
      return handleFetchQRCodeSuccess(state, action);
    case FETCH_QR_CODE_FAILURE:
    case DISCONNECT_PROVIDER_FAILURE:
    case ENABLE_MFA_FAILURE:
    case FETCH_CONNECT_URL_FAILURE:
    case DISABLE_MFA_FAILURE:
    case UPDATE_DETAILS_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
    case CHANGE_OWNER_FAILURE:
    case DELETE_ACCOUNT_FAILURE:
    case UPLOAD_PROFILE_PICTURE_FAILURE:
    case FETCH_PROFILE_PICTURE_FAILURE:
      return handleFailure(state);
    case RESET_ACCOUNT_DATA:
      return handleResetAccountData();
    default:
      return state;
  }
};

// Fetch QR Code
function handleFetchQRCodeSuccess(
  state: AccountState,
  action: FetchQRCodeSuccessAction
): AccountState {
  return {
    ...state,
    loading: false,
    QRCodeData: action.payload.QRCodeData,
  };
}

// Fetch profile picture
function handleFetchProfilePictureSuccess(
  state: AccountState,
  action: FetchProfilePictureSuccessAction
): AccountState {
  return {
    ...state,
    loading: false,
    profilePicture: action.payload.profilePicture,
  };
}

// Common
function handleRequest(state: AccountState): AccountState {
  return {
    ...state,
    loading: true,
  };
}

function handleSuccess(state: AccountState): AccountState {
  return {
    ...state,
    loading: false,
  };
}

function handleFailure(state: AccountState): AccountState {
  return {
    ...state,
    loading: false,
  };
}

// Reset
function handleResetAccountData(): AccountState {
  return initialState;
}
