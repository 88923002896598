import { call, put } from 'redux-saga/effects';
import { ApiError, ApiResponse } from 'store/api/types';
import { RefreshContactsRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { refreshContacts } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { ERROR_HANG_TIME } from 'store/app/constants';
import {
  fetchContactsRequest,
  refreshContactsFailure,
  refreshContactsSuccess,
} from '../actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* refreshContactsSaga(action: RefreshContactsRequestAction) {
  try {
    let err: ApiError | null;
    const { connectionId } = action.payload;

    const response: ApiResponse = yield call(refreshContacts, connectionId);
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(fetchContactsRequest(connectionId));
    yield put(refreshContactsSuccess());
    const message: MessageData = {
      title: 'Success',
      description: 'Contacts were refreshed.',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(refreshContactsFailure());
  }
}
