import { Button, Row, Space, Typography } from 'antd';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { turnModalOff } from 'store/app/actions';

const { Text } = Typography;
interface ConfirmBoxContainerProps {
  callback: (props?: any) => void;
  callback2?: (props?: any) => void;
  param?: any;
  param2?: any;
  description: string;
  cancelText?: string;
  okText?: string;
  okText2?: string;
}
export const ConfirmBoxContainer: React.FC<ConfirmBoxContainerProps> = ({
  callback,
  callback2,
  param,
  param2,
  description,
  cancelText,
  okText,
  okText2,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <Row>
        <Text>{description}</Text>
      </Row>
      <Row justify={'end'}>
        <Space>
          <Button type={'default'} onClick={() => dispatch(turnModalOff())}>
            {cancelText ? cancelText : 'Cancel'}
          </Button>
          <Button
            type={'primary'}
            onClick={() => {
              dispatch(turnModalOff());
              param ? callback(param) : callback();
            }}
          >
            {okText ? okText : 'Yes'}
          </Button>
          {callback2 && (
            <Button
              type={'primary'}
              onClick={() => {
                dispatch(turnModalOff());
                param2 ? callback2(param2) : callback2();
              }}
            >
              {okText2 ? okText2 : 'Yes 2'}
            </Button>
          )}
        </Space>
      </Row>
    </>
  );
};
