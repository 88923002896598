import { call, put } from 'redux-saga/effects';
import {
  resetPasswordEmailFailure,
  resetPasswordEmailSuccess,
} from '../actions';
import { resetPasswordEmail } from '../api';
import { ResetPasswordEmailRequestAction } from '../types';
import { MessageType } from 'store/api/enums';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { ERROR_HANG_TIME } from 'store/app/constants';
import { checkResponse } from 'store/utils';
import { push } from 'connected-react-router';
import routes from 'routes';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* resetPasswordEmailSaga(
  action: ResetPasswordEmailRequestAction
) {
  try {
    let err: ApiError | null;

    const { user } = action.payload;

    const response = yield call(resetPasswordEmail, user);

    //err = checkResponse(response?.responseMessage);
    //if (err) throw err;

    yield put(push(routes.resetPassword.emailSent));
    yield put(resetPasswordEmailSuccess());
  } catch (error) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(resetPasswordEmailFailure());
  }
}
