import { call, put, select } from 'redux-saga/effects';
import {
  disconnectOrganisationsFailure,
  disconnectOrganisationsSuccess,
  fetchOrganisationsSuccess,
} from '../actions';
import { disconnectOrganisation } from '../api';
import { DisconnectOrganisationRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { push } from 'connected-react-router';
import routes from 'routes';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { ERROR_HANG_TIME } from 'store/app/constants';
import { getOrgMappings, getOrgOrganisations } from '../selectors';
import { Organisation } from '../models';
import { fetchViesInfoRequest } from 'store/vat-checker/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* disconnectOrganisationSaga(
  action: DisconnectOrganisationRequestAction
) {
  try {
    let err: ApiError | null;
    const { organisationId, fromVIES } = action.payload;
    const response: ApiResponse = yield call(
      disconnectOrganisation,
      organisationId
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const orgs: Organisation[] = yield select(getOrgOrganisations);
    const maps = yield select(getOrgMappings);

    const idx = orgs.findIndex((o) => o.uuid === organisationId);
    idx > -1 && orgs.splice(idx, 1);

    yield put(fetchOrganisationsSuccess(orgs, maps));
    yield put(disconnectOrganisationsSuccess());

    if (!fromVIES) yield put(push(routes.organisations.all));
    else {
      put(fetchViesInfoRequest());
      put(push(routes.vies.qualifiedEntities));
    }

    const message: MessageData = {
      title: 'Success',
      description: 'Entity Disconnected',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(disconnectOrganisationsFailure());
  }
}
