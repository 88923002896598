type RoutesConfig = {
  login: string;
  logout: string;
  signUp: {
    root: string;
    signupForm: string;
    validateUser: string;
    signupSuccess: string;
    signupSSO: string;
  };
  activate: string;
  notifications: string;
  resetPassword: {
    root: string;
    request: string;
    confirm: string;
    emailSent: string;
  };
  home: string;
  main: string;
  organisations: { all: string; organisation: string };
  returns: { root: string; add: string; edit: string };
  account: { root: string; details: string; error: string };
  users: string;
  notFound: string;
  termsOfUse: string;
  privacyPolicy: string;
  tempRedirector: string;
  vat: {
    root: string;
    viewReportLink: string;
    report: string;
  };
  billing: string;
  vies: {
    qualifiedEntities: string;
    customersAndSuppliers: string;
    contact: string;
  };
  support: string;
  reporting: string;
  pictureTest: string;
};

const routes: RoutesConfig = {
  login: '/login',
  logout: '/logout',
  signUp: {
    root: '/signup',
    signupForm: '/signup/form',
    validateUser: '/signup/validate/:id',
    signupSuccess: '/signup/success',
    signupSSO: '/signup/sso',
  },
  activate: '/activate',
  notifications: '/notifications',
  resetPassword: {
    root: '/forgot-password',
    request: '/forgot-password/request',
    confirm: '/forgot-password/confirm/:id',
    emailSent: '/forgot-password/email-sent',
  },
  home: '/',
  main: '/main',
  organisations: {
    all: '/entities',
    organisation: '/entities/organisation/:id',
  },
  returns: {
    root: '/returns',
    add: '/returns/:connectionId',
    edit: '/returns/:connectionId/editMapping/:mappingId',
  },
  account: {
    root: '/profile',
    details: '/profile/:accountId',
    error: '/profile/error/:errorId',
  },
  users: '/users',
  notFound: '/not-found',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
  tempRedirector: '/redirector/:route',
  vat: {
    root: '/vat',
    viewReportLink:
      '/vat/report/connection/:connectionId/mapping/:mappingId/log/:dataLogId',
    report: '/vat/report',
  },
  billing: '/billing',
  vies: {
    qualifiedEntities: '/vat-checker',
    customersAndSuppliers: '/vat-checker/customers-and-suppliers/:connectionId',
    contact:
      '/vat-checker/contact-details/connection/:connectionId/contact/:contactId',
  },
  support: '/support',
  reporting: '/reporting',
  pictureTest: '/pictureTest',
};

export const getRoute = (
  path: string,
  params?: { [key: string]: string | number },
  routesConfig: RoutesConfig = routes
): RoutesConfig =>
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  path.split('.').reduce((routeBranch: any, pathItem: string) => {
    if (routeBranch && routeBranch[pathItem]) {
      const route = routeBranch[pathItem];
      if (typeof route === 'string') {
        if (!params || typeof params === 'undefined') {
          return route;
        }

        return Object.entries(params).reduce((replaced, [key, value]) => {
          return replaced.replace(`:${key}`, String(value));
        }, route);
      }
      return routeBranch[pathItem];
    }
  }, routesConfig);

export default routes;
