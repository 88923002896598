import {
  FETCH_BUSINESS_SIZES_FAILURE,
  FETCH_BUSINESS_SIZES_REQUEST,
  FETCH_BUSINESS_SIZES_SUCCESS,
  FETCH_BUSINESS_TYPES_FAILURE,
  FETCH_BUSINESS_TYPES_REQUEST,
  FETCH_BUSINESS_TYPES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_CURRENCIES_FAILURE,
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_TIMEZONES_FAILURE,
  FETCH_TIMEZONES_REQUEST,
  FETCH_TIMEZONES_SUCCESS,
  FETCH_COUNTRIES_FLAGS_REQUEST,
  FETCH_COUNTRIES_FLAGS_SUCCESS,
  FETCH_COUNTRIES_FLAGS_FAILURE,
} from './constants';
import {
  CommonAction,
  CommonState,
  FetchBusinessSizesSuccessAction,
  FetchBusinessTypesSuccessAction,
  FetchCountriesSuccessAction,
  FetchCurrenciesSuccessAction,
  FetchTimezonesSuccessAction,
  FetchCountriesExternalDataSuccessAction,
} from './types';

const initialState: CommonState = {
  loading: false,
  error: undefined,
  errorCode: undefined,
  countries: [],
  businessTypes: [],
  businessSizes: [],
  timezones: [],
  currencies: [],
  countriesExternalData:[]
};

export const commonReducer = (
  state = initialState,
  action: CommonAction
): CommonState => {
  switch (action.type) {
    case FETCH_COUNTRIES_REQUEST:
    case FETCH_COUNTRIES_FLAGS_REQUEST:
    case FETCH_TIMEZONES_REQUEST:
    case FETCH_BUSINESS_SIZES_REQUEST:
    case FETCH_BUSINESS_TYPES_REQUEST:
    case FETCH_CURRENCIES_REQUEST:
      return handleRequest(state);
    case FETCH_COUNTRIES_SUCCESS:
      return handleFetchCountriesSuccess(state, action);
    case FETCH_COUNTRIES_FLAGS_SUCCESS:
      return handleFetchCountriesExternalDataSuccess(state, action);
    case FETCH_BUSINESS_TYPES_SUCCESS:
      return handleFetchBusinessTypesSuccess(state, action);
    case FETCH_BUSINESS_SIZES_SUCCESS:
      return handleFetchBusinessSizesSuccess(state, action);
    case FETCH_CURRENCIES_SUCCESS:
      return handleFetchCurrenciesSuccess(state, action);
    case FETCH_TIMEZONES_SUCCESS:
      return handleFetchTimezonesSuccess(state, action);
    case FETCH_COUNTRIES_FAILURE:
    case FETCH_TIMEZONES_FAILURE:
    case FETCH_BUSINESS_SIZES_FAILURE:
    case FETCH_BUSINESS_TYPES_FAILURE:
    case FETCH_CURRENCIES_FAILURE:
    case FETCH_COUNTRIES_FLAGS_FAILURE:
      return handleFailure(state);
    default:
      return state;
  }
};

function handleFetchCountriesSuccess(
  state: CommonState,
  action: FetchCountriesSuccessAction
): CommonState {
  return {
    ...state,
    loading: false,
    countries: action.payload.countries,
  };
}

function handleFetchCountriesExternalDataSuccess(
  state: CommonState,
  action: FetchCountriesExternalDataSuccessAction
): CommonState {
  return {
    ...state,
    loading: false,
    countriesExternalData: action.payload.countries,
  };
}

function handleFetchBusinessTypesSuccess(
  state: CommonState,
  action: FetchBusinessTypesSuccessAction
): CommonState {
  return {
    ...state,
    loading: false,
    businessTypes: action.payload.businessTypes,
  };
}

function handleFetchBusinessSizesSuccess(
  state: CommonState,
  action: FetchBusinessSizesSuccessAction
): CommonState {
  return {
    ...state,
    loading: false,
    businessSizes: action.payload.businessSizes,
  };
}

function handleFetchTimezonesSuccess(
  state: CommonState,
  action: FetchTimezonesSuccessAction
): CommonState {
  return {
    ...state,
    loading: false,
    timezones: action.payload.timezones,
  };
}

function handleFetchCurrenciesSuccess(
  state: CommonState,
  action: FetchCurrenciesSuccessAction
): CommonState {
  return {
    ...state,
    loading: false,
    currencies: action.payload.currencies,
  };
}

// Common

function handleRequest(state: CommonState): CommonState {
  return {
    ...state,
    loading: true,
  };
}

function handleFailure(state: CommonState): CommonState {
  return {
    ...state,
    loading: false,
  };
}
