import { call, put } from 'redux-saga/effects';
import { fetchVatLogsFailure, fetchVatLogsSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { FetchVatLogsRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { GetVatLogsResponse, getVatLogs } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { ERROR_HANG_TIME } from 'store/app/constants';
import { VatReturnLog } from '../models';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchVatLogsSaga(action: FetchVatLogsRequestAction) {
  try {
    let err: ApiError | null;
    const { connectionId, mappingId } = action.payload;
    const vatReturnLogs: VatReturnLog[] = [];
    for (let i = 0; i < mappingId.length; i++) {
      const response: GetVatLogsResponse = yield call(
        getVatLogs,
        connectionId,
        mappingId[i]
      );

      err = checkResponse(response?.responseMessage);
      if (err) throw err;

      const tempLogs: VatReturnLog[] = response.data.logs;
      tempLogs?.forEach((vatLog) => {
        vatLog.connectionId = connectionId;
        vatLog.mappingId = mappingId[i];
        vatReturnLogs.push(vatLog);
      });
    }
    yield put(fetchVatLogsSuccess(vatReturnLogs));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(fetchVatLogsFailure());
  }
}
