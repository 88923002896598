import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  //margin: 25px 15%;
  height: fit-content;

  width: ${(props) => (props.width ? props.width : '270px')};
  border: 1px solid #dbeae3;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.stopShadowBox ? 'none' : '0px 0px 10px 0px #0000000d'};

  .ant-switch-inner {
    background: #dbeae3 !important;
  }

  .ant-switch-handle::before {
    background: ${(props) => (props.checked ? '#41AAAF' : '#FFFFFF')}!important;
  }

  .switch-checked {
    .ant-switch-handle::before {
      background: #41aaaf !important;
    }
  }
  ${(props) =>
    props.fullSize &&
    `
          .ant-card-body {
          width:100%
      }
        `}
`;
