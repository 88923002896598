import { Row, Col, Typography } from 'antd';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

export const TermsOfUse: React.FC = () => {
  const { Text, Title } = Typography;
  const textStyle = { fontSize: '14px', fontWeight: '300' };
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Terms of use | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for terms of use" />
      </Helmet>
      <Row justify="center" style={{ backgroundColor: '#f6f6f7' }}>
        <Col
          span={15}
          style={{ backgroundColor: 'white', padding: '20px 60px' }}
        >
          <Title level={4}>Terms of Use</Title>
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            generate.TAX Service
          </Text>
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '18px', fontWeight: '300' }}>
            www.generate.tax
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            Welcome to generate.TAX, an online dashboard/platform/application
            which serves as an integration point to other accounting software
            applications hosted on the cloud. These Terms of Use are intended to
            explain Our obligations as a service provider and Your obligations
            as a customer.
            <br />
            <br />
            These Terms are binding on any use of the Service by You and apply
            to You from the time that We provide You with access to the Service.
            <br />
            <br />
            The generate.TAX Service will evolve over time based on user
            feedback. These Terms are not intended to answer every question or
            address every issue raised by the use of the generate.TAX Service.
            We reserve the right to change these terms at any time, effective
            upon the posting of modified terms on Our Website, and We will make
            every effort to notify You about these changes via email. It is
            likely that the terms of use will change over time. It is Your
            obligation to ensure that You have read, understood and agree to the
            most recent terms available.
            <br />
            <br />
            By registering to use the Service you acknowledge that You have read
            and understood these Terms and have the authority to act on behalf
            of any person for whom You are using the Service. You are deemed to
            have agreed to these Terms on behalf of any entity for whom you use
            the Service.
            <br />
            <br />
            When we say ‘Us’, ‘We’ or ‘Our’, we mean generate.TAX , having its
            registered and business office address located at Digital Hub, Malta
            Life Sciences Park, San Gwann, SGN 3000, Malta, – and any associated
            or other group companies.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            DEFINITIONS
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            <i>"Account Owner"</i> - means the person who registers to use the
            Service, and, where the context permits, includes any entity or
            person on whose behalf that person registers to use the Service.
            <br />
            <br />
            <i>"Agreement/Terms"</i> - means these Terms of Use, as subsequently
            may be amended, from time to time.
            <br />
            <br />
            <i>"Access Fee"</i> - means the monthly and any other one-off fee
            (excluding any taxes and duties) payable by You in accordance with
            the fee schedule set out on the Website (which We may change from
            time to time on notice to You).
            <br />
            <br />
            <i>"Confidential Information"</i> - includes all information of a
            confidential nature exchanged between the parties to this Agreement,
            whether in writing, electronically or orally, including the Service
            but does not include information which is, or becomes, publicly
            available other than through unauthorized disclosure by the other
            party.
            <br />
            <br />
            <i>"Data"</i> - means any data inputted by You or with Your
            authority into the Website.
            <br />
            <br />
            <i>“generate.TAX”</i> is a tradename/mark of Ours/licensed to Us.
            <br />
            <br />
            <i>"Intellectual Property Right"</i> - means any patent, trade mark,
            service mark, copyright, moral right, right in a design, know-how
            and any other intellectual or industrial property rights, anywhere
            in the world, whether registerable or not, in registered or
            unregistered form.
            <br />
            <br />
            <i>"Service/generate.TAX Service"</i> - means the SaaS by the name
            of generate.TAX, an online dashboard/platform/application that
            provides and enables online accounting and personal finance
            management services, as may be changed, modified or updated by Us,
            from time to time, and which is made available to the Account Owner
            and other Users via the Website.
            <br />
            <br />
            <i>“SaaS”</i> - means a software-as-a-service, a creation of cloud
            computing whereby software is licensed by software vendors and
            distributed on a subscription basis, and is centrally hosted.
            <br />
            <br />
            <i>“third party applications”</i> - means other SaaS or accounting
            applications hosted on the cloud, the likes of Xero accounting, for
            use in conjunction with the Service.
            <br />
            <br />
            <i>"Website"</i> - means the internet site at the domain
            www.generate.tax or any other site operated by Us.
            <br />
            <br />
            <i>"User"</i> - means any person or entity, other than the Account
            Owner, that uses the Service with the authorization of the Account
            Owner, from time to time.
            <br />
            <br />
            <i>"You"</i> - means the Account Owner, and where the context
            permits, a User. "Your" has a corresponding meaning.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            USE OF SOFTWARE
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            We grant You the right to access and use the Service via the Website
            with the particular user rights available to You according to Your
            subscription type. This right is non-exclusive, non-transferable,
            and limited by and subject to this Agreement. You acknowledge and
            agree that, subject to any applicable written agreement between the
            Account Owner and the Users, or any other applicable laws:
            <ul style={{ listStyleType: 'disc', margin: '15px' }}>
              <li>the Account Owner determines who is a User;</li>
              <li>
                the Account Owner is responsible for all Users’ use of the
                Service;
              </li>
              <li>
                the Account Owner controls each User’s level of access to the
                relevant connected organisation (as defined below) and Service
                at all times and can revoke or change a User’s access, or level
                of access, at any time and for any reason, in which case that
                person or entity will cease to be a User;
              </li>
              <li>
                if there is any dispute between a Account Owner and a User
                regarding access to any connected organisation or Service, the
                Account Owner shall decide what access or level of access to the
                relevant Data or Service that Users shall have, if any.
              </li>
            </ul>
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            YOUR OBLIGATIONS
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            <strong>Payment obligations</strong> - An invoice for the Access Fee
            will be issued at the end of each month from when You activate the
            Service. We will continue invoicing You monthly until this Agreement
            is terminated.
            <br />
            <br />
            All Our invoices will be sent to You, or to a Billing Contact whose
            details are provided by You, by email. You must pay or arrange
            payment of all amounts specified in any invoice by the due date for
            payment and are payable within 10 days of the invoice date. You are
            responsible for payment of all taxes and duties in addition to the
            Access Fee.
            <br />
            <br />
            You may accept responsibility for payment of Access Fees in relation
            to organisations or persons on whose behalf you are acting, or you
            added to the Service or that have otherwise been added with your
            authority or as a result of your use of the Service (“connected
            organisations and companies”).
            <br />
            <br />
            generate.TAX does not provide refunds.
            <br />
            <br />
            <br />
            <strong>General obligations</strong> - You must only use the Service
            and Website for Your own lawful internal business purposes, in
            accordance with these Terms and any notice sent by Us or condition
            posted on the Website. You may use the Service and Website on behalf
            of others or in order to provide services to others but if You do so
            you must ensure that You are authorized to do so and that all
            persons for whom or to whom such services are provided comply with
            and accept all terms of this Agreement that apply to You.
            <br />
            <br />
            <br />
            <strong>Access conditions</strong> - You must ensure that all
            usernames and passwords required to access the Service are kept
            secure and confidential. You must immediately notify Us of any
            unauthorized use of Your passwords or any other breach of security
            and We will reset Your password and You must take all other actions
            that We reasonably deem necessary to maintain or enhance the
            security of Our computing systems and networks and Your access to
            the Service.
            <br />
            <br />
            As a condition of these Terms, when accessing and using the Service,
            You must:
            <br />
            <ul style={{ listStyleType: 'disc', margin: '15px' }}>
              <li>
                not attempt to undermine the security or integrity of Our
                computing systems or networks or, where the Service is hosted by
                a third party, that third party's computing systems and
                networks;
              </li>
              <li>
                not use, or misuse, the Service in any way which may impair the
                functionality of the Service or Website, or other systems used
                to deliver the Service or impair the ability of any other user
                to use the Service or Website;
              </li>
              <li>
                not attempt to gain unauthorized access to any materials other
                than those to which You have been given express permission to
                access or to the computer system on which the Service is hosted;
              </li>
              <li>
                not transmit, or input into the Website, any: files that may
                damage any other person's computing devices or software, content
                that may be offensive, or material or Data in violation of any
                law (including Data or other material protected by copyright or
                trade secrets which You do not have the right to use); and
              </li>
              <li>
                not attempt to modify, copy, adapt, reproduce, disassemble,
                decompile or reverse engineer any computer programs used to
                deliver the Service or to operate the Website except as is
                strictly necessary to use either of them for normal operation.
              </li>
            </ul>
            <br />
            <br />
            <br />
            <strong>Usage Limitations</strong> - Use of the Service may be
            subject to limitations, including but not limited to client
            connections, Users’ activity and the number of calls You are
            permitted to make against generate.TAX’s application programming
            interface. Any such limitations will be specified within the
            Service.
            <br />
            <br />
            <br />
            <strong>Communication Conditions</strong> - As a condition of these
            Terms, if You use any communication tools available through the
            Website (such as any community tools), You agree only to use such
            communication tools for lawful and legitimate purposes. You must not
            use any such communication tool for posting or disseminating any
            material unrelated to the use of the Service, including (but not
            limited to): offers of goods or services for sale, unsolicited
            commercial e-mail, files that may damage any other person's
            computing devices or software, content that may be offensive to any
            other users of the Service or the Website, or material in violation
            of any law (including material that is protected by copyright or
            trade secrets which You do not have the right to use).
            <br />
            <br />
            <br />
            When You make any communication on the Website, You represent that
            You are permitted to make such communication. We are under no
            obligation to ensure that the communications on the Website are
            legitimate or that they are related only to the use of the Service.
            As with any other web-based forum, You must exercise caution when
            using the communication tools available on the Website. However, We
            do reserve the right to remove any communication at any time in its
            sole discretion.
            <br />
            <br />
            Indemnity. You indemnify Us against: all claims, costs, damage and
            loss arising from Your breach of any of these Terms or any
            obligation You may have towards Us, including (but not limited to)
            any costs relating to the recovery of any Access Fees that are due
            but have not been paid by You.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            CONFIDENTIALITY AND PRIVACY
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            <strong>Confidentiality</strong> - Unless the relevant party has the
            prior written consent of the other or unless required to do so by
            law, regulation, or regulatory or supervisory authority or court
            order:
            <br />
            <br />
            Each party will preserve the confidentiality of all Confidential
            Information of the other obtained in connection with these Terms.
            Neither party will, without the prior written consent of the other,
            disclose or make any Confidential Information available to any
            person, or use the same for its own benefit, other than as
            contemplated by these Terms.
            <br />
            <br />
            Each party's obligations under this clause will survive termination
            of these Terms.
            <br />
            <br />
            These provisions shall not apply to any information which:
            <br />
            <ul style={{ listStyleType: 'disc', margin: '15px' }}>
              <li>
                is or becomes public knowledge other than by a breach of this
                clause;
              </li>
              <li>
                is received from a third party who lawfully acquired it and who
                is under no obligation restricting its disclosure;
              </li>
              <li>
                is in the possession of the receiving party without restriction
                in relation to disclosure before the date of receipt from the
                disclosing party; or
              </li>
              <li>
                is independently developed without access to the Confidential
                Information.
              </li>
            </ul>
            <br />
            <br />
            <strong>Privacy</strong> - We maintain a Privacy Policy, as last
            updated, that further sets out the parties’ obligations in respect
            of personal information processed by Us on/via the generate.TAX
            online dashboard/platform/application as a result of your use of the
            Service. You should read that policy and You will be taken to have
            accepted that policy when You accept these Terms. You are also
            invited to read our Data Processing Addendum relating to the
            processing of personal data in accordance with applicable data
            protection legislation (as defined therein) which Addendum is also
            deemed to be supplemental to and forms an integral part of these
            Terms.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            INTELLECTUAL PROPERTY
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            <strong>General</strong> - Title to, and all Intellectual Property
            Rights in the Service, the Website and any documentation relating to
            the Service remain Our property (or of Our licensors).
            <br />
            <br />
            <br />
            <strong>Ownership of Data</strong> - Title to, and all Intellectual
            Property Rights in, the Data remain Your property. However, Your
            access to the Data is contingent on full payment of the generate.TAX
            Access Fee when due. You grant Us a licence to use, copy, transmit,
            store, back-up and otherwise process Your information and Data only
            for the purposes of enabling You to access and use the Service and
            for any other purpose related to provision of the Service to You.
            <br />
            <br />
            <br />
            <strong>Backup of Data</strong> - You must maintain copies of all
            Data inputted into the Service. We adheres to its best practice
            policies and procedures to prevent data loss, including a daily
            system data back-up regime, but does not make any guarantees that
            there will be no loss of Data. We expressly exclude liability for
            any loss of Data no matter how caused to the extent that this is
            possible under applicable data protection legislation.
            <br />
            <br />
            <br />
            <strong>Third-party applications and your Data</strong> - If You
            enable third-party applications for use in conjunction with the
            Service, You acknowledge that We may allow the providers or
            facilitators of those third-party applications to access Your Data
            as required for the interoperation of such third-party applications
            with the Service. We shall not be responsible for any disclosure,
            modification, deletion or other processing activity of/in relation
            to Your Data resulting from any such access by third-party
            application providers.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            WARRANTIES AND ACKNOWLEDGEMENTS
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            <strong>Authority</strong> - You warrant that where You have
            registered to use the Service on behalf of another person, You have
            the authority to agree to these Terms on behalf of that person and
            agree that by registering to use the Service You bind the person on
            whose behalf You act to the performance of any and all obligations
            that You become subject to by virtue of these Terms, without
            limiting Your own personal obligations under these Terms.
            <br />
            <br />
            <br />
            <strong>Acknowledgement</strong> - You acknowledge that:
            <br />
            <ol style={{ marginLeft: '15px' }}>
              <li>
                You are authorized to use the Service and the Website and to
                access the information and Data that You input into the Website,
                including any information or Data input into the Website by any
                person you have authorized to use the Service. You are also
                authorized to access the processed information and Data that is
                made available to You through Your use of the Website and the
                Service (whether that information and Data is Your own or that
                of anyone else).
              </li>
              <li>
                We have no responsibility to any person other than You and
                nothing in this Agreement confers, or purports to confer, a
                benefit on any person other than You. If You use the Service or
                access the Website on behalf of or for the benefit of anyone
                other than yourself (whether a body corporate or otherwise) you
                agree that:
                <ol style={{ marginLeft: '15px' }}>
                  <li>
                    You are responsible for ensuring that You have the right to
                    do so;
                  </li>
                  <li>
                    You are responsible for authorizing any person who is given
                    access to information or Data, and you agree that We have no
                    obligation to provide any person access to such information
                    or Data without Your authorization and may refer any
                    requests for information to You to address; and
                  </li>
                </ol>
              </li>
              <li>
                You will indemnify Us against any claims or loss relating to:
                <ol style={{ marginLeft: '15px' }}>
                  <li>
                    Our refusal to provide any person access to Your information
                    or Data in accordance with these Terms,
                  </li>
                  <li>
                    Our making available information or Data to any person with
                    Your authorisation.
                  </li>
                </ol>
              </li>
              <li>
                The provision of, access to, and use of, the Service is on an
                "as is" basis and at Your own risk.
              </li>
              <li>
                We do not warrant that the use of the Service will be
                uninterrupted or error free. Among other things, the operation
                and availability of the systems used for accessing the Service,
                including public telephone services, computer networks and the
                internet, can be unpredictable and may from time to time
                interfere with or prevent access to the Service. We are not in
                any way responsible for any such interference or prevention of
                Your access or use of the Service.
              </li>
              <li>
                We are not Your accountant and use of the Service does not
                constitute the receipt of accounting advice. If You have any
                accounting questions, please contact an accountant.
              </li>
              <li>
                It is Your sole responsibility to determine that the Service
                meets the needs of Your business and are suitable for the
                purposes for which they are used.
              </li>
              <li>
                You remain solely responsible for complying with all applicable
                accounting, tax and other laws. It is Your responsibility to
                check that storage of and access to your Data via the software
                and the Website will comply with laws applicable to you
                (including any laws requiring you to retain records).
              </li>
            </ol>
            <br />
            <br />
            <br />
            <strong>No warranties</strong> - We give no warranty about the
            Service. Without limiting the foregoing, We do not warrant that the
            Service will meet Your requirements or that it will be suitable for
            any particular purpose. To avoid doubt, all implied conditions or
            warranties are excluded in so far as is permitted by law, including
            (without limitation) warranties of merchantability, fitness for
            purpose, title and non-infringement.
            <br />
            <br />
            <br />
            <strong>Consumer guarantees</strong> - You warrant and represent
            that You are acquiring the right to access and use the Service for
            the purposes of a business and that, to the maximum extent permitted
            by law, any statutory consumer guarantees or legislation intended to
            protect non-business consumers in any jurisdiction does not apply to
            the supply of the Service, the Website or these Terms.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            LIMITATION OF LIABILITY
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            To the maximum extent permitted by law, We exclude all liability and
            responsibility to You (or any other person) in contract, tort
            (including negligence), or otherwise, for any loss (including loss
            of information, Data, profits and savings) or damage resulting,
            directly or indirectly, from any use of, or reliance on, the Service
            or Website.
            <br />
            <br />
            If You suffer loss or damage as a result of Our negligence or
            failure to comply with these Terms, any claim by You against Us
            arising from Our negligence or failure will be limited in respect of
            any one incident, or series of connected incidents, to the Access
            Fees paid by You in the previous 12 months.
            <br />
            <br />
            If You are not satisfied with the Service, Your sole and exclusive
            remedy is to terminate these Terms.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            TERMINATION
          </Text>
          <br />
          <br />
          <Text style={textStyle}>
            <strong>Trial policy</strong> - When You first sign up for access to
            the Service You can evaluate the Service under the defined trial
            usage conditions, with no obligation to continue to use the Service.
            If You choose to continue using the Service thereafter, You will be
            billed from the month You first added Your billing details into the
            Service. If You choose not to continue using the Service, You may do
            nothing and your account details will be dormant until you decide to
            continue using the Service or until you decide to cancel your
            account.
            <br />
            <br />
            <br />
            <strong>Prepaid Subscriptions and ther services</strong> - We will
            not provide any refund for any remaining prepaid period for a
            prepaid Access Fee subscription or any other service provided to
            You.
            <br />
            <br />
            <br />
            <strong>No-fault termination</strong> - These Terms will continue
            for the period covered by the Access Fee, unless either party
            terminates these Terms by giving the other at least 30 days written
            notice. If You terminate these Terms You shall be liable to pay all
            relevant Access Fees until the end of the month in which notice was
            given.
            <br />
            <br />
            <br />
            <strong>Breach</strong> - If You:
            <br />
            <ol style={{ marginLeft: '15px' }}>
              <li>
                breach any of these Terms (including, without limitation, by
                non-payment of any Access Fees) and do not remedy the breach
                within 14 days after receiving notice of the breach if the
                breach is capable of being remedied;
              </li>
              <li>
                breach any of these Terms and the breach is not capable of being
                remedied (which includes (without limitation) any breach of
                these Terms or any payment of Access Fees that are more than 30
                days overdue); or
              </li>
              <li>
                You or Your business become insolvent or Your business goes into
                liquidation or has a receiver or manager appointed of any of its
                assets or if You become insolvent, or make any arrangement with
                Your creditors, or become subject to any similar insolvency
                event in any jurisdiction;
              </li>
            </ol>
            <br />
            <br />
            We may take any or all of the following actions, at its sole
            discretion:
            <br />
            <ol style={{ marginLeft: '15px' }}>
              <li>
                Terminate this Agreement and Your use of the Service and the
                Website;
              </li>
              <li>
                Suspend for any definite or indefinite period of time, Your use
                of the Service and the Website;
              </li>
              <li>Suspend or terminate access to all or any Data;</li>
              <li>
                Take either of the actions of this clause in respect of any or
                all other persons whom You have authorized to have access to
                Your information or Data.
              </li>
            </ol>
            <br />
            <br />
            For the avoidance of doubt, if payment of any invoice for Access
            Fees due in relation to any of Your billing contacts, billing plans
            or any of Your connected organisations is not made in full by the
            relevant due date, We may: suspend or terminate Your use of the
            Service, the authority for all or any of Your connected
            organisations to use the Service, or Your rights of access to all or
            any Data.
            <br />
            <br />
            <br />
            <strong>Accrued Rights and Survival of Clause</strong> - Termination
            of these Terms is without prejudice to any rights and obligations of
            the parties accrued up to and including the date of termination and
            without prejudice to the survival of specific clauses which,
            expressly, or, by their nature, are intended to survive the
            expiration/termination of this Agreement, including, inter alia,
            provisions relating to payment obligations, confidentiality
            obligations, limitation of liability provisions, provisions
            regulating intellectual property rights, warranties and
            acknowledgements, and so on. On termination of this Agreement You
            will:
            <br />
            <ol style={{ marginLeft: '15px' }}>
              <li>
                remain liable for any accrued charges and amounts which become
                due for payment before or after termination; and
              </li>
              <li>immediately cease to use the Service and the Website.</li>
            </ol>
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>HELP DESK</Text>
          <br />
          <br />
          <Text style={textStyle}>
            <strong>Technical Problems</strong> - In the case of technical
            problems You must make all reasonable efforts to investigate and
            diagnose problems before contacting Us. If You still need technical
            help, please check the support provided online by Us on the Website
            or failing that email us at helpme@generate.tax.
            <br />
            <br />
            <br />
            <strong>Service availability</strong> - Whilst We intend for the
            Service to be available 24 hours a day, seven days a week, it is
            possible that on occasions the Service or Website may be unavailable
            to permit maintenance or other development activity to take place.
            <br />
            <br />
            If for any reason We have to interrupt the Service for longer
            periods than We would normally expect, We will use reasonable
            endeavors to publish in advance details of such activity on the
            Website.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text style={{ fontSize: '20px', fontWeight: '300' }}>GENERAL</Text>
          <br />
          <br />
          <Text style={textStyle}>
            <strong>Entire agreement</strong> - These Terms, together with the
            generate.TAX Privacy Policy and other policies or notices given to
            You under these Terms of Use or otherwise published on the Website,
            supersede and extinguish all prior agreements, representations
            (whether oral or written), and understandings and constitute the
            entire agreement between You and Us relating to the use of the
            Service and the other matters dealt with in these Terms.
            <br />
            <br />
            <br />
            <strong>Waiver</strong> - If either party waives any breach of these
            Terms, this will not constitute a waiver of any other breach. No
            waiver will be effective unless made in writing.
            <br />
            <br />
            <br />
            <strong>Delays</strong> - Neither party will be liable for any delay
            or failure in performance of its obligations under these Terms if
            the delay or failure is due to any cause outside its reasonable
            control.
            <br />
            <br />
            <br />
            <strong>No Assignment</strong> - You may not assign or transfer any
            rights to any other person without Our prior written consent.
            <br />
            <br />
            <br />
            <strong>Governing law and dispute resolution</strong> - This
            Agreement and any dispute or claim arising out of or in connection
            with this Contract shall be governed by and shall be construed and
            interpreted in accordance with Maltese law. In case of a dispute or
            breach arising out of or in connection with the interpretation
            and/or execution of this Agreement, You may refer the matter to
            arbitration under the rules of the Arbitration Act, Cap 387 of the
            Laws of Malta. Arbitration proceedings shall be conducted at the
            Malta Arbitration Centre in Malta.
            <br />
            <br />
            <br />
            <strong>Severability</strong> - If any part or provision of these
            Terms is invalid, unenforceable or in conflict with the law, that
            part or provision is replaced with a provision which, as far as
            possible, accomplishes the original purpose of that part or
            provision. The remainder of this Agreement will be binding on the
            parties.
            <br />
            <br />
            <br />
            <strong>Notices</strong> - Any notice given under these Terms by
            either party to the other must be in writing by email and will be
            deemed to have been given on transmission. Notices to Us relating to
            the use of the Service or other matters dealt with under these Terms
            must be sent to helpme@generate.tax or to any other email address
            notified by email to You by Us. Notices to You will be sent to the
            email address which You provided when setting up Your access to the
            Service.
            <br />
            <br />
            <br />
            <strong>Rights of Third Parties</strong> - A person who is not a
            party to these Terms has no right to benefit under or to enforce any
            term of these Terms.
            <br />
            <br />
            The Terms shall apply unless otherwise altered by mutual agreement
            between the parties.
          </Text>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Text style={textStyle}>
            <i>These Terms were last updated on 29th September 2022.</i>
          </Text>
          <br />
          <br />
        </Col>
      </Row>
    </React.Fragment>
  );
};
