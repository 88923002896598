import {
  ACTIVATE_ACCOUNT_FAILURE,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_SUCCESS,
  CONFIRM_USER_FAILURE,
  CONFIRM_USER_REQUEST,
  CONFIRM_USER_SUCCESS,
  PROVIDER_SIGNUP_FAILURE,
  PROVIDER_SIGNUP_REQUEST,
  PROVIDER_SIGNUP_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESEND_ACTIVATION_FAILURE,
  RESEND_ACTIVATION_REQUEST,
  RESEND_ACTIVATION_SUCCESS,
} from './constants';
import { ConfirmUserSuccessAction, SignUpAction, SignUpState } from './types';

const initialState: SignUpState = {
  loading: false,
  userId: null,
  userActivated: false,
  providerUserData: null,
};

export const signUpReducer = (
  state = initialState,
  action: SignUpAction
): SignUpState => {
  switch (action.type) {
    case REGISTER_REQUEST:
    case ACTIVATE_ACCOUNT_REQUEST:
    case RESEND_ACTIVATION_REQUEST:
    case PROVIDER_SIGNUP_REQUEST:
      return handleRequest(state);
    case REGISTER_SUCCESS:
    case ACTIVATE_ACCOUNT_SUCCESS:
    case RESEND_ACTIVATION_SUCCESS:
    case PROVIDER_SIGNUP_SUCCESS:
      return handleSuccess(state);
    case CONFIRM_USER_FAILURE:
    case REGISTER_FAILURE:
    case ACTIVATE_ACCOUNT_FAILURE:
    case RESEND_ACTIVATION_FAILURE:
    case PROVIDER_SIGNUP_FAILURE:
      return handleFailure(state);
    case CONFIRM_USER_REQUEST:
      return handleConfirmUserRequest(state);
    case CONFIRM_USER_SUCCESS:
      return handleConfirmUserSuccess(state, action);
    default:
      return state;
  }
};

// confirm user
function handleConfirmUserRequest(state: SignUpState): SignUpState {
  return {
    ...state,
    loading: true,
    userId: null,
  };
}

function handleConfirmUserSuccess(
  state: SignUpState,
  action: ConfirmUserSuccessAction
): SignUpState {
  return {
    ...state,
    loading: false,
    userId: action.payload.uuid,
    userActivated: action.payload.userActivated,
  };
}

//// COMMON
function handleSuccess(state: SignUpState): SignUpState {
  return {
    ...state,
    loading: false,
  };
}

function handleRequest(state: SignUpState): SignUpState {
  return {
    ...state,
    loading: true,
  };
}

function handleFailure(state: SignUpState): SignUpState {
  return {
    ...state,
    loading: false,
  };
}
