import { ValidateUserForm } from 'components/SignUpForm/validate';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  resendActivationRequest,
  activateAccounRequest,
  confirmUserRequest,
} from 'store/signup/actions';
import { UserDetails } from 'store/signup/models';
import { getSignUpLoading, getSignUpUserId } from 'store/signup/selectors';

import routes from 'routes';
import jstz from 'jstz';

import { resetPasswordEmailRequest } from 'store/auth/actions';

export const ValidateUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const resetPassword = location.pathname.includes(routes.resetPassword.root);
  const userId = useSelector(getSignUpUserId);
  const loading = useSelector(getSignUpLoading);
  const userExists = !!userId;
  const [showPage, setShowPage] = React.useState(false);

  const onActivate = (password: string) => {
    if (userId) {
      const timeZone = jstz.determine().name();
      const params = {
        password,
        uuid: userId,
        timeZone,
      } as UserDetails;

      dispatch(activateAccounRequest(params, resetPassword));
    }
  };

  const onResend = (email: string) => {
    if (resetPassword) {
      const user = { forgotEmail: email } as UserDetails;
      dispatch(resetPasswordEmailRequest(user));
    } else {
      dispatch(resendActivationRequest(email));
    }
  };

  useEffect(() => {
    dispatch(confirmUserRequest(id, resetPassword));
    setShowPage(true);
  }, []);

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`${resetPassword ? 'Reset password' : 'Activate'} | ${
              process.env.PROJECT_NAME
            }`}</title>
            <meta
              name="description"
              content="This a page for activating your account"
            />
          </Helmet>

          <ValidateUserForm
            loading={loading}
            userExists={userExists}
            activate={onActivate}
            resend={onResend}
            resetPassword={resetPassword}
          />
        </>
      )}
    </React.Fragment>
  );
};

export { ValidateUserForm as ResetPasswordConfirmForm };
