import { takeLatest } from 'redux-saga/effects';
import {
  FETCH_ME_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  RESET_PASSWORD_EMAIL_REQUEST,
} from '../constants';
import { fetchMeSaga } from './fetchMeSaga';
import { loginSaga } from './loginSaga';
import { logoutSaga } from './logoutSaga';
import { resetPasswordEmailSaga } from './resetPasswordEmailSaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* authSaga() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
  yield takeLatest(FETCH_ME_REQUEST, fetchMeSaga);
  yield takeLatest(LOGOUT_REQUEST, logoutSaga);
  yield takeLatest(RESET_PASSWORD_EMAIL_REQUEST, resetPasswordEmailSaga);
}
