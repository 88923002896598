import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import routes from 'routes';
import { setRefreshDone } from 'store/app/actions';
import { MenuSize } from 'store/app/enums';
import { getRefreshDone } from 'store/app/selectors';
import { fetchMeRequest } from 'store/auth/actions';
import { getAuthUser } from 'store/auth/selectors';
import { fetchOrganisationsRequest } from 'store/organisation/actions';
import { getOrgOrganisations } from 'store/organisation/selectors';
import { fetchUsersRequest } from 'store/user/actions';
import { getUserMembers } from 'store/user/selectors';

interface LayoutProps {
  menuSize: MenuSize;
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ menuSize, children }) => {
  const loggedUser = useSelector(getAuthUser);
  const refreshDone = useSelector(getRefreshDone);
  const organisations = useSelector(getOrgOrganisations);
  const members = useSelector(getUserMembers);

  const dispatch = useDispatch();
  const location = useLocation();
  const loggedIn =
    !location.pathname.includes(routes.signUp.root) &&
    !location.pathname.includes(routes.resetPassword.root) &&
    !location.pathname.includes(routes.termsOfUse) &&
    !location.pathname.includes(routes.privacyPolicy) &&
    // !location.pathname.includes(routes.login) &&
    !location.pathname.includes(routes.notFound); //&&

  //!location.pathname.includes(routes.home);
  React.useEffect(() => {
    if (!location.pathname.includes('redirector')) {
      if (refreshDone) {
        dispatch(
          push(
            routes.tempRedirector.replace(
              ':route',
              location.pathname.replace(/\//g, '<slash>')
            )
          )
        );
        dispatch(setRefreshDone());
      }
      // ! FOR LOADING DETAILS ON FIRST CALL
      if (loggedIn) {
        dispatch(fetchMeRequest());
      }
    }
  }, []);

  React.useEffect(() => {
    if (loggedUser) {
      if (!organisations) {
        dispatch(fetchOrganisationsRequest());
      }
      if (!members) {
        dispatch(fetchUsersRequest());
      }
    }
  }, [loggedUser]);

  const layoutClass =
    !loggedIn || location.pathname.includes(routes.login)
      ? 'layout'
      : menuSize === MenuSize.WIDE
      ? 'layout-narrow'
      : 'layout-wide';
  return <div className={layoutClass}>{children}</div>;
};
