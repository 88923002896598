import { Button, Col, Divider, Form, Input, Radio, Row } from 'antd';
import { NewMember } from 'pages/users';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LabelWrapper } from 'components/Custom/labels';
import { FormInput } from 'components/Custom/inputs';
import { ScopeButton } from 'components/Custom/buttons';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}
interface AddMemberFormProps {
  saveNewMember: (newMember: NewMember) => void;
}

export const AddMemberForm: React.FC<AddMemberFormProps> = ({
  saveNewMember,
}) => {
  const { t } = useTranslation();
  const [isClient, setIsClient] = React.useState<boolean>(false);

  const handleSubmit = (values: FormValues) => {
    const newMember: NewMember = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      isClient,
    };
    saveNewMember(newMember);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 5 }}
      //wrapperCol={{ span: 14 }}
      //form={form}
      autoComplete="off"
      onFinish={handleSubmit}
    >
      <Row justify={'center'}>
        <Col span={24}>
          <Radio.Group
            defaultValue={isClient}
            buttonStyle="solid"
            onChange={() => setIsClient(!isClient)}
          >
            <Radio.Button value={false}>{t('users.user')}</Radio.Button>
            <Radio.Button value={true}>{t('users.client')}</Radio.Button>
          </Radio.Group>
        </Col>
        <Divider />
        <Col span={24}>
          <LabelWrapper>
            <span className="label-text">{t('signup.first-name')}</span>
          </LabelWrapper>
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: t('signup.required-field') }]}
          >
            <FormInput width="100%" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <LabelWrapper>
            <span className="label-text">{t('signup.last-name')}</span>
          </LabelWrapper>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: t('signup.required-field') }]}
          >
            <FormInput width="100%" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <LabelWrapper>
            <span className="label-text">{t('signup.email')}</span>
          </LabelWrapper>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('signup.required-field') },
              {
                type: 'email',
                message: t('signup.invalid-email'),
              },
            ]}
          >
            <FormInput width="100%" />
          </Form.Item>
        </Col>
        <Form.Item>
          <ScopeButton type="primary" htmlType="submit">
            {t('users.send-invitation')}
          </ScopeButton>
        </Form.Item>
      </Row>
    </Form>
  );
};
