import { Form, Typography } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { FormTextArea } from 'components/Custom/inputs';
import { LabelWrapper } from 'components/Custom/labels';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { turnDrawerOff } from 'store/app/actions';
import { EmailDataParametersRequest } from 'store/vat/models/emailDataParametersRequest';

const { Text } = Typography;

interface NotApproveFormProps {
  setNotApproved: (values: EmailDataParametersRequest) => void;
}

export const NotApproveForm: React.FC<NotApproveFormProps> = ({
  setNotApproved,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (values: any) => {
    const formValues = {
      ...values,
    } as EmailDataParametersRequest;

    setNotApproved(formValues);
    dispatch(turnDrawerOff());
  };

  return (
    <>
      <Text
        style={{
          fontSize: '18px',
          fontWeight: '400',
          lineHeight: '24px',
          textAlign: 'left',
          color: '#879494',
          marginBottom: '40px',
        }}
      >
        Please leave a note to the preparer.
      </Text>
      <Form
        layout="horizontal"
        style={{
          marginTop: '40px',
        }}
        key={Date()}
        onFinish={handleSubmit}
      >
        <LabelWrapper>
          <span
            className="label-text"
            style={{ fontSize: 14, fontWeight: 400 }}
          >
            {t('common.note')}
          </span>
        </LabelWrapper>
        <Form.Item
          name="note"
          rules={[{ required: true, message: t('signup.required-field') }]}
        >
          <FormTextArea rows={5} placeholder={t('common.enter-your-note')} />
        </Form.Item>
        <Form.Item>
          <ScopeButton //@ts-ignore
            width="100%"
            height="40px"
            type="primary"
            htmlType="submit"
          >
            {t('common.return-to-preparer')}
          </ScopeButton>
          <div style={{ marginTop: '20px' }}>
            <ScopeButton //@ts-ignore
              width="100%"
              height="40px"
              bg_color="#E9F6F7"
              type={'default'}
              onClick={() => dispatch(turnDrawerOff())}
            >
              {t('common.cancel')}
            </ScopeButton>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
