import { call, put } from 'redux-saga/effects';
import {
  disconnectProviderFailure,
  disconnectProviderSuccess,
} from '../actions';
import { disconnectProvider } from '../api';
import { DisconnectProviderRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { fetchMeRequest } from 'store/auth/actions';
import { ERROR_HANG_TIME } from 'store/app/constants';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* disconnectAccountSaga(
  action: DisconnectProviderRequestAction
) {
  try {
    let err: ApiError | null;

    const { provider } = action.payload;
    const response: ApiResponse = yield call(disconnectProvider, provider);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: `Disconnected !`,
      description: `${provider} disconnected successfully.`,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(disconnectProviderSuccess());
    yield put(fetchMeRequest());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(disconnectProviderFailure());
  }
}
