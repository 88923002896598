import { Typography } from 'antd';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

export const BillingPage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Billing | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This is a page for billing" />
      </Helmet>
      <Typography.Title level={3}>Billing Page</Typography.Title>
    </React.Fragment>
  );
};
