import * as React from 'react';
import { ViesStatus, ViesTriggerType } from 'store/app/enums';
import moment from 'moment';
import { StatusGetter } from 'utils/status-getter';
import { Contact, ViesCheck } from 'store/vat-checker/models';
import { ActivityLogsWrapper } from 'components/Custom/wrappers';
import { RoundInput } from 'components/Custom/inputs';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/Custom/buttons';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/downloadIcon.svg';
import { Space, Tooltip } from 'antd';
import { CSVLink } from 'react-csv';
import { Provider } from 'store/api/enums';
import { getInvoiceLink } from 'utils/get-invoice-link';

interface ViesLogsProps {
  viesLogs?: ViesCheck[];
  currentContact?: Contact;
  openDownloadCertificateModal: (contact: Contact, invoice: string) => void;
  providerName?: string;
  closeDrawer: () => void;
}

export const ViesLogs: React.FC<ViesLogsProps> = ({
  viesLogs,
  currentContact,
  openDownloadCertificateModal,
  providerName,
  closeDrawer,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [logsList, setLogsList] = React.useState<ViesCheck[] | undefined>([]);

  const getHeaderForExport = (dataToExport) => {
    const obj = dataToExport?.length > 0 ? dataToExport[0] : null;
    let headerArr: any[] = [];
    if (obj) {
      const headers = Object.keys(obj);
      headerArr = headers.map((key) => ({
        label: key.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
        key: key,
      }));
    }

    return headerArr;
  };

  const getDataForExport = (viesLogs: ViesCheck[]) => {
    const dataToExport = [...viesLogs];
    let dataArr: any[] = []; //@ts-ignore
    dataArr =
      dataToExport?.length &&
      dataToExport?.map((log) => ({
        triggerType: ViesTriggerType[log.triggerType],
        viesCheckedDate: moment(log.viesCheckedDate).format(
          'DD/MM/YYYY - hh:mm:ss'
        ),
        viesStatus: ViesStatus[log.viesStatus],
        triggerInvoiceId: log.triggerInvoiceId,
      }));

    return dataArr;
  };

  React.useEffect(() => {
    setLogsList(viesLogs);
  }, [viesLogs]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == '') {
      setLogsList(viesLogs);
    }
  };

  const handleSearch = (_e) => {
    if (searchValue != '') {
      const filteredLogs = viesLogs?.filter((log) =>
        Object.keys(log).some((k) => {
          if (k == 'viesCheckedDate') {
            return (
              moment(searchValue.toLowerCase()).isValid() &&
              String(log[k]).includes(
                moment(searchValue.toLowerCase(), ['DD/MM/YYYY']).format(
                  'YYYY-MM-DD'
                )
              )
            );
          } else if (k == 'triggerType') {
            return ViesTriggerType[log?.triggerType]
              ?.toLowerCase()
              .includes(searchValue.toLowerCase());
          } else if (k == 'viesStatus') {
            return ViesStatus[log?.viesStatus]
              ?.toLowerCase()
              .includes(searchValue.toLowerCase());
          } else {
            return String(log[k])
              .toLowerCase()
              .includes(searchValue.toLowerCase());
          }
        })
      );
      setLogsList(filteredLogs);
    } else {
      setLogsList(viesLogs);
    }
  };

  return (
    <ActivityLogsWrapper>
      <RoundInput
        width="330px"
        height="45px" //@ts-ignore
        bg_color="white"
        size="large"
        style={{ marginBottom: '20px' }}
        value={searchValue}
        placeholder={`${t('common.search')} ${t('vat-checker.history-log')}`}
        onChange={handleChange}
        suffix={
          <SearchOutlined
            width={33}
            height={33}
            style={{
              backgroundColor: '#41AAAF',
              color: 'white',
              padding: '8px',
              borderRadius: '50px',
              fontSize: '16px',
              cursor: 'pointer',
            }}
            onClick={handleSearch}
          />
        }
      />
      <IconButton
        style={{ marginBottom: '10px' }}
        // onClick={() => {
        //   const contact = currentContact;
        //   contact.lastViesCheck = vl.viesCheckedDate;
        //   openDownloadCertificateModal(contact);
        // }}
        icon={
          <DownloadIcon
            width={'12px'}
            height={'12px'}
            /*             style={{
              filter:
                'brightness(0) saturate(100%) invert(62%) sepia(19%) saturate(146%) hue-rotate(131deg) brightness(90%) contrast(88%)',
            }} */
          />
        }
        type="default"
      >
        <CSVLink
          filename={`${t('vat-checker.history-log')}.csv`}
          data={viesLogs && getDataForExport(viesLogs)}
          headers={getHeaderForExport(viesLogs)}
        >
          Download Log in Excel
        </CSVLink>
      </IconButton>
      <div className={'scroller-activity'}>
        {logsList?.map((vl, index) => {
          return (
            <div key={index} className="vies-logs-card">
              <div className="date-row">
                <span className="title-date">
                  {moment(vl.viesCheckedDate).format('DD/MM/YYYY - hh:mm:ss')}
                </span>
                <div style={{ alignSelf: 'end', marginBottom: 4 }}>
                  <Space>
                    {ViesStatus[vl.viesStatus] == ViesStatus.VALID && (
                      <IconButton
                        style={{
                          width: 25,
                          height: 25,
                        }}
                        onClick={() => {
                          const contact = currentContact;
                          let invoice = '';
                          if (contact) {
                            if (vl.invoiceNumber && vl.triggerInvoiceId) {
                              if (providerName === Provider.CIN7)
                                invoice = `Cin7 ${vl.invoiceNumber}`;
                              if (providerName === Provider.XERO)
                                invoice = `Xero ${vl.invoiceNumber}`;
                            }
                            contact.lastViesCheck = vl.viesCheckedDate;
                            openDownloadCertificateModal(contact, invoice);
                            closeDrawer();
                          }
                        }}
                        icon={
                          <DownloadIcon
                            width={'12px'}
                            height={'12px'}
                            /*                         style={{
                          filter:
                            'brightness(0) saturate(100%) invert(62%) sepia(19%) saturate(146%) hue-rotate(131deg) brightness(90%) contrast(88%)',
                        }} */
                          />
                        }
                        type="default"
                      ></IconButton>
                    )}
                    <StatusGetter //@ts-ignore
                      style={{ marginRight: 0 }}
                      status={
                        vl.viesStatus
                          ? ViesStatus[vl.viesStatus]
                          : vl.viesStatus
                      }
                      smaller
                    />
                  </Space>
                </div>
              </div>
              <div className="invoice-line">
                <div>{ViesTriggerType[vl.triggerType]}</div>
                {vl.invoiceNumber && vl.triggerInvoiceId && (
                  <Space className="invoice-box">
                    <Tooltip title="Click to view the invoice">
                      <a
                        href={
                          providerName &&
                          getInvoiceLink(vl.triggerInvoiceId, providerName)
                        }
                        target="_blank"
                        rel="noreferrer"
                        //style={{ height: '32px', display: 'block' }}
                      >
                        <img
                          src={
                            providerName
                              ? require(`shared/assets/images/${providerName.toLowerCase()}_small.png`)
                                  .default
                              : ''
                          }
                          width={20}
                          style={{ paddingTop: '4px' }}
                        />
                      </a>
                    </Tooltip>
                    <span>{vl.invoiceNumber}</span>
                  </Space>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </ActivityLogsWrapper>
  );
};
