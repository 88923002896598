import { Space } from 'antd';
import * as React from 'react';
import { BlackSpan, GraySpan } from 'components/Custom/spans';

interface CardInfosProps {
  label: string;
  data: any;
  wrapData?: boolean;
  haveBorder?: boolean;
}
export const CardInfo: React.FC<CardInfosProps> = ({
  data,
  label,
  wrapData,
  haveBorder,
}) => {
  return (
    <div
      style={
        haveBorder
          ? {
              borderLeft: '1px solid #41AAAF',
              paddingLeft: 10,
              minWidth: '160px',
              maxWidth: '160px',
              //width: '200px',
            }
          : {}
      }
    >
      <Space direction="vertical" style={{ rowGap: 0 }}>
        <GraySpan>{label}</GraySpan>
        <div style={wrapData ? { width: 134 } : {}}>
          <BlackSpan>{data}</BlackSpan>
        </div>
      </Space>
    </div>
  );
};
