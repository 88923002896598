import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { ReturnState } from './types';

const selectSignUp = (app: StoreState): ReturnState => app.return;

export const getReturnLoading = createSelector(
  selectSignUp,
  (returns: ReturnState): ReturnState['loading'] => returns.loading
);

export const getReturnPreLoading = createSelector(
  selectSignUp,
  (returns: ReturnState): ReturnState['preLoading'] => returns.preLoading
);

export const getReturnTaxRatesLoading = createSelector(
  selectSignUp,
  (returns: ReturnState): ReturnState['taxRatesLoading'] =>
    returns.taxRatesLoading
);

export const getReturnData = createSelector(
  selectSignUp,
  (returns: ReturnState): ReturnState['returnData'] => returns.returnData
);
