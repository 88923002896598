export const FETCH_ADD_RETURN_DATA_REQUEST =
  'returns/fetch-add-return-data-request';
export const FETCH_ADD_RETURN_DATA_PRE_SUCCESS =
  'returns/fetch-add-return-data-pre-success';
export const FETCH_ADD_RETURN_DATA_RATES_SUCCESS =
  'returns/fetch-add-return-data-rates-success';
export const FETCH_ADD_RETURN_DATA_POST_SUCCESS =
  'returns/fetch-add-return-data-post-success';
export const FETCH_ADD_RETURN_DATA_FAILURE =
  'returns/fetch-add-return-data-failure';
export const SAVE_MAPPING_REQUEST = 'returns/save-mapping-request';
export const SAVE_MAPPING_SUCCESS = 'returns/save-mapping-success';
export const SAVE_MAPPING_FAILURE = 'returns/save-mapping-failure';
export const DELETE_MAPPING_REQUEST = 'returns/delete-mapping-request';
export const DELETE_MAPPING_SUCCESS = 'returns/delete-mapping-success';
export const DELETE_MAPPING_FAILURE = 'returns/delete-mapping-failure';

export const RESET_RETURN_DATA = 'returns/reset-return-data';
