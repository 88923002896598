import { notification } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { turnMessageOff } from 'store/app/actions';
import { getMessage, getShowMessage } from 'store/app/selectors';
import styled from 'styled-components';

interface EmptyPageProps {
  titleText: string;
  actionText: string;
  messageOnly?: boolean;
  buttonText?: string;
  actionCallback?: () => void;
  disableButton?: boolean;
}

const EmptyPageWrapper = styled.div`
  .page-props {
    //width: 851px;
    height: 370px;
    border-radius: 10px;
    background: var(--White, #ffffff);
    border: 1px dashed #41aaaf;
    box-shadow: 0px 4px 10px 0px #0000000d;
    //margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    color: #042a2a;
  }
  .page-message {
    height: 150px;
  }

  .title-text {
    font-size: 24px;
    font-weight: 400;
  }

  .action-text {
    font-size: 16px;
    font-weight: 300;
    margin-top: 10px;
  }

  .action-button {
    margin-top: 40px;
    min-width: 200px;
  }
`;
export const EmptyPage: React.FC<EmptyPageProps> = ({
  titleText,
  actionText,
  messageOnly,
  buttonText,
  actionCallback,
  disableButton,
}) => {
  return (
    <EmptyPageWrapper>
      <div className={`page-props ${messageOnly && 'page-message'}`}>
        <div className="title-text">{titleText}</div>
        <div className="action-text">{actionText}</div>
        {buttonText && (
          <div className="action-button">
            <ScopeButton
              type="primary" //@ts-ignore
              width="100%"
              onClick={actionCallback}
              disabled={disableButton}
            >
              {buttonText}
            </ScopeButton>
          </div>
        )}
      </div>
    </EmptyPageWrapper>
  );
};
