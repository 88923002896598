import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Typography, Spin } from 'antd';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { FormWrapper } from 'components/Custom/wrappers';
import { FormInput } from 'components/Custom/inputs';
import { ScopeButton } from 'components/Custom/buttons';
import Logo from 'assets/images/generateTAX-logo.svg';
import { CenterLogo } from 'components/Custom/logo';

interface ResetPasswordRequestFormProps {
  onRequestResetPassword: (email: string) => void;
  loading?: boolean;
}

interface ResetPasswordRequestFormValues {
  email: string;
}

export const ResetPasswordRequestForm: React.FC<
  ResetPasswordRequestFormProps
> = ({ onRequestResetPassword, loading }) => {
  const { t } = useTranslation();

  const handleResendSubmit = async (
    values: ResetPasswordRequestFormValues
  ): Promise<void> => {
    onRequestResetPassword(values.email);
  };
  return (
    <FormWrapper>
      <Spin spinning={loading} style={{ marginTop: '150px' }} size="large">
        <Row justify="center">
          <CenterLogo src={Logo} />
        </Row>
        <Row justify="center" align={'middle'} style={{ textAlign: 'center' }}>
          <Col span={6}>
            <Row justify="start">
              <Col span={24}>
                <Typography.Title
                  level={3}
                  style={{
                    fontWeight: 300,
                  }}
                >
                  {t('forgot-password.reset-password')}
                </Typography.Title>
              </Col>

              <Col span={24} style={{ marginTop: '30px' }}>
                <Form
                  layout="vertical"
                  onFinish={handleResendSubmit}
                  style={{ marginBottom: '30px' }}
                >
                  <Row gutter={24} justify="space-between">
                    <Col span={24}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t('signup.required-field'),
                          },
                          {
                            type: 'email',
                            message: t('signup.invalid-email'),
                          },
                        ]}
                      >
                        <FormInput placeholder={t('signup.email')} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item>
                        <ScopeButton type="primary" htmlType="submit">
                          {t('forgot-password.send-instructions')}
                        </ScopeButton>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Link className="link link-text" to={routes.login}>
                        {t('forgot-password.back-to-login')}
                      </Link>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </FormWrapper>
  );
};
