import { UserDetails } from 'store/signup/models';
import { createAction } from '../utils';
import {
  FETCH_ME_FAILURE,
  FETCH_ME_REQUEST,
  FETCH_ME_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_MFA,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESET_PASSWORD_EMAIL_FAILURE,
  RESET_PASSWORD_EMAIL_REQUEST,
  RESET_PASSWORD_EMAIL_SUCCESS,
  UPDATE_CURRENT_USER_PICTURE,
} from './constants';
import {
  FetchMeFailureAction,
  FetchMeRequestAction,
  FetchMeSuccessAction,
  LoginFailureAction,
  LoginMfaAction,
  LoginRequestAction,
  LoginSuccessAction,
  LogoutFailureAction,
  LogoutRequestAction,
  LogoutSuccessAction,
  ResetPasswordEmailFailureAction,
  ResetPasswordEmailRequestAction,
  ResetPasswordEmailSuccessAction,
  UpdateCurrentUserPictureAction,
} from './types';
import { MfaResponse, User } from './models';

export const loginRequest = (
  loginParams: UserDetails,
  rememberMe?: boolean,
  gCaptchaResponse?: string,
  mfaCode?: string | null
): LoginRequestAction =>
  createAction(LOGIN_REQUEST, {
    loginParams,
    rememberMe,
    gCaptchaResponse,
    mfaCode,
  });

export const loginSuccess = (): LoginSuccessAction =>
  createAction(LOGIN_SUCCESS);

export const loginFailure = (): LoginFailureAction =>
  createAction(LOGIN_FAILURE);

export const loginMfa = (mfaResponse: MfaResponse): LoginMfaAction =>
  createAction(LOGIN_MFA, { mfaResponse });

export const fetchMeRequest = (): FetchMeRequestAction =>
  createAction(FETCH_ME_REQUEST);

export const fetchMeSuccess = (user: User): FetchMeSuccessAction =>
  createAction(FETCH_ME_SUCCESS, { user });

export const fetchMeFailure = (): FetchMeFailureAction =>
  createAction(FETCH_ME_FAILURE);

export const logoutRequest = (): LogoutRequestAction =>
  createAction(LOGOUT_REQUEST);
export const logoutSuccess = (): LogoutSuccessAction =>
  createAction(LOGOUT_SUCCESS);
export const logoutFailure = (): LogoutFailureAction =>
  createAction(LOGOUT_FAILURE);

export const resetPasswordEmailRequest = (
  user: UserDetails
): ResetPasswordEmailRequestAction =>
  createAction(RESET_PASSWORD_EMAIL_REQUEST, { user });

export const resetPasswordEmailSuccess = (): ResetPasswordEmailSuccessAction =>
  createAction(RESET_PASSWORD_EMAIL_SUCCESS);

export const resetPasswordEmailFailure = (): ResetPasswordEmailFailureAction =>
  createAction(RESET_PASSWORD_EMAIL_FAILURE);

export const updateCurrentUserPicture = (
  picture: string
): UpdateCurrentUserPictureAction =>
  createAction(UPDATE_CURRENT_USER_PICTURE, { picture });
