import { api } from 'store/api';
import { ApiResponse } from 'store/api/types';

import { EditOrganisationParams, Mapping, Organisation } from './models';

export interface FetchOrganisationsResponse extends ApiResponse {
  data: { organisations: Organisation[] };
}

export const getOrganisations = (): Promise<FetchOrganisationsResponse> =>
  api.get<FetchOrganisationsResponse>(`/auth/organisation/getAll`);

export interface FetchMappingsResponse extends ApiResponse {
  //data: { mappings: Mapping[] };
  data: { mappings: Mapping[] };
}

export const getMappings = (): Promise<FetchMappingsResponse> =>
  api.get<FetchMappingsResponse>(`/auth/returns/mapping/getAll`);

export const disconnectOrganisation = (
  organisationId: string
): Promise<ApiResponse> =>
  api.post<ApiResponse>(`/auth/organisation/disconnect/${organisationId}`);

export interface FetchAddUrlResponse extends ApiResponse {
  data: { url: string };
}

export const getAddOrganisationUrl = (
  providerName: string,
  uuid: string,
  sourceUrl: string
): Promise<FetchAddUrlResponse> =>
  api.get<FetchAddUrlResponse>(
    `/auth/organisation/connect/${providerName}/${uuid}?client=ACCOUNTING&sourceUrl=${sourceUrl}`
  );

export const connectCin7Entity = (
  key: string,
  cin7accountId: string,
  orgName: string
): Promise<ApiResponse> =>
  api.get<ApiResponse>(
    `/auth/organisation/connect/cin7/${cin7accountId}?key=${key}&cin7accountId=${cin7accountId}&orgName=${orgName}`
  );

export const editOrganisation = (
  editOrganisation: EditOrganisationParams
): Promise<ApiResponse> =>
  api.put<ApiResponse>('/auth/organisation/editconnection', {
    body: editOrganisation,
  });
