import { AuthToken } from '../models';

export class AuthTokenStorage {
  public static ACCESS_TOKEN = 'access_token';
  public static REFRESH_TOKEN = 'refresh_token';
  public static ACCESS_TOKEN_EXPIRES_IN = 'expires_in';
  public static REFRESH_TOKEN_EXPIRES_IN = 'refresh_expires_in';

  public static save(token: AuthToken): void {
    if (!this.valid(token)) return;
    const { localStorage } = window;

    localStorage.setItem(AuthTokenStorage.ACCESS_TOKEN, token.accessToken);
    localStorage.setItem(
      AuthTokenStorage.ACCESS_TOKEN_EXPIRES_IN,
      JSON.stringify(token.expiresIn)
    );
    localStorage.setItem(AuthTokenStorage.REFRESH_TOKEN, token.refreshToken);
    localStorage.setItem(
      AuthTokenStorage.REFRESH_TOKEN_EXPIRES_IN,
      JSON.stringify(token.refreshExpiresIn)
    );
  }

  public static retrieve(): AuthToken | null {
    const { localStorage } = window;

    const accessToken = localStorage.getItem(AuthTokenStorage.ACCESS_TOKEN);
    const expiresIn = Number(
      localStorage.getItem(AuthTokenStorage.ACCESS_TOKEN_EXPIRES_IN)
    );
    const refreshToken = localStorage.getItem(AuthTokenStorage.REFRESH_TOKEN);
    const refreshExpiresIn = Number(
      localStorage.getItem(AuthTokenStorage.REFRESH_TOKEN_EXPIRES_IN)
    );

    const token = {
      accessToken,
      expiresIn,
      refreshToken,
      refreshExpiresIn,
    } as AuthToken;

    if (AuthTokenStorage.valid(token)) {
      return token;
    }

    return null;
  }

  public static getAccessToken(): string | null {
    return window.localStorage.getItem(AuthTokenStorage.ACCESS_TOKEN);
  }

  public static clear(): void {
    const { localStorage } = window;
    localStorage.removeItem(AuthTokenStorage.ACCESS_TOKEN);
    localStorage.removeItem(AuthTokenStorage.ACCESS_TOKEN_EXPIRES_IN);
    localStorage.removeItem(AuthTokenStorage.REFRESH_TOKEN);
    localStorage.removeItem(AuthTokenStorage.REFRESH_TOKEN_EXPIRES_IN);
  }

  private static valid(token: AuthToken): boolean {
    return (
      !empty(token.accessToken) &&
      !empty(token.expiresIn) &&
      !empty(token.refreshExpiresIn) &&
      !empty(token.refreshToken)
    );
  }
}

function empty(x: string | number | null) {
  return x === null || x === undefined || Number.isNaN(x);
}
