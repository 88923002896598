//src/App.jsx
import { ImageCrop } from 'components/AccountComponent/pic-image-crop';
import { ImageCropProvider } from 'components/AccountComponent/pic-image-crop-provider';
import React from 'react';

interface PicPageProps {
  profilePicture?: any;
  onClose: () => void;
  uploadImage: (base64: File) => void;
}

export const PicPage: React.FC<PicPageProps> = ({
  profilePicture,
  onClose,
  uploadImage,
}) => {
  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      <ImageCropProvider>
        <ImageCrop
          profilePicture={profilePicture}
          onClose={onClose}
          uploadImage={uploadImage}
        />
      </ImageCropProvider>
    </div>
  );
};

export default PicPage;
