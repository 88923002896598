import { Provider } from 'store/api/enums';

export const getProviderLink = (provider?: string, code?: string) => {
  switch (provider) {
    case Provider.XERO:
      return `https://go.xero.com/app/${code}/dashboard`;
    case Provider.CIN7:
      return `https://inventory.dearsystems.com`;
  }
};

export const getVIESProviderLink = (
  provider?: string,
  code?: string,
  providerContactId?: string
) => {
  switch (provider) {
    case 'xero':
      return `https://go.xero.com/app/${code}/contacts/contact/${providerContactId}`;
    case 'dear':
      return `https://inventory.dearsystems.com/${code}#${providerContactId}`;
  }
};
