import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { AuthState } from './types';

const authSelector = (state: StoreState) => state.auth;

export const getAuthLoading = createSelector(
  authSelector,
  (auth: AuthState) => auth.loading
);
export const getAuthError = createSelector(
  authSelector,
  (auth: AuthState) => auth.error
);
export const getAuthErrorCode = createSelector(
  authSelector,
  (auth: AuthState) => auth.errorCode
);

export const getAuthUser = createSelector(
  authSelector,
  (auth: AuthState) => auth.user
);

export const getAuthMfaResponse = createSelector(
  authSelector,
  (auth: AuthState) => auth.mfaResponse
);
