export const FETCH_COUNTRIES_REQUEST = 'common/fetch-countries-request';
export const FETCH_COUNTRIES_SUCCESS = 'common/fetch-countries-success';
export const FETCH_COUNTRIES_FAILURE = 'common/fetch-countries-failure';

export const FETCH_COUNTRIES_FLAGS_REQUEST = 'common/fetch-countries-flags-request';
export const FETCH_COUNTRIES_FLAGS_SUCCESS = 'common/fetch-countries-flags-success';
export const FETCH_COUNTRIES_FLAGS_FAILURE = 'common/fetch-countries-flags-failure';

export const FETCH_BUSINESS_TYPES_REQUEST =
  'common/fetch-business-types-request';
export const FETCH_BUSINESS_TYPES_SUCCESS =
  'common/fetch-business-types-success';
export const FETCH_BUSINESS_TYPES_FAILURE =
  'common/fetch-business-types-failure';

export const FETCH_BUSINESS_SIZES_REQUEST =
  'common/fetch-business-sizes-request';
export const FETCH_BUSINESS_SIZES_SUCCESS =
  'common/fetch-business-sizes-success';
export const FETCH_BUSINESS_SIZES_FAILURE =
  'common/fetch-business-sizes-failure';

export const FETCH_TIMEZONES_REQUEST = 'common/fetch-timezones-request';
export const FETCH_TIMEZONES_SUCCESS = 'common/fetch-timezones-success';
export const FETCH_TIMEZONES_FAILURE = 'common/fetch-timezones-failure';

export const FETCH_CURRENCIES_REQUEST = 'common/fetch-currencies-request';
export const FETCH_CURRENCIES_SUCCESS = 'common/fetch-currencies-success';
export const FETCH_CURRENCIES_FAILURE = 'common/fetch-currencies-failure';
