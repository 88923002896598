import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';

import { appReducer } from './app/reducer';
import { authReducer } from './auth/reducer';
import { commonReducer } from './common/reducer';
import { signUpReducer } from './signup/reducer';
import { StoreState } from './types';
import { organisationReducer } from './organisation/reducer';
import { returnReducer } from './returns/reducer';
import { userReducer } from './user/reducer';
import { accountReducer } from './account/reducer';
import { vatReducer } from './vat/reducer';
import { vatCheckerReducer } from './vat-checker/reducer';

const createRootReducer = (history: History<StoreState>): Reducer<StoreState> =>
  combineReducers<StoreState>({
    account: accountReducer,
    app: appReducer,
    auth: authReducer,
    common: commonReducer,
    router: connectRouter(history),
    signUp: signUpReducer,
    organisation: organisationReducer,
    return: returnReducer,
    user: userReducer,
    vat: vatReducer,
    vatChecker: vatCheckerReducer,
  });

export default createRootReducer;
