import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { AppState, CurrentPage } from './types';

export const appSelector = (state: StoreState): AppState => state.app;

export const getLocale = createSelector(
  appSelector,
  (app: AppState): string => app.locale
);
export const getCurrentPage = createSelector(
  appSelector,
  (app: AppState): CurrentPage => app.currentPage
);

export const getShowMessage = createSelector(
  appSelector,
  (app: AppState): AppState['showMessage'] => app.showMessage
);

export const getMessage = createSelector(
  appSelector,
  (app: AppState): AppState['message'] => app.message
);

export const getModalOpen = createSelector(
  appSelector,
  (app: AppState): AppState['modalOpen'] => app.modalOpen
);

export const getModalData = createSelector(
  appSelector,
  (app: AppState): AppState['modalData'] => app.modalData
);

export const getDrawerOpen = createSelector(
  appSelector,
  (app: AppState): AppState['drawerOpen'] => app.drawerOpen
);

export const getDrawerData = createSelector(
  appSelector,
  (app: AppState): AppState['drawerData'] => app.drawerData
);

export const getRefreshDone = createSelector(
  appSelector,
  (app: AppState): AppState['refreshDone'] => app.refreshDone
);

export const getMenuSize = createSelector(
  appSelector,
  (app: AppState): AppState['menuSize'] => app.menuSize
);

export const getMappingData = createSelector(
  appSelector,
  (app: AppState): AppState['mappingData'] => app.mappingData
);

export const getProfilePictures = createSelector(
  appSelector,
  (app: AppState): AppState['profilePictures'] => app.profilePictures
);
