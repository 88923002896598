import { call, put } from 'redux-saga/effects';
import { fetchCurrenciesFailure, fetchCurrenciesSuccess } from '../actions';
import { CurrenciesResponse, getCurrencies } from '../api';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { ERROR_HANG_TIME } from 'store/app/constants';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchCurrenciesSaga() {
  try {
    let err: ApiError | null;

    const response: CurrenciesResponse = yield call(getCurrencies);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const currencies: string[] = response?.data?.currencies;
    yield put(fetchCurrenciesSuccess(currencies));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(fetchCurrenciesFailure());
  }
}
