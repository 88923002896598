import { call, put } from 'redux-saga/effects';
import { ApiError } from 'store/api/types';
import { CheckContactRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { CheckContactResponse, checkContact } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { ERROR_HANG_TIME } from 'store/app/constants';
import { checkContactFailure, checkContactSuccess } from '../actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* checkContactSaga(action: CheckContactRequestAction) {
  try {
    let err: ApiError | null;
    const { contactUuid } = action.payload;

    const response: CheckContactResponse = yield call(
      checkContact,
      contactUuid
    );
    err = checkResponse(response?.responseMessage);
    if (err) throw err;
    const message: MessageData = {
      title: 'Success',
      description: 'Contact was checked.',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));

    const viesChecks = response.data.viesChecks;
    const contact = response.data.contact;
    yield put(checkContactSuccess(viesChecks, contact));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(checkContactFailure());
  }
}
