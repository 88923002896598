import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import * as React from 'react';
import routes from 'routes';
import { connect } from 'react-redux';
import { StoreState } from 'store/types';
import { getAuthLoading, getAuthUser } from 'store/auth/selectors';

import { User } from 'store/auth/models';
import { isAuthorized } from 'utils/is-authorized';

interface PrivateRouteProps extends RouteProps {
  loading: boolean;
  user: User;
}

export const PrivateRouteContainer: React.FC<PrivateRouteProps> = ({
  loading,
  user,
  ...props
}) => {
  if (!loading) {
    //if (isAuthorized()) {
    //} && !!user) {
    return <Route {...props} />;
    //}

    //const { pathname, search } = useLocation();
    //const nextPath = pathname + search;
    return <Redirect to={routes.login} />;
    //return <Redirect to={`${routes.login}?next=${nextPath}`} />;
  } else {
    return null;
  }
};

const mapStateToProps = (state: StoreState) => ({
  loading: getAuthLoading(state),
  user: getAuthUser(state),
});

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteContainer);
