import {
  AuthAction,
  AuthState,
  FetchMeSuccessAction,
  LoginMfaAction,
  UpdateCurrentUserPictureAction,
} from './types';
import {
  FETCH_ME_FAILURE,
  FETCH_ME_REQUEST,
  FETCH_ME_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_MFA,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESET_PASSWORD_EMAIL_FAILURE,
  RESET_PASSWORD_EMAIL_REQUEST,
  RESET_PASSWORD_EMAIL_SUCCESS,
  UPDATE_CURRENT_USER_PICTURE,
} from './constants';
import { User } from './models';

export const initialState: AuthState = {
  loading: false,
  user: undefined,
  mfaResponse: undefined,
};

export const authReducer = (
  state = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case FETCH_ME_REQUEST:
    case LOGOUT_REQUEST:
    case RESET_PASSWORD_EMAIL_REQUEST:
      return handleRequest(state);
    case LOGIN_SUCCESS:
    case RESET_PASSWORD_EMAIL_SUCCESS:
      return handleSuccess(state);
    case FETCH_ME_SUCCESS:
      return handleFetchMeSuccess(state, action);
    case LOGOUT_SUCCESS:
      return handleLogoutSuccess();
    case LOGIN_FAILURE:
    case FETCH_ME_FAILURE:
    case LOGOUT_FAILURE:
    case RESET_PASSWORD_EMAIL_FAILURE:
      return handleFailure(state);
    case LOGIN_MFA:
      return handleLoginMfa(state, action);
    case UPDATE_CURRENT_USER_PICTURE:
      return handleUpdateCurrentUserPicture(state, action);
    default:
      return state;
  }
};

function handleFetchMeSuccess(
  state: AuthState,
  action: FetchMeSuccessAction
): AuthState {
  return {
    ...state,
    loading: false,
    user: action.payload.user,
  };
}

function handleLoginMfa(state: AuthState, action: LoginMfaAction): AuthState {
  return {
    ...state,
    loading: false,
    mfaResponse: action.payload.mfaResponse,
  };
}

function handleLogoutSuccess(): AuthState {
  return {
    ...initialState,
    loading: false,
  };
}

function handleRequest(state: AuthState): AuthState {
  return {
    ...state,
    loading: true,
    user: undefined,
  };
}

function handleSuccess(state: AuthState): AuthState {
  return {
    ...state,
    loading: false,
  };
}

function handleFailure(state: AuthState): AuthState {
  return {
    ...state,
    loading: false,
    user: undefined,
  };
}

function handleUpdateCurrentUserPicture(
  state: AuthState,
  action: UpdateCurrentUserPictureAction
): AuthState {
  const profilePicture = action.payload.picture;
  let newUser: any;
  if (!!state.user) {
    newUser = { ...state.user, profilePicture };
  } else {
    newUser = undefined;
  }

  return {
    ...state,
    loading: false,
    user: newUser,
  };
}
