import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { UserState } from './types';

const selectUser = (app: StoreState): UserState => app.user;

export const getUserLoading = createSelector(
  selectUser,
  (user: UserState): UserState['loading'] => user.loading
);

export const getUserError = createSelector(
  selectUser,
  (user: UserState): UserState['error'] => user.error
);

export const getUserMembers = createSelector(
  selectUser,
  (user: UserState): UserState['members'] => user.members
);

export const getUserHistory = createSelector(
  selectUser,
  (user: UserState): UserState['loginDetails'] => user.loginDetails
);

export const getUserRights = createSelector(
  selectUser,
  (user: UserState): UserState['userRights'] => user.userRights
);
