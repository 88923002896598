import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { ViesState } from './types';

const selectSignUp = (app: StoreState): ViesState => app.vatChecker;

export const getViesLoading = createSelector(
  selectSignUp,
  (returns: ViesState): ViesState['loading'] => returns.loading
);

export const getViesInfos = createSelector(
  selectSignUp,
  (returns: ViesState): ViesState['viesInfos'] => returns.viesInfos
);

export const getViesContacts = createSelector(
  selectSignUp,
  (returns: ViesState): ViesState['contacts'] => returns.contacts
);

export const getViesChecks = createSelector(
  selectSignUp,
  (returns: ViesState): ViesState['viesChecks'] => returns.viesChecks
);
