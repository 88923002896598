import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  LockOutlined,
  QuestionOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { ColoredTag } from 'components/Custom/spans';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityType, DataLogStatus, ViesStatus } from 'store/app/enums';

interface StatusGetterProps {
  status?: string;
  noDraft?: boolean;
  smaller?: boolean;
}

export const StatusGetter: React.FC<StatusGetterProps> = ({
  status,
  noDraft,
  smaller,
}) => {
  const { t } = useTranslation();
  const valueToCheck =
    status && DataLogStatus[status] ? DataLogStatus[status] : status;

  if (status && status != '') {
    switch (valueToCheck) {
      case DataLogStatus.SAVED:
        return (
          <ColoredTag
            color="#054581" //@ts-ignore
            bgcolor="#E1EDF6"
            bordered={true}
            bordercolor="#C2D3DF"
            icon={<SaveOutlined />}
            smaller={smaller}
          >
            {valueToCheck}
          </ColoredTag>
        );
      case DataLogStatus.AWAITING_APPROVAL:
      case DataLogStatus.AWAITING_CLIENT_APPROVAL:
        return (
          <ColoredTag
            color="#C38A20" //@ts-ignore
            bgcolor="#FFF5E2"
            bordered={true}
            bordercolor="#FFBC3E"
            icon={<ClockCircleOutlined />}
            smaller={smaller}
          >
            {valueToCheck}
          </ColoredTag>
        );
      case DataLogStatus.CANCELLED:
      case ViesStatus.ERROR:
      case ViesStatus.INVALID:
        return (
          <ColoredTag
            color="#D13131" //@ts-ignore
            bgcolor="#FCE7E7"
            bordered={true}
            icon={<CloseOutlined style={{ color: '#D13131' }} />}
            smaller={smaller}
          >
            {valueToCheck}
          </ColoredTag>
        );
      case DataLogStatus.SUBMITTED:
      case DataLogStatus.APPROVED:
      case DataLogStatus.INTERNAL_APPROVED:
      case DataLogStatus.CLIENT_APPROVED:
      case ViesStatus.VALID:
        return (
          <ColoredTag
            color="#058181" //@ts-ignore
            bgcolor="#E9F6F7"
            bordered={true}
            bordercolor="#DBEAE3"
            icon={<CheckOutlined />}
            smaller={smaller}
          >
            {valueToCheck}
          </ColoredTag>
        );
      case DataLogStatus.LOCKED:
        return (
          <ColoredTag
            color="#879494" //@ts-ignore
            bgcolor="#EAEAEA"
            bordered={true}
            bordercolor="#D7D7D7"
            icon={<LockOutlined />}
            smaller={smaller}
          >
            {valueToCheck}
          </ColoredTag>
        );
      case DataLogStatus.NOT_SAVED:
        return (
          <ColoredTag
            color="#879494" //@ts-ignore
            bgcolor="#EAEAEA"
            bordered={true}
            bordercolor="#D7D7D7"
            icon={<SaveOutlined />}
            smaller={smaller}
          >
            {t('main.draft')}
          </ColoredTag>
        );
      case ViesStatus.EMPTY:
        return (
          <ColoredTag
            color="#879494" //@ts-ignore
            bgcolor="#EAEAEA"
            bordered={true}
            bordercolor="#D7D7D7"
            icon={<QuestionOutlined />}
            smaller={smaller}
          >
            {valueToCheck}
          </ColoredTag>
        );
      default:
        return (
          //@ts-ignore
          <ColoredTag
            color="#042a2a"
            bgcolor={!!ActivityType[status] ? '#FFF5E2' : '#ffffff'}
            smaller={smaller}
          >
            {!!ActivityType[status] ? ActivityType[status] : status}
          </ColoredTag>
        );
    }
  }
  if (noDraft) return null;
  return (
    <ColoredTag
      color="#879494" //@ts-ignore
      bgcolor="#EAEAEA"
      bordered={true}
      bordercolor="#D7D7D7"
      icon={<SaveOutlined />}
    >
      {t('main.draft')}
    </ColoredTag>
  );
};
