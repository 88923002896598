import { takeLatest } from 'redux-saga/effects';
import {
  FETCH_BUSINESS_SIZES_REQUEST,
  FETCH_BUSINESS_TYPES_REQUEST,
  FETCH_COUNTRIES_REQUEST,
  FETCH_CURRENCIES_REQUEST,
  FETCH_TIMEZONES_REQUEST,
  FETCH_COUNTRIES_FLAGS_REQUEST,
} from '../constants';
import { fetchCountriesSaga, fetchCountriesExternalDataSaga } from './fetchCountriesSaga';
import { fetchBusinessTypesSaga } from './fetchBusinessTypesSaga';
import { fetchBusinessSizesSaga } from './fetchBusinessSizesSaga';
import { fetchTimezonesSaga } from './fetchTimezonesSaga';
import { fetchCurrenciesSaga } from './fetchCurrenciesSaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* commonSaga() {
  yield takeLatest(FETCH_COUNTRIES_REQUEST, fetchCountriesSaga);
  yield takeLatest(FETCH_CURRENCIES_REQUEST, fetchCurrenciesSaga);
  yield takeLatest(FETCH_BUSINESS_TYPES_REQUEST, fetchBusinessTypesSaga);
  yield takeLatest(FETCH_BUSINESS_SIZES_REQUEST, fetchBusinessSizesSaga);
  yield takeLatest(FETCH_TIMEZONES_REQUEST, fetchTimezonesSaga);
  yield takeLatest(FETCH_COUNTRIES_FLAGS_REQUEST, fetchCountriesExternalDataSaga);
}
