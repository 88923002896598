//src/components/cropper/Sliders.jsx
import React from 'react';
import { useImageCropContext } from './pic-image-crop-provider';
import { Slider, Space } from 'antd';
import { GraySpan } from 'components/Custom/spans';
import { useTranslation } from 'react-i18next';

export const ZoomSlider = ({ className }) => {
  const { zoom, setZoom } = useImageCropContext();

  const { t } = useTranslation();

  return (
    <Space style={{ width: '50%' }}>
      <GraySpan>{t('common.zoom')}</GraySpan>
      <Slider
        value={zoom}
        min={1}
        max={3}
        step={0.1}
        style={{ width: '120px' }}
        aria-labelledby="Zoom"
        onChange={(zoom) => setZoom(zoom)}
      />
    </Space>
  );
};

export const RotationSlider = ({ className }) => {
  const { rotation, setRotation } = useImageCropContext();

  const { t } = useTranslation();

  return (
    <Space style={{ width: '50%' }}>
      <GraySpan>{t('common.rotation')}</GraySpan>
      <Slider
        value={rotation}
        min={0}
        max={360}
        step={5}
        style={{ width: '120px' }}
        aria-labelledby="Rotation"
        onChange={(rotation) => setRotation(rotation)}
      />
    </Space>
  );
};
