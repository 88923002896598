//@ts-nocheck
import { Select } from 'antd';
import styled from 'styled-components';

export const FormSelect = styled(Select)`
  width: ${(props) => (props.width ? props.width : '90%')} !important;
  border-radius: ${(props) => (props.radius ? props.radius : '0')} !important;
  border: 1px solid
    ${(props) => (props.bordercolor ? props.bordercolor : 'transparent')};
  min-height: ${(props) => (props.height ? props.height : '45px')};
  margin-right: ${(props) => (props.marginright ? props.marginright : '0')};
  text-align: ${(props) => (props.textalign ? props.textalign : 'none')};
  min-width: 25%;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #41aaaf !important;
    box-shadow: none;
    min-height: ${(props) => (props.height ? props.height : '43px')};
  }

  > div {
    border-radius: ${(props) => (props.radius ? props.radius : '0')} !important;
    border: 1px solid transparent !important;
  }

  .ant-select-arrow {
    color: #41aaaf !important;
  }

  .ant-select-selector {
    min-height: ${(props) =>
      props.height && props.mode == 'multiple' ? props.height : '43px'};
    background: ${(props) =>
      props.bgcolor ? props.bgcolor : '#FFFFFF'} !important;
  }

  .ant-select-selector {
    min-height: ${(props) =>
      props.height && props.isFilter
        ? `${props.height.replace('px', '') - 5}px`
        : '43px'};
  }

  .ant-select-selection-item {
    color: #042a2a;
    font-weight: 600;
  }
  ${(props) =>
    props.mode == 'multiple' &&
    `.ant-select-selection-item {
    background: ${
      props.selected_bg_color ? props.selected_bg_color : '#fff5e2'
    } !important;
    border-radius: 5px;
    height:35px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
  }
  
  .ant-select-selection-item > span > div > img {
    margin-top: 8px !important;
  }
  `}

  .ant-select-selection-item > div > img {
    margin-top: 7px !important;
  }

  .ant-select-selection-item-remove {
    color: #41aaaf !important;
    font-weight: 800;
    font-size: 12px;
  }
`;

export const FilterSelect = styled(Select)`
  width: 120px;
  height: 32px;
  gap: 0px;
  border-radius: 10px !important;
  border: 1px solid #dbeae3;
  opacity: 0px;
  box-shadow: 0px 0px 10px 0px #0000000d;

  > div {
    border-radius: 10px !important;
  }

  &.selected-filter > div::after {
    content: "${(props) =>
      props.mode == 'multiple' && props.placeholder
        ? props.placeholder
        : ''}" !important;
    visibility: visible !important;
    color: #9ca7b0;
    font-size: 12px;
    font-weight: 600;
    line-height: 11px;
    width: ${(props) =>
      props.mode == 'multiple' && props.placeholder ? '70px' : '0px'};
    pointer-events: none;
  }

  .ant-select-selection-overflow {
    display: contents !important;
  }

  > div > span {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
  }

  > div:hover {
    border: 2px solid #41aaaf !important;
  }

  ${(props) =>
    props.mode == 'multiple' &&
    `.ant-select-selection-item {
      display: none !important;
  }`}
`;

export const RoundSearchSelect = styled(Select)`
  width: 317px;
  height: 45px !important;
  border-radius: 50px !important;

  > div {
    border-radius: 50px !important;
    border: 1px solid transparent !important;
  }

  .ant-select-clear {
    inset-inline-start: 250px;
  }

  background: #f3f7f8;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #41aaaf !important;
    box-shadow: none !important;
    .ant-select-arrow {
      opacity: 1 !important;
    }
  }
`;
