import { VatOverview } from 'components/Vat/Overview';
import * as React from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchCurrenciesRequest } from 'store/common/actions';
import { getCommonCurrencies } from 'store/common/selectors';
import {
  getOrgMappings,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import {
  fetchRunDataRequest,
  fetchVatLogsRequest,
  fetchViewRunDataRequest,
} from 'store/vat/actions';
import { VatRunRequestData, ViewRunRequestData } from 'store/vat/models';
import {
  getVatLoading,
  getVatPrepLoading,
  getVatReturnLogs,
} from 'store/vat/selectors';
import { calculateNextPeriod } from 'utils/calculate-next-period';
import { Form } from 'antd';
import { push } from 'connected-react-router';
import routes from 'routes';
import { turnDrawerOff } from 'store/app/actions';

interface VatOverviewPageProps {
  connectionId: string;
  mappingId: string;
}
// interface Params {
//   connectionId: string;
//   mappingId: string;
// }
export const VatOverviewPage: React.FC<VatOverviewPageProps> = ({
  connectionId,
  mappingId,
}) => {
  const dispatch = useDispatch();
  //const params: Params = useParams();
  //const connectionId = params.connectionId;
  //const mappingId = params.mappingId;
  const vatReturnLogs = useSelector(getVatReturnLogs);
  const loading = useSelector(getVatLoading);
  const prepLoading = useSelector(getVatPrepLoading);
  const currencies = useSelector(getCommonCurrencies);
  const organisations = useSelector(getOrgOrganisations);
  const mappings = useSelector(getOrgMappings);
  const [form] = Form.useForm();
  const selectedCurrency = organisations?.find(
    (o) => o.connectionId === connectionId
  )?.currency;
  const selectedMapping = mappings?.find((m) => m.mappingId === mappingId);
  const retFreq = selectedMapping?.returnFrequency
    ? selectedMapping?.returnFrequency
    : '';
  const selectedRange = calculateNextPeriod(
    selectedMapping?.periodFromDate,
    retFreq
  )[2];

  const entityName = organisations?.find(
    (o) => o.connectionId === connectionId
  )?.friendlyName;
  const retTypes = organisations?.find(
    (o) => o.connectionId === connectionId
  )?.returnTypesEdit;
  const mappingTypeId = mappings?.find(
    (m) => m.mappingId === mappingId
  )?.returnTypeId;
  const taxReturn = retTypes?.find((rt) => rt.id === mappingTypeId);
  const taxReturnName = taxReturn?.countryName + ' - ' + taxReturn?.name;

  const goBack = () => {
    dispatch(push(routes.main));
  };

  const generateTaxReport = (dateRange: Date[], currency: string) => {
    const fromDate = moment(new Date(dateRange[0])).format('YYYY-MM-DD');
    const toDate = moment(new Date(dateRange[1])).format('YYYY-MM-DD');

    const vatRunRequestData: VatRunRequestData = {
      connectionId,
      mappingId,
      currency,
      fromDate,
      toDate,
    };

    dispatch(fetchRunDataRequest(vatRunRequestData));
    dispatch(turnDrawerOff());
  };

  const loadTaxReport = (dataLogId: string) => {
    const viewRunRequestData: ViewRunRequestData = {
      connectionId,
      mappingId,
      dataLogId,
    };

    dispatch(fetchViewRunDataRequest(viewRunRequestData));
  };

  React.useEffect(() => {
    currencies.length === 0 && dispatch(fetchCurrenciesRequest());

    // connectionId &&
    //   mappingId &&
    //   dispatch(fetchVatLogsRequest(connectionId, mappingId));
  }, []);

  React.useEffect(() => {
    selectedCurrency &&
      form.setFieldsValue({
        ['currency']: selectedCurrency,
      });
    selectedRange &&
      form.setFieldsValue({
        ['currency']: selectedCurrency,
        ['dateRange']: selectedRange,
      });
  }, [selectedCurrency, selectedRange]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Tax return | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for users" />
      </Helmet>

      <VatOverview
        loading={loading}
        prepLoading={prepLoading}
        vatReturnLogs={vatReturnLogs}
        currencies={currencies.sort()}
        generateTaxReport={generateTaxReport}
        form={form}
        goBack={goBack}
        loadTaxReport={loadTaxReport}
        entityName={entityName}
        taxReturnName={taxReturnName}
      />
    </React.Fragment>
  );
};
