import React from 'react';
import { Select } from 'antd';
import { CountryFlag } from 'store/common/models';

export const renderPhoneCodeOption = (
  country: CountryFlag
): React.JSX.Element => {
  return (
    <Select.Option
      key={country.name?.official}
      value={country.idd.root + country.idd.suffixes[0]}
      style={{ padding: '5px 10px', fontSize: '13px' }}
    >
      <div style={{ display: 'flex' }}>
        <img
          width={'24px'}
          height={'16px'}
          alt={country.flags.alt}
          src={country.flags.png}
          style={{
            marginLeft: '5px',
            marginRight: '10px',
            marginTop: '3px',
          }}
        />
        <span>
          {`${
            country.name.common.includes(',')
              ? country.name.common.split(',')[0]
              : country.name.common
          } ${
            country.idd.root == '' && country.idd.suffixes.length == 0
              ? '+672'
              : country.idd.root + country.idd.suffixes[0]
          }`}
        </span>
      </div>
    </Select.Option>
  );
};
