import React, { useEffect } from 'react';

import { SignUpSuccess } from 'components/SignUpForm/success';

export const SignupSuccessPage: React.FC = () => {
  const [showPage, setShowPage] = React.useState(false);
  useEffect(() => {
    setShowPage(true);
  }, []);
  return <>{showPage && <SignUpSuccess resetPassword={false} />}</>;
};
