import { Spin } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchViewRunDataRequest } from 'store/vat/actions';
import { ViewRunRequestData } from 'store/vat/models';
import { getVatPrepLoading } from 'store/vat/selectors';

interface Params {
  connectionId: string;
  mappingId: string;
  dataLogId: string;
}

export const ViewReportLink: React.FC = () => {
  const dispatch = useDispatch();
  const params: Params = useParams();
  const connectionId = params.connectionId;
  const mappingId = params.mappingId;
  const dataLogId = params.dataLogId;
  const loading = useSelector(getVatPrepLoading);
  React.useEffect(() => {
    const viewRunRequestData: ViewRunRequestData = {
      connectionId,
      mappingId,
      dataLogId,
    };

    dispatch(fetchViewRunDataRequest(viewRunRequestData));
  }, []);

  return (
    <Spin
      spinning={loading}
      size={'large'}
      style={{ width: '100%', marginTop: 200 }}
    ></Spin>
  );
};
