import { Button, Col, GetProp, Input, Row, Typography } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { OTPInput } from 'components/Custom/inputs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { QRCodeData } from 'store/account/models';

interface QRCodeSetupProps {
  QRCodeData?: QRCodeData;
  onVerifyCode: (code: string) => void;
}
const { Text } = Typography;

export const QRCodeSetup: React.FC<QRCodeSetupProps> = ({
  QRCodeData,

  onVerifyCode,
}) => {
  const { t } = useTranslation();
  const [mfaCode, setMfaCode] = React.useState<any>();
  const handleMfaChange: GetProp<typeof Input.OTP, 'onChange'> = (text) => {
    const code = text;
    if (code) {
      if (code.length <= 6 && parseInt(code, 10).toString() === code) {
        setMfaCode(code);
      } else {
      }
    } else {
      setMfaCode(undefined);
    }
  };

  const handleVerifyCode = () => {
    onVerifyCode(mfaCode ? mfaCode : '');
    setMfaCode('');
  };

  return (
    <Row justify={'center'} gutter={[0, 6]}>
      <Col span={24}>
        <Text>1) In the Google Authenticator app, tap the +</Text>
      </Col>
      <Col span={24}>
        <Text>
          2) Choose <strong>Scan a QR code</strong> and scan the below image
        </Text>
      </Col>
      <Col>
        <img src={QRCodeData?.code} width={180} height={180} />
      </Col>
      <Col span={24}>
        <Text>3) Enter the 6-digit code that you see in the app</Text>
      </Col>
      <Col span={24}>
        <OTPInput
          id="code"
          length={6}
          size={'large'}
          style={{
            fontSize: 17,
            letterSpacing: 7,
            textAlign: 'center',
            width: '100%',
            marginTop: '25px',
            columnGap: '10',
          }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={handleMfaChange}
          value={mfaCode}
        />
      </Col>
      <Col span={6} />
      <Col span={24}>
        <ScopeButton
          type={'primary'}
          size={'large'}
          onClick={handleVerifyCode}
          disabled={!mfaCode || mfaCode.length != 6}
        >
          Verify Code
        </ScopeButton>
      </Col>
    </Row>
  );
};
