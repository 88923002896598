import { createAction } from 'store/utils';
import {
  DeleteMappingFailureAction,
  DeleteMappingRequestAction,
  DeleteMappingSuccessAction,
  FetchAddReturnDataFailureAction,
  FetchAddReturnDataPostSuccessAction,
  FetchAddReturnDataPreSuccessAction,
  FetchAddReturnDataRatesSuccessAction,
  FetchAddReturnDataRequestAction,
  ResetReturnDataAction,
  SaveMappingFailureAction,
  SaveMappingRequestAction,
  SaveMappingSuccessAction,
} from './types';
import {
  DELETE_MAPPING_FAILURE,
  DELETE_MAPPING_REQUEST,
  DELETE_MAPPING_SUCCESS,
  FETCH_ADD_RETURN_DATA_FAILURE,
  FETCH_ADD_RETURN_DATA_POST_SUCCESS,
  FETCH_ADD_RETURN_DATA_PRE_SUCCESS,
  FETCH_ADD_RETURN_DATA_RATES_SUCCESS,
  FETCH_ADD_RETURN_DATA_REQUEST,
  RESET_RETURN_DATA,
  SAVE_MAPPING_FAILURE,
  SAVE_MAPPING_REQUEST,
  SAVE_MAPPING_SUCCESS,
} from './constants';

import {
  ReturnData,
  ReturnFrequencies,
  ReturnType,
  Section,
  TaxRate,
} from './models/returnData';
import { MappingData } from './models';

// Fetch Reports
export const fetchAddReturnDataRequest = (
  connectionId: string,
  loadedReturnData?: ReturnData
): FetchAddReturnDataRequestAction =>
  createAction(FETCH_ADD_RETURN_DATA_REQUEST, {
    connectionId,
    loadedReturnData,
  });

export const fetchAddReturnDataPreSuccess = (
  returnTypes?: ReturnType[],
  returnTypesEdit?: ReturnType[],
  returnFrequency?: ReturnFrequencies,
  connectionId?: string
): FetchAddReturnDataPreSuccessAction =>
  createAction(FETCH_ADD_RETURN_DATA_PRE_SUCCESS, {
    returnTypes,
    returnTypesEdit,
    returnFrequency,
    connectionId,
  });

export const fetchAddReturnDataRatesSuccess = (
  taxRates: TaxRate[]
): FetchAddReturnDataRatesSuccessAction =>
  createAction(FETCH_ADD_RETURN_DATA_RATES_SUCCESS, {
    taxRates,
  });

export const fetchAddReturnDataPostSuccess = (
  sections: Section[]
): FetchAddReturnDataPostSuccessAction =>
  createAction(FETCH_ADD_RETURN_DATA_POST_SUCCESS, {
    sections,
  });

export const fetchAddReturnDataFailure = (): FetchAddReturnDataFailureAction =>
  createAction(FETCH_ADD_RETURN_DATA_FAILURE);

// Save Mapping
export const saveMappingRequest = (
  connectionId: string,
  mappingData: MappingData
): SaveMappingRequestAction =>
  createAction(SAVE_MAPPING_REQUEST, { connectionId, mappingData });

export const saveMappingSuccess = (): SaveMappingSuccessAction =>
  createAction(SAVE_MAPPING_SUCCESS);

export const saveMappingFailure = (): SaveMappingFailureAction =>
  createAction(SAVE_MAPPING_FAILURE);

// Delete Mapping
export const deleteMappingRequest = (
  organisationId: string,
  mappingId: string
): DeleteMappingRequestAction =>
  createAction(DELETE_MAPPING_REQUEST, { organisationId, mappingId });

export const deleteMappingSuccess = (): DeleteMappingSuccessAction =>
  createAction(DELETE_MAPPING_SUCCESS);

export const deleteMappingFailure = (): DeleteMappingFailureAction =>
  createAction(DELETE_MAPPING_FAILURE);

// RESET
export const resetReturnData = (): ResetReturnDataAction =>
  createAction(RESET_RETURN_DATA);
