import { api } from 'store/api';
import { ApiResponse } from 'store/api/types';
import { Country, CountryFlag, Timezone } from './models';
import Countries from '../../resources/countries.json';

// Countries
export interface CountriesResponse extends ApiResponse {
  data: { countries: Country[] };
}
export const getCountries = (): Promise<CountriesResponse> =>
  api.get<CountriesResponse>('/common/countries/all');

// Currencies
export interface CurrenciesResponse extends ApiResponse {
  data: { currencies: string[] };
}
export const getCurrencies = (): Promise<CurrenciesResponse> =>
  api.get<CurrenciesResponse>('/common/currencies/all');

// Business Types
export interface BusinessTypesMap {
  businessTypesMap: Map<string, string>;
}
export interface BusinessTypesResponse extends ApiResponse {
  data: { businessTypes: BusinessTypesMap };
}
export const getBusinessTypes = (): Promise<BusinessTypesResponse> =>
  api.get<BusinessTypesResponse>('/common/business/types');

// Business Sizes
export interface BusinessSizesMap {
  businessSizes: Map<string, string>;
}
export interface BusinessSizesResponse extends ApiResponse {
  data: { businessSizes: BusinessSizesMap };
}
export const getBusinessSizes = (): Promise<BusinessSizesResponse> =>
  api.get<BusinessSizesResponse>('/common/business/sizes');

// Timezones
export interface TimezonesResponse extends ApiResponse {
  data: { timezones: Timezone[] };
}
export const getTimezones = (): Promise<TimezonesResponse> =>
  api.get<TimezonesResponse>('/common/getAllTimezones');

//countries flags
export const getCountriesData = (): Promise<CountryFlag[]> => Countries;
