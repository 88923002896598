import { Input, Space } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { FormInput } from 'components/Custom/inputs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface CardInfosProps {
  friendlyName: string | undefined;
  editOrganisationName: (newName?: string) => void;
  onClose: () => void;
}
export const EditFriendlyName: React.FC<CardInfosProps> = ({
  friendlyName,
  editOrganisationName,
  onClose,
}) => {
  const { t } = useTranslation();
  const [nameValue, setNameValue] = React.useState<any>(friendlyName);
  return (
    <Space direction="vertical" style={{ width: '100%', rowGap: 30 }}>
      <FormInput
        width={'100%'}
        value={nameValue}
        onChange={(e) => setNameValue(e.target.value)}
      />

      <Space
        align="center"
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <ScopeButton
          type="default"
          width="155px"
          height="35px"
          onClick={onClose}
        >
          {t('common.cancel')}
        </ScopeButton>
        <ScopeButton
          type="primary"
          width="155px"
          height="35px"
          onClick={() => editOrganisationName(nameValue)}
        >
          {t('common.save')}
        </ScopeButton>
      </Space>
    </Space>
  );
};
