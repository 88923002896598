import { Table } from 'antd';
import * as React from 'react';
import { DataContact } from 'store/vat/models/vatReportData';
interface TableDataContact extends DataContact {
  key: number;
}
export const renderMaltaRecap = (contacts?: DataContact[]) => {
  const tableContacts: TableDataContact[] = [];
  contacts?.forEach((c, key) => tableContacts.push({ ...c, key }));
  const columns = [
    {
      title: 'Triangular Trade',
      render: (_, record: DataContact) => {
        return record.isTriangularTrade ? 'YES' : 'NO';
      },
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
    },
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: 'VAT Number',
      dataIndex: 'taxNumber',
    },
    {
      title: 'VAT Verified',
      dataIndex: 'viesStatus',
    },
    {
      title: 'Goods Subtotal',
      dataIndex: 'goodsSum',
      align: 'right',
      render: (_, record: DataContact) => {
        return record.goodsSum.toFixed(2);
      },
    },
    {
      title: 'Services Subtotal',
      dataIndex: 'servicesSum',
      align: 'right',
      render: (_, record: DataContact) => {
        return record.servicesSum.toFixed(2);
      },
    },
  ];

  return (
    <Table
      dataSource={tableContacts} // @ts-ignore
      columns={columns}
      rowKey="key"
      size="small"
      pagination={{ position: ['topRight', 'bottomRight'] }}
      bordered
    />
  );
};
