import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import routes from 'routes';
import { useTranslation } from 'react-i18next';
import { getAuthUser } from 'store/auth/selectors';
import { getCommonCountries } from 'store/common/selectors';
import {
  getOrgLoading,
  getOrgMappedOrganisations,
} from 'store/organisation/selectors';
import { getReturnData, getReturnLoading } from 'store/returns/selectors';
import { fetchViewRunDataRequest } from 'store/vat/actions';
import { ViewRunRequestData } from 'store/vat/models';
import { getVatPrepLoading } from 'store/vat/selectors';
import { EntityComponent } from 'components/Entity';
import { DrawerType, WindowType } from 'store/app/enums';
import {
  turnDrawerOff,
  turnDrawerOn,
  turnModalOff,
  turnModalOn,
} from 'store/app/actions';
import {
  addMemberRequest,
  fetchUsersRequest,
  setUserRightsRequest,
} from 'store/user/actions';
import { DrawerData, ModalData } from 'store/app/types';
import { Form } from 'antd';
import { AddMemberForm } from 'components/UsersComponent/add-member';
import { NewMember } from 'pages/users';
import { ChooseReturn } from 'components/MappingForm/choose-return';
import { fetchAddReturnDataRequest } from 'store/returns/actions';
import { ReturnData } from 'store/returns/models';
import { ConnectEntity } from 'components/ConnectEntity';
import { InviteUser } from 'components/UsersComponent/invite-user';
import { TablePermission } from 'store/api/enums';
import { Entity } from 'store/organisation/models';
import {
  getUserLoading,
  getUserMembers,
  getUserRights,
} from 'store/user/selectors';
import { useState } from 'react';
import { fetchProfilePictureRequest } from 'store/account/actions';
import { ConnectCin7 } from 'components/Entity/connect-cin7';

export const OrganisationsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const mappedOrganisations = useSelector(getOrgMappedOrganisations);
  const countries = useSelector(getCommonCountries);
  const returnData = useSelector(getReturnData);
  const currentUser = useSelector(getAuthUser);
  const orgLoading = useSelector(getOrgLoading);
  const retLoading = useSelector(getReturnLoading);
  const vatLoading = useSelector(getVatPrepLoading);
  const rights = useSelector(getUserRights);
  const members = useSelector(getUserMembers);
  const userLoading = useSelector(getUserLoading);
  const loading = orgLoading || vatLoading;
  const [showPage, setShowPage] = useState(false);

  const addEntity = () => {
    const drawerData: DrawerData = {
      title: 'Add a new entity',
      subtitle: 'Connect one of the below',
      type: DrawerType.CONNECT_ENTITY,
      component: <ConnectEntity />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  React.useEffect(() => {
    //dispatch(fetchUsersRequest());
    setShowPage(true);
  }, []);

  // React.useEffect(() => {
  //   const sentRequests: string[] = [];
  //   if (mappedOrganisations) {
  //     mappedOrganisations.forEach((mo) => {
  //       mo.users.forEach((u) => {
  //         if (u.hasProfilePicture && !u.profilePicture) {
  //           if (sentRequests.findIndex((sr) => sr === u.userUuid) === -1) {
  //             dispatch(fetchProfilePictureRequest(u.userUuid));
  //             sentRequests.push(u.userUuid);
  //           }
  //         }
  //       });
  //     });
  //   }
  // }, [mappedOrganisations]);

  const addReturnMapping = (connectionId: string) => {
    if (connectionId) {
      const drawerData: DrawerData = {
        title: 'Add a Tax Return',
        type: DrawerType.ADD_TAX_RETURN,
        component: <ChooseReturn editMode={false} />,
      };
      if (!returnData || returnData.connectionId !== connectionId) {
        dispatch(fetchAddReturnDataRequest(connectionId));
      }
      dispatch(turnDrawerOn(drawerData));
    } else alert('Missing connection Id.');
  };

  const editReturnMapping = (connectionId: string, mappingId: string) => {
    const selectedOrg = mappedOrganisations?.find(
      (mo) => mo.connectionId === connectionId
    );
    const returnTypes = selectedOrg?.returnTypes;
    const returnTypesEdit = selectedOrg?.returnTypesEdit;
    const taxRates = selectedOrg?.taxRates;
    const selMapping = selectedOrg?.returns.find(
      (r) => r.mappingId === mappingId
    );
    const sections = selMapping?.sections ? selMapping.sections : [];
    const retData: ReturnData = {
      returnTypes,
      returnTypesEdit,
      sections,
      connectionId,
      taxRates,
    };
    dispatch(fetchAddReturnDataRequest(connectionId, retData));
    if (connectionId && mappingId) {
      dispatch(
        push(
          routes.returns.edit
            .replace(':connectionId', connectionId)
            .replace(':mappingId', mappingId)
        )
      );
    } else alert('Missing connection Id.');
  };

  // const saveNewMember = (newMember: NewMember) => {
  //   dispatch(addMemberRequest(newMember));
  //   dispatch(turnDrawerOff());
  // };

  const openMemberWindow = (entity: Entity) => {
    const modalData: DrawerData = {
      type: DrawerType.INVITE_USER,
      title: `${t('common.share')} “${entity.organisation} ”`,
      component: (
        <InviteUser
          loading={userLoading}
          members={entity.users} //@ts-ignore
          allUsers={_.differenceBy(members, entity.users, 'accountUuid')}
          organisationId={entity.organisationId}
        />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const editUsers = (entity: Entity) => {
    openMemberWindow(entity);
  };

  // const saveUserRights = (uuid: string, tablePermission: TablePermission) => {
  //   dispatch(setUserRightsRequest(uuid, tablePermission));
  // };

  // const onAddEntity = () => {
  //   const drawerData: DrawerData = {
  //     title: 'Connect entity',
  //     subtitle: 'Connect one of the below',
  //     type: DrawerType.CONNECT_ENTITY,
  //     component: <ConnectEntity />,
  //   };
  //   dispatch(turnDrawerOn(drawerData));
  // };

  // const vatGenerate = (
  //   connectionId: string,
  //   mappingId: string,
  //   dataLogId: string
  // ) => {
  //   const vatRoute = routes.vat.overview
  //     .replace(':connectionId', connectionId)
  //     .replace(':mappingId', mappingId);
  //   const viewRunRequestData: ViewRunRequestData = {
  //     connectionId,
  //     mappingId,
  //     dataLogId,
  //   };
  //   dataLogId
  //     ? dispatch(fetchViewRunDataRequest(viewRunRequestData))
  //     : dispatch(push(vatRoute));
  // };

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`Entities | ${process.env.PROJECT_NAME}`}</title>
            <meta name="description" content="This is a page for Entities" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            ></meta>
          </Helmet>
          <EntityComponent
            loading={loading}
            mappedOrganisations={mappedOrganisations}
            countries={countries}
            addReturnMapping={addReturnMapping}
            editReturnMapping={editReturnMapping}
            addEntity={addEntity}
            editUsers={editUsers}
            currentUser={currentUser}
            //connectEntity={}
          />
        </>
      )}
    </React.Fragment>
  );
};
