import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { configureStore, history } from '../shared/store';
import App from '../shared/App';
import IntlProvider from '../shared/i18n/IntlProvider';
import { rootSaga } from '../shared/store/rootSaga';

// Create/use the store
// history MUST be passed here if you want syncing between server on initial route
const store =
  window.store ||
  configureStore({
    initialState: window.__PRELOADED_STATE__,
  });

let sagaTask = store.runSaga(rootSaga);

hydrate(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <IntlProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </IntlProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();

    module.hot.accept('../shared/store/rootSaga', () => {
      sagaTask.cancel();
      sagaTask.toPromise().then(() => {
        sagaTask = store.runSaga(require('../shared/store/rootSaga').default);
      });
    });
  }

  if (!window.store) {
    window.store = store;
  }
}
