import { call, put } from 'redux-saga/effects';
import { providerSignupFailure, providerSignupSuccess } from '../actions';
import { providerSignup } from '../api';
import { ProviderSignupRequestAction } from '../types';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { ERROR_HANG_TIME } from 'store/app/constants';
import { checkResponse } from 'store/utils';
import { navigateToExternalPage } from 'utils/validation/common';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* providerSignupSaga(action: ProviderSignupRequestAction) {
  try {
    let err: ApiError | null;

    const { provider } = action.payload;
    const response = yield call(providerSignup, provider);
    const providerUrl: string = response.data.url;

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: `Contacting ${provider}`,
      description: 'Please be patient, it might take few seconds',
      type: MessageStates.INFO,
    };

    yield put(turnMessageOn(message));
    yield call(navigateToExternalPage, providerUrl);
    yield put(providerSignupSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(providerSignupFailure());
  }
}
