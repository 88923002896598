import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import routes from 'routes';

// TODO: Translate labels
// TODO: Display error translations by error code from API
export const SignUpSSOPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  if (location.search) {
    const searchParams = location.search.substring(1);
    const searchParamsArray = searchParams.split('&');
    const searchParamsJson = {};
    searchParamsArray.forEach((sp) => {
      const paramSplit = sp.split('=');
      searchParamsJson[paramSplit[0]] = paramSplit[1];
    });
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        'SSO-USER-DETAILS',
        JSON.stringify(searchParamsJson)
      );
    }
  }
  useEffect(() => {
    dispatch(push(routes.signUp.signupForm));
  }, []);

  return null;
};
