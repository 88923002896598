import { call, put } from 'redux-saga/effects';
import { fetchConnectUrlSuccess, fetchQRCodeFailure } from '../actions';
import { FetchConnectUrlResponse, getConnectAccountUrl } from '../api';
import { FetchConnectUrlRequestAction } from '../types';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { navigateToExternalPage } from 'utils/validation/common';
import { checkResponse } from 'store/utils';
import { ERROR_HANG_TIME } from 'store/app/constants';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchConnectUrlSaga(action: FetchConnectUrlRequestAction) {
  try {
    let err: ApiError | null;

    const { provider, providerAction } = action.payload;
    const response: FetchConnectUrlResponse = yield call(
      getConnectAccountUrl,
      provider,
      providerAction
    );

    const connectUrl = response.data.url;
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: `Contacting ${provider}`,
      description: 'Please be patient, it might take few seconds',
      type: MessageStates.INFO,
    };
    yield put(turnMessageOn(message));
    yield call(navigateToExternalPage, connectUrl);
    yield put(fetchConnectUrlSuccess());
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
      duration: ERROR_HANG_TIME,
    };
    yield put(turnMessageOn(message));
    yield put(fetchQRCodeFailure());
  }
}
