export const LOGIN_REQUEST = 'auth/login-request';
export const LOGIN_SUCCESS = 'auth/login-success';
export const LOGIN_FAILURE = 'auth/login-failure';
export const LOGIN_MFA = 'auth/login-mfa';

export const FETCH_ME_REQUEST = 'auth/fetch-me-request';
export const FETCH_ME_SUCCESS = 'auth/fetch-me-success';
export const FETCH_ME_FAILURE = 'auth/fetch-me-failure';

export const LOGOUT_REQUEST = 'auth/logout-request';
export const LOGOUT_SUCCESS = 'auth/logout-success';
export const LOGOUT_FAILURE = 'auth/logout-failure';

export const RESET_PASSWORD_EMAIL_REQUEST =
  'auth/forgot-password-email-request';
export const RESET_PASSWORD_EMAIL_SUCCESS =
  'auth/forgot-password-email-success';
export const RESET_PASSWORD_EMAIL_FAILURE =
  'auth/forgot-password-email-failure';

export const LOGIN = 'LOGIN';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_CONFIRM = 'PASSWORD_CONFIRM';
export const SIGN_UP = 'SIGN_UP';
export const ACTIVATE = 'ACTIVATE';

export const UPDATE_CURRENT_USER_PICTURE = 'auth/update-current-user-picture';
