import {
  AppAction,
  AppState,
  SetCurrentPageAction,
  SetLocaleAction,
  SetMenuSize,
  TurnDrawerOnAction,
  TurnMessageOnAction,
  TurnModalOnAction,
  UpdateMappingData,
  UpdateUserPictures,
} from './types';

import {
  SET_CURRENT_PAGE,
  SET_LOCALE,
  SET_MENU_SIZE,
  SET_REFRESH_DONE,
  TURN_DRAWER_OFF,
  TURN_DRAWER_ON,
  TURN_MESSAGE_OFF,
  TURN_MESSAGE_ON,
  TURN_MODAL_OFF,
  TURN_MODAL_ON,
  UPDATE_MAPPING_DATA,
  UPDATE_USER_PICTURES,
} from './constants';
import { MenuSize, Pages } from './enums';
import routes from 'routes';

export const initialState: AppState = {
  locale: 'en_US',
  currentPage: { route: routes.main, name: Pages.DASHBOARD },
  showMessage: false,
  message: undefined,
  modalOpen: false,
  modalData: undefined,
  drawerOpen: false,
  drawerData: undefined,
  refreshDone: true,
  menuSize: MenuSize.NARROW,
  mappingData: undefined,
  profilePictures: [],
};

export const appReducer = (
  state = initialState,
  action: AppAction
): AppState => {
  switch (action.type) {
    case SET_LOCALE:
      return handleSetLocale(state, action);
    case SET_CURRENT_PAGE:
      return handleSetCurrentPage(state, action);
    case TURN_MESSAGE_ON:
      return handleTurnMesssageOn(state, action);
    case TURN_MESSAGE_OFF:
      return handleTurnMesssageOff(state);
    case TURN_MODAL_ON:
      return handleTurnModalOn(state, action);
    case TURN_MODAL_OFF:
      return handleTurnModalOff(state);
    case TURN_DRAWER_ON:
      return handleTurnDrawerOn(state, action);
    case TURN_DRAWER_OFF:
      return handleTurnDrawerOff(state);
    case SET_REFRESH_DONE:
      return handleSetRefreshDone(state);
    case SET_MENU_SIZE:
      return handleSetMenuSize(state, action);
    case UPDATE_MAPPING_DATA:
      return handleUpdateMappingData(state, action);
    case UPDATE_USER_PICTURES:
      return handleUpdateUserPictures(state, action);
    default:
      return state;
  }
};

function handleSetLocale(state: AppState, action: SetLocaleAction): AppState {
  return {
    ...state,
    locale: action.payload.locale,
  };
}

function handleSetCurrentPage(
  state: AppState,
  action: SetCurrentPageAction
): AppState {
  return {
    ...state,
    currentPage: action.payload.currentPage,
  };
}

// messages
function handleTurnMesssageOn(
  state: AppState,
  action: TurnMessageOnAction
): AppState {
  return {
    ...state,
    showMessage: true,
    message: action.payload.message,
  };
}

function handleTurnMesssageOff(state: AppState): AppState {
  return {
    ...state,
    showMessage: false,
    message: undefined,
  };
}

// modal
function handleTurnModalOn(
  state: AppState,
  action: TurnModalOnAction
): AppState {
  return {
    ...state,
    modalOpen: true,
    modalData: action.payload.modalData,
  };
}

function handleTurnModalOff(state: AppState): AppState {
  return {
    ...state,
    modalOpen: false,
    modalData: undefined,
  };
}

// modal
function handleTurnDrawerOn(
  state: AppState,
  action: TurnDrawerOnAction
): AppState {
  return {
    ...state,
    drawerOpen: true,
    drawerData: action.payload.drawerData,
  };
}

function handleTurnDrawerOff(state: AppState): AppState {
  return {
    ...state,
    drawerOpen: false,
    drawerData: undefined,
  };
}

function handleSetRefreshDone(state: AppState): AppState {
  return {
    ...state,
    refreshDone: false,
  };
}

function handleSetMenuSize(state: AppState, action: SetMenuSize): AppState {
  return {
    ...state,
    menuSize: action.payload.menuSize,
  };
}

function handleUpdateMappingData(
  state: AppState,
  action: UpdateMappingData
): AppState {
  return {
    ...state,
    mappingData: action.payload.mappingData,
  };
}

function handleUpdateUserPictures(
  state: AppState,
  action: UpdateUserPictures
): AppState {
  const { profilePicture, userUuid } = action.payload;
  const profilePictures = state.profilePictures;
  if (profilePictures.findIndex((pp) => pp.userUuid === userUuid) === -1)
    profilePictures.push({ profilePicture, userUuid });
  return {
    ...state,
    profilePictures,
  };
}
