import { AccountType } from 'store/api/enums';
import { DataLogStatus } from 'store/app/enums';
import { User } from 'store/auth/models';

export function canEditTaxReturn(
  connected: boolean,
  currentUser?: User,
  status?: string
) {
  if (
    connected &&
    (!status ||
      DataLogStatus[status] === DataLogStatus.CANCELLED ||
      DataLogStatus[status] === DataLogStatus.SUBMITTED) &&
    currentUser &&
    AccountType[currentUser.accountType] !== AccountType.CLIENT
  )
    return true;
  return false;
}
