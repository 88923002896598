import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { AccountState } from './types';

const selectAccount = (state: StoreState): AccountState => state.account;

export const getAccountLoading = createSelector(
  selectAccount,
  (state: AccountState): AccountState['loading'] => state.loading
);

export const getAccountQRCodeData = createSelector(
  selectAccount,
  (state: AccountState): AccountState['QRCodeData'] => state.QRCodeData
);


export const getAccountProfilePicture = createSelector(
  selectAccount,
  (state: AccountState): AccountState['profilePicture'] => state.profilePicture
);