import { Row, Typography } from 'antd';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

const IFrameWRapper = styled.div`
  height: calc(100vh - 75px);
`;

export const SupportPage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Support | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This is a page for Support" />
      </Helmet>
      <IFrameWRapper>
        <iframe
          src="https://scribehow.com/page-embed/Welcome_to_generateTAX__OcV700u0S3qvT1B-f4RMWw?removeLogo=true"
          width="100%"
          height="100%"
          allowFullScreen
          frameBorder="0"
        ></iframe>
      </IFrameWRapper>
    </React.Fragment>
  );
};
