export const REGISTER_REQUEST = 'signup/register-request';
export const REGISTER_SUCCESS = 'signup/register-success';
export const REGISTER_FAILURE = 'signup/register-failure';

export const CONFIRM_USER_REQUEST = 'signup/confirm-user-request';
export const CONFIRM_USER_SUCCESS = 'signup/confirm-user-success';
export const CONFIRM_USER_FAILURE = 'signup/confirm-user-failure';

export const ACTIVATE_ACCOUNT_REQUEST = 'signup/activate-account-request';
export const ACTIVATE_ACCOUNT_SUCCESS = 'signup/activate-account-success';
export const ACTIVATE_ACCOUNT_FAILURE = 'signup/activate-account-failure';

export const RESEND_ACTIVATION_REQUEST = 'signup/resend-activation-request';
export const RESEND_ACTIVATION_SUCCESS = 'signup/resend-activation-success';
export const RESEND_ACTIVATION_FAILURE = 'signup/resend-activation-failure';

export const PROVIDER_SIGNUP_REQUEST = 'signup/provider-signup-request';
export const PROVIDER_SIGNUP_SUCCESS = 'signup/provider-signup-success';
export const PROVIDER_SIGNUP_FAILURE = 'signup/provider-signup-failure';
