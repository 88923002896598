import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { OrganisationState } from './types';

const selectSignUp = (app: StoreState): OrganisationState => app.organisation;

export const getOrgLoading = createSelector(
  selectSignUp,
  (organisation: OrganisationState): OrganisationState['loading'] =>
    organisation.loading
);

export const getOrgError = createSelector(
  selectSignUp,
  (organisation: OrganisationState): OrganisationState['error'] =>
    organisation.error
);

export const getOrgOrganisations = createSelector(
  selectSignUp,
  (organisation: OrganisationState): OrganisationState['organisations'] =>
    organisation.organisations
);

export const getOrgMappings = createSelector(
  selectSignUp,
  (organisation: OrganisationState): OrganisationState['mappings'] =>
    organisation.mappings
);

export const getOrgMappedOrganisations = createSelector(
  selectSignUp,
  (organisation: OrganisationState): OrganisationState['mappedOrganisations'] =>
    organisation.mappedOrganisations
);

export const getOrgMappedReturns = createSelector(
  selectSignUp,
  (organisation: OrganisationState): OrganisationState['mappedReturns'] =>
    organisation.mappedReturns
);

export const getOrgShowActions = createSelector(
  selectSignUp,
  (organisation: OrganisationState): OrganisationState['showActions'] =>
    organisation.showActions
);

export const getOrgShowConnections = createSelector(
  selectSignUp,
  (organisation: OrganisationState): OrganisationState['showConnections'] =>
    organisation.showConnections
);

export const getOrgGettingTaxes = createSelector(
  selectSignUp,
  (organisation: OrganisationState): OrganisationState['gettingTaxes'] =>
    organisation.gettingTaxes
);
